import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import { antdVal } from './antdConfig';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducer';
import { thunk } from 'redux-thunk';
import { Provider } from 'react-redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
root.render(
    <Provider store={store}>
        <ConfigProvider
            theme={antdVal}
        >
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ConfigProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
