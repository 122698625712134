import { SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Space, Typography } from "antd";
import React, { Children } from "react";
import componentCss from "./component.module.scss"

interface SearchHeaderPropsInterface {
    children: any;
    title: String
}
const flash = (
    <svg width="15" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.07681 0.115356H11.5383L5.99989 5.65382H10.6153L2.30758 14.8846L5.07681 7.49997H0.461426L5.07681 0.115356Z" fill="white" />
    </svg>
  );
function SearchHeader({children, title}:SearchHeaderPropsInterface) {
    return (
        <Flex justify="space-between" align="center" gap={30} style={{ marginBottom: 10 }} className={componentCss.mytaskBlock}>
            <Typography.Title level={3} className={componentCss.pageTitle}>
                {title}
            </Typography.Title>
            <Space size={15} style={{ width: "100%" }} className={componentCss.customFilterBar}>
                {children}
                <Input placeholder="Filter and Search" addonAfter={<SearchOutlined style={{ color: "#6F7994" }} />} className={componentCss.searchInput} />
                <Button className={componentCss.defaultBtn} type="default">
                    <SettingOutlined style={{ fontSize: 16, color: "#fff" }} />
                </Button>
                <Button className={componentCss.defaultBtn} type="default">
                    {flash}
                </Button>
            </Space>
        </Flex>
    );
}

export default SearchHeader;
