import { useEffect, useState } from "react";
import { INoteCard, ITags } from "../../utils/interface";
import { IoClose, IoDocumentTextOutline } from "react-icons/io5";
import { RiPushpin2Line, RiCloseLine, RiPushpin2Fill } from "react-icons/ri";
import { FiClock, FiPlus } from "react-icons/fi";
import { MdDragIndicator, MdOutlineMoreVert } from "react-icons/md";
import { Card, Tag, Typography, Avatar, Modal, Form, Button, Input, Flex, Checkbox, Tooltip, Image, Upload, UploadFile, GetProp, UploadProps, Menu, Dropdown, Divider } from "antd";
import ImageGallery from "./ImageGallery";
import CardFooter from "./CardFooter";
import styleCss from "./style.module.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AxiosService from "../../utils/APIService";
import { getInitials } from "../../utils/commonFunctions";
import { useNote } from ".";
import { TweenOneGroup } from "rc-tween-one";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import { DeleteForEver, RestoreFromBin } from "./NotesFooterIcons";
import { NOTES_FOOTER_TYPE } from "../../utils/const";

dayjs.extend(isToday);

const colors = ["#fa7777", "#30bfee"];
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const NoteCard = (note: any) => {
    const { fetchList, addLabelByNoteId, getUniqIdsOnly, UpdateReminderByNoteId, updateShareList, DeleteDocByNoteId, noteSelectHandle, selectedNotes, activeTab } = useNote();
    const { is_pinned, tag } = note;
    const { _id, title, description, descriptionType, checkList, bg_color, docs, share_to, reminder, updated_on, created_on } = note?.note;
    const [form] = Form.useForm();
    const apiService = new AxiosService();
    const [isHover, setIsHover] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(bg_color || "#ffffff");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNote, setSelectedNote] = useState<INoteCard | null>(null);
    const [showCheckboxes, setShowCheckboxes] = useState(descriptionType === "checkbox" ? true : false);
    const [noteContent, setNoteContent] = useState(descriptionType === "checkbox" ? checkList : description);
    const [isNotesPin, setIsNotesPin] = useState(is_pinned ?? false);
    const [selectedLabels, setSelectedLabels] = useState<any[]>(tag || []);
    const [reminderTime, setReminderTime] = useState<any>(null);
    const [isRemainderOpen, setIsRemainderOpen] = useState(false);
    const [isRemainderModalOpen, setIsRemainderModalOpen] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [initFileList, setInitFileList] = useState<UploadFile[]>([]);
    const [uncheckedItems, setUncheckedItems] = useState<any[]>([]);
    const [checkedItems, setCheckedItems] = useState<any[]>([]);

    useEffect(() => {
        if (showCheckboxes) {
            setUncheckedItems(showCheckboxes ? noteContent?.filter((line: any) => !line?.checked) : []);
            setCheckedItems(showCheckboxes ? noteContent?.filter((line: any) => line.checked) : []);
        }
    }, [showCheckboxes, noteContent]);

    useEffect(() => {
        if (showCheckboxes && noteContent.length && uncheckedItems.length && checkedItems.length) {
            setNoteContent([...uncheckedItems, ...checkedItems]);
        }
    }, [uncheckedItems, checkedItems, showCheckboxes]);

    const formatDateTime = (editTime: string) => {
        const time = dayjs(editTime);
        if (time.isToday()) {
            return time.format("hh:mm A");
        } else {
            return time.format("MMM D");
        }
    };

    const modalStyle = {
        backgroundColor: backgroundColor,
        borderRadius: "8px",
    };

    const imageTypes = ["jpg", "jpeg", "png"];
    const doc = docs.filter((image: any) => !imageTypes.includes(image.fileType));

    useEffect(() => {
        const validImages = docs?.filter((image: any) => imageTypes?.includes(image?.fileType));
        const fileList: UploadFile[] = validImages?.map((image: any, index: any) => ({
            uid: `${index}`,
            name: image?.fileName,
            status: "done",
            url: image?.url,
        }));
        setInitFileList(fileList);
    }, [docs]);

    useEffect(() => {
        setSelectedLabels(tag);
        setBackgroundColor(bg_color);
        setShowCheckboxes(descriptionType === "checkbox" ? true : false);
        setIsNotesPin(is_pinned ?? false);
        setNoteContent(descriptionType === "checkbox" ? checkList : description);
        setReminderTime(reminder);
    }, [tag, bg_color, descriptionType, description, checkList, reminder]);

    const parseContent = (text: any) =>
        text.split("\n").map((line: any) => ({
            title: line,
            checked: false,
        }));

    const handleEditNote = (note: INoteCard) => {
        setSelectedNote(note);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        form.submit();
        setIsModalOpen(false);
        setSelectedNote(null);
    };

    const handleEditNotes = (value?: any) => {
        const editedNotes: any = {
            descriptionType: showCheckboxes ? "checkbox" : "text",
        };
        value && (editedNotes.title = value.title);
        if (showCheckboxes) {
            editedNotes.checkList = noteContent;
        } else {
            editedNotes.description = noteContent ?? null;
        }
        apiService
            .put(`/notes/update/${_id}`, editedNotes, false, false)
            .then(() => {
                value && fetchList();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    // Function to handle color change
    const handleColorChange = (color: string) => {
        apiService
            .post(`/notes/color-change/${_id}`, { color: color }, false, false)
            .then(() => {
                setBackgroundColor(color);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    
    const handleCheckboxToggle = () => {
        setShowCheckboxes((prev) => {
            if (!prev) {
                setNoteContent(parseContent(noteContent));
            } else {
                setNoteContent(noteContent?.map((line: any) => line?.title).join("\n"));
            }
            return !prev;
        });
    };

    const handleCheckboxChange = (index: number, isUncheckedList: boolean) => {
        if (isUncheckedList) {
            const itemToMove = uncheckedItems[index];
            setUncheckedItems((prevItems) => prevItems.filter((_, i) => i !== index));
            setCheckedItems((prevItems) => [...prevItems, { ...itemToMove, checked: true }]);
        } else {
            const itemToMove = checkedItems[index];
            setCheckedItems((prevItems) => prevItems.filter((_, i) => i !== index));
            setUncheckedItems((prevItems) => [...prevItems, { ...itemToMove, checked: false }]);
        }
    };

    const handleAddNewLine = () => {
        if (uncheckedItems.length && !uncheckedItems[uncheckedItems.length - 1].title) return;
        setUncheckedItems((prevItems) => [...prevItems, { title: "", checked: false }]);
    };

    const handleLineTextChange = (index: number, text: string, isUncheckedList: boolean) => {
        if (isUncheckedList) {
            setUncheckedItems((prevItems) => {
                const newItems = [...prevItems];
                newItems[index].title = text;
                return newItems;
            });
        } else {
            setCheckedItems((prevItems) => {
                const newItems = [...prevItems];
                newItems[index].title = text;
                return newItems;
            });
        }
    };

    const handleContentChange = (e: any) => {
        setNoteContent(e.target.value);
    };

    const handleRemoveLine = (index: number, isUncheckedList: boolean) => {
        if (isUncheckedList) {
            setUncheckedItems((prevItems) => prevItems.filter((_, i) => i !== index));
        } else {
            setCheckedItems((prevItems) => prevItems.filter((_, i) => i !== index));
        }
    };

    const handleDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;

        const newUncheckedItems = Array.from(uncheckedItems);
        const [movedItem] = newUncheckedItems.splice(source.index, 1);
        newUncheckedItems.splice(destination.index, 0, movedItem);
        setUncheckedItems(newUncheckedItems);
    };

    const handlePinNote = () => {
        apiService
            .post(`/notes/pinned/${_id}`, { isPinned: !isNotesPin }, false, false)
            .then(() => {
                setIsNotesPin(!isNotesPin);
                !isModalOpen && fetchList();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleUserListChange = (userList: any[]) => {
        updateShareList(_id, userList);
    };

    const handleRemoveNoteLabel = (label: any) => {
        const updatedLabels = selectedLabels?.filter((l) => l?._id !== label?._id);
        if (_id) {
            addLabelByNoteId(_id, getUniqIdsOnly(updatedLabels));
            setSelectedLabels(updatedLabels);
        }
    };

    const handleEditReminder = (e: any) => {
        e.stopPropagation();
        setIsRemainderOpen(true);
    };

    const removeReminder = () => {
        setReminderTime(null);
        setIsRemainderOpen(false);
        UpdateReminderByNoteId(_id, null);
    };

    const handleAddNewLineAtIndex = (index: number, isChecked: boolean) => {
        const newLine = { title: "", checked: isChecked };

        if (isChecked) {
            setCheckedItems((prevItems: any[]) => {
                const newCheckedItems = [...prevItems];
                newCheckedItems.splice(index + 1, 0, newLine);
                return newCheckedItems;
            });
        } else {
            setUncheckedItems((prevItems: any[]) => {
                const newUncheckedItems = [...prevItems];
                newUncheckedItems.splice(index + 1, 0, newLine);
                return newUncheckedItems;
            });
        }
    };

    const handleKeyPress = (index: number, e: any, isUncheckedList: boolean) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (isUncheckedList) {
                handleAddNewLineAtIndex(index, false);
            } else {
                handleAddNewLineAtIndex(index, true);
            }
            setTimeout(() => {
                const nextElement = document.querySelector(`.editable-div-${index + 1}`);
                if (nextElement) {
                    (nextElement as HTMLElement).focus();
                }
            }, 0);
        }
    };

    const getBase64 = (file: File): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const removeDocHandle = ({ file, fileList }: any) => {
        if (file.status === "removed") {
            setInitFileList(fileList);
            DeleteDocByNoteId(_id, file.name);
        }
    };

    const handlePreview = async (file: UploadFile) => {
        let fileType = ["jpeg", "png", "jpg"];
        if (fileType.includes(file.name?.split(".")[1])) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as FileType);
            }

            setPreviewImage(file.url || (file.preview as string));
            setPreviewOpen(true);
        } else {
            window.open(file.url, "_blank")?.focus();
        }
    };

    const handleCheckBoxClick = (e: any) => {
        e.stopPropagation();
        setTimeout(() => {
            handleEditNotes();
        }, 0);
    };

    const menu = (image: any) => {
        return (
            <Menu>
                <Menu.Item onClick={() => handlePreview(image)}>Preview</Menu.Item>
                <Menu.Item onClick={() => DeleteDocByNoteId(_id, image?.fileName, true)}>Delete</Menu.Item>
            </Menu>
        );
    };

    return (
        <>
            <Card
                className={`${styleCss.noteCard} ${styleCss.mb16}`}
                classNames={{
                    body: "note-card-body",
                    header: "note-card-head",
                }}
                style={{
                    boxShadow: isHover ? "0px 6px 15px 0px #00000026" : "none",
                    backgroundColor: backgroundColor,
                    transition: "300ms all",
                    opacity: isModalOpen ? 0 : 1,
                }}
                onMouseOver={() => setIsHover(true)}
                onMouseOut={() => setIsHover(false)}
                bordered={false}
                onClick={() => {
                    if (selectedNotes.length == 0) {
                        handleEditNote(note);
                    }
                }}
                title={
                    <>
                        <div
                            className={styleCss.checkCircle}
                            style={{ opacity: selectedNotes.some((dt: any) => dt?.note?._id == _id) ? 1 : "revert-layer" }}
                            onClick={(event) => {
                                event.stopPropagation();
                                noteSelectHandle(note);
                            }}
                        >
                            <Tooltip title={isNotesPin ? "Selected pin note" : " Selected Note"}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="7" y="9" width="10" height="7" fill={bg_color} />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12ZM15.7127 10.7197C16.0055 10.4268 16.0055 9.95192 15.7127 9.65903C15.4198 9.36614 14.9449 9.36614 14.652 9.65903L10.9397 13.3713L9.34869 11.7804C9.0558 11.4875 8.58092 11.4875 8.28803 11.7804C7.99514 12.0732 7.99514 12.5481 8.28803 12.841L10.4093 14.9623C10.7022 15.2552 11.1771 15.2552 11.47 14.9623L15.7127 10.7197Z"
                                        fill="black"
                                    />
                                </svg>
                            </Tooltip>
                        </div>
                        <div className={styleCss.notesPin} onClick={(event) => event.stopPropagation()}>
                            {isNotesPin ? (
                                <Tooltip title="Unpin note">
                                    <RiPushpin2Fill size={24} color="#444444" onClick={handlePinNote} />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Pin note">
                                    <RiPushpin2Line size={24} color="#444444" onClick={handlePinNote} />
                                </Tooltip>
                            )}
                        </div>
                    </>
                }
            >
                {selectedNotes.length > 0 && (
                    <div
                        className={`${styleCss.selectOverlay} ${selectedNotes?.some((dt: any) => dt?.note?._id == _id) ? styleCss.select : ""}`}
                        onClick={() => noteSelectHandle(note)}
                    ></div>
                )}
                <div>
                    {!!docs && docs.length > 0 && (
                        <div className={styleCss.galleryContainer} style={{ maxHeight: "600px" }}>
                            <ImageGallery images={docs} noteId={_id} />
                        </div>
                    )}

                    {title && <Typography.Title className={styleCss.cardTitle}>{title}</Typography.Title>}

                    <div style={{ marginBottom: "10px" }}>
                        {showCheckboxes ? (
                            <>
                                {/* Unchecked items first */}
                                {uncheckedItems &&
                                    uncheckedItems.map((line, index) => (
                                        <div key={`unchecked-${index}`}>
                                            <Checkbox
                                                checked={line?.checked}
                                                className={`${styleCss.cardContent}`}
                                                onChange={() => handleCheckboxChange(index, true)}
                                                style={{ marginRight: "8px", marginBottom: "0px" }}
                                                onClick={(e) => handleCheckBoxClick(e)}
                                            >
                                                {line?.title}
                                            </Checkbox>
                                        </div>
                                    ))}

                                {/* Divider */}
                                {Array.isArray(noteContent) && noteContent.some((line) => !line.checked) && noteContent.some((line) => line.checked) && (
                                    <Divider
                                        style={{
                                            borderColor: "rgba(100, 100, 100, 0.2)",
                                            margin: "10px 0px",
                                            minWidth: "auto",
                                            width: "auto",
                                        }}
                                    />
                                )}

                                {/* Checked items */}
                                {checkedItems &&
                                    checkedItems.map((line, index) => (
                                        <div key={`checked-${index}`}>
                                            <Checkbox
                                                checked={line?.checked}
                                                className={`${styleCss.cardContent}`}
                                                onChange={() => handleCheckboxChange(index, false)}
                                                style={{ marginRight: "8px", marginBottom: "0px" }}
                                                onClick={(e) => handleCheckBoxClick(e)}
                                            >
                                                <span style={{ textDecoration: "line-through", color: "#5f6368" }}>{line?.title}</span>
                                            </Checkbox>
                                        </div>
                                    ))}
                            </>
                        ) : (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: noteContent?.replace(/\n/g, "<br />"),
                                }}
                                className={`${styleCss.cardContent} ${styleCss.lineClamp}`}
                            />
                        )}
                    </div>

                    {!title && !noteContent && !reminderTime && !share_to?.length && !tag?.length && !docs?.length && (
                        <Typography.Title className={styleCss.emptyNotesTitle}>Empty notes</Typography.Title>
                    )}

                    {doc.length > 0 && (
                        <div style={{ paddingBottom: "6px" }}>
                            {doc?.map((image: any) => (
                                <Flex align="center" className={styleCss.documentView} gap={5}>
                                    <IoDocumentTextOutline size={26} style={{ width: "26px", minWidth: "26px", maxWidth: "26px" }} />
                                    <Typography.Text ellipsis>{image?.localName}</Typography.Text>
                                </Flex>
                            ))}
                        </div>
                    )}

                    {reminderTime && (
                        <Flex className={styleCss.reminder} onClick={handleEditReminder} align="center" gap={4}>
                            <FiClock size={16} />
                            <Typography>{dayjs(reminderTime)?.format("MMM D, h:mm A")}</Typography>
                            <IoClose
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeReminder();
                                }}
                            />
                        </Flex>
                    )}

                    {!!selectedLabels && selectedLabels.length > 0 && (
                        <div className={styleCss.noteLabels}>
                            <TweenOneGroup
                                appear={false}
                                enter={{ scale: 0.8, opacity: 0, type: "from", duration: 100 }}
                                leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                                onEnd={(e) => {
                                    if (e.type === "appear" || e.type === "enter") {
                                        (e.target as any).style = "display: inline-block";
                                    }
                                }}
                            >
                                {selectedLabels?.map((tag: ITags) => (
                                    <Tag
                                        key={tag?._id}
                                        // color={tag?.color}
                                        bordered={false}
                                        onClose={(e) => {
                                            e.preventDefault();
                                            handleRemoveNoteLabel(tag);
                                        }}
                                    >
                                        {tag?.title}
                                    </Tag>
                                ))}
                            </TweenOneGroup>
                        </div>
                    )}

                    <div style={{ paddingLeft: "5px" }}>
                        {!!share_to && share_to.length > 0 && (
                            <Avatar.Group
                                max={{
                                    count: 3,
                                    style: { color: "#ffffffd6", backgroundColor: "#76a3da", fontSize: "14px" },
                                    popover: { rootClassName: "AsssignnePopover" },
                                }}
                                size={30}
                            >
                                {share_to?.map((asn: any, idx: any) => {
                                    return (
                                        <Tooltip title={asn?.full_name} key={idx}>
                                            <Avatar
                                                style={{
                                                    fontSize: "14px",
                                                    backgroundColor: colors[idx % colors?.length],
                                                    marginLeft: "-5px",
                                                }}
                                            >
                                                {getInitials(asn.full_name)}
                                            </Avatar>
                                        </Tooltip>
                                    );
                                })}
                            </Avatar.Group>
                        )}
                    </div>
                </div>
                {!(activeTab === "bin") ? (
                    <CardFooter
                        isHover={isHover}
                        onColorChange={handleColorChange}
                        onCheckboxToggle={handleCheckboxToggle}
                        showCheckboxes={showCheckboxes}
                        id={_id}
                        onUserListChange={handleUserListChange}
                        selectedLabels={selectedLabels}
                        setSelectedLabels={setSelectedLabels}
                        userShareList={share_to}
                        reminder={reminderTime}
                        setReminder={(val: any) => {
                            setReminderTime(val);
                            UpdateReminderByNoteId(_id, val);
                        }}
                        isRemainderOptionOpen={isRemainderOpen}
                        setIsRemainderOptionOpen={setIsRemainderOpen}
                        footerType={NOTES_FOOTER_TYPE.cardBottom}
                    />
                ) : (
                    <>
                        <div className={`${styleCss.placeholder} ${!isHover ? "" : styleCss.showSpace}`}></div>
                        <div className={`${styleCss.cardFooter} ${!isHover ? "" : styleCss.showBlock}`}>
                            <DeleteForEver id={_id} />
                            <RestoreFromBin id={_id} />
                        </div>
                    </>
                )}
            </Card>

            {/* Modal for editing notes */}
            <Form form={form} layout="vertical" onFinish={handleEditNotes}>
                {isModalOpen && selectedNote && (
                    <Modal open={isModalOpen} onCancel={handleModalClose} footer={null} closable={false} width={600} className={styleCss.noteModel} bodyStyle={modalStyle} centered>
                        <div className="ModelWrapper">
                            <div className="ModelPin" style={{ cursor: "pointer" }} onClick={(event) => event.stopPropagation()}>
                                {isNotesPin ? (
                                    <Tooltip title="Unpin note">
                                        <RiPushpin2Fill size={24} color="#444444" onClick={handlePinNote} />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Pin note">
                                        <RiPushpin2Line size={24} color="#444444" onClick={handlePinNote} />
                                    </Tooltip>
                                )}
                            </div>
                            {initFileList?.length > 0 && (
                                <div className="modelImage">
                                    <Upload
                                        listType="picture-card"
                                        fileList={initFileList}
                                        beforeUpload={() => false}
                                        onChange={removeDocHandle}
                                        onPreview={handlePreview}
                                        multiple={false}
                                    />
                                </div>
                            )}

                            <Image
                                wrapperStyle={{ display: "none" }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(""),
                                }}
                                src={previewImage}
                            />

                            <div className="Notetitle">
                                <Form.Item name="title" initialValue={title}>
                                    <Input variant="borderless" placeholder="Title" />
                                </Form.Item>
                            </div>

                            <Form.Item className="NoteDecr" name="content">
                                <div>
                                    {showCheckboxes ? (
                                        <>
                                            {/* Unchecked items - draggable */}
                                            <DragDropContext onDragEnd={handleDragEnd}>
                                                <Droppable droppableId="unchecked-list">
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                                            {uncheckedItems?.map((line: any, index: number) => (
                                                                <Draggable key={index} draggableId={`unchecked-${index}`} index={index}>
                                                                    {(provided) => (
                                                                        <div ref={provided.innerRef} {...provided.draggableProps} className="note-content">
                                                                            <div style={{ display: "flex", alignItems: "start" }} className="contain-div">
                                                                                <div
                                                                                    className="dragHandle"
                                                                                    {...provided.dragHandleProps}
                                                                                    style={{ paddingRight: "6px", display: "inline-flex" }}
                                                                                >
                                                                                    <MdDragIndicator size={24} color="#979797" />
                                                                                </div>
                                                                                <Checkbox
                                                                                    checked={line.checked}
                                                                                    onChange={() => handleCheckboxChange(index, true)}
                                                                                    style={{ marginRight: "10px"}}
                                                                                />
                                                                                <div
                                                                                    contentEditable
                                                                                    style={{ flexGrow: 1, outline: "none" }}
                                                                                    className={`editable-div editable-div-${index}`}
                                                                                    onBlur={(e: any) => handleLineTextChange(index, e.currentTarget.textContent, true)}
                                                                                    onKeyDown={(e: any) => handleKeyPress(index, e, true)}
                                                                                >
                                                                                    {line?.title}
                                                                                </div>
                                                                                <RiCloseLine
                                                                                    size={24}
                                                                                    onClick={() => handleRemoveLine(index, true)}
                                                                                    className="delete-item"
                                                                                    color="#979797"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            {showCheckboxes && (
                                                <div className="listItem">
                                                    <Button
                                                        style={{ color: "#333333", fontSize: "14px", padding: 0, paddingLeft: "28px" }}
                                                        size="large"
                                                        type="link"
                                                        onClick={handleAddNewLine}
                                                        icon={<FiPlus size={20} color="#333333" />}
                                                    >
                                                        List item
                                                    </Button>
                                                </div>
                                            )}

                                            {/* Divider between unchecked and checked items */}
                                            {Array.isArray(noteContent) && noteContent.some((line) => !line.checked) && noteContent.some((line) => line.checked) && (
                                                <Divider
                                                    style={{
                                                        borderColor: "rgba(100, 100, 100, 0.2)",
                                                        margin: "5px 10px 15px 25px",
                                                        minWidth: "auto",
                                                        width: "auto",
                                                    }}
                                                />
                                            )}

                                            {/* Checked items - not draggable but editable */}
                                            <div style={{ paddingLeft: "30px" }}>
                                                {checkedItems?.map((line: any, index: number) => (
                                                    <div key={`checked-${index}`} className="note-content">
                                                        <div style={{ display: "flex", alignItems:'start' }} className="contain-div">
                                                            <Checkbox checked={line.checked} onChange={() => handleCheckboxChange(index, false)} style={{ marginRight: "10px"}} />
                                                            <div
                                                                contentEditable
                                                                style={{ flexGrow: 1, outline: "none" }}
                                                                className={`editable-div editable-div-${index}`}
                                                                onBlur={(e: any) => handleLineTextChange(index, e.currentTarget.textContent, false)}
                                                                onKeyDown={(e: any) => handleKeyPress(index, e, false)}
                                                            >
                                                                <span style={{ textDecoration: "line-through", color: "#5f6368" }}>{line?.title}</span>
                                                            </div>
                                                            <RiCloseLine size={24} onClick={() => handleRemoveLine(index, false)} className="delete-item" color="#979797" />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    ) : (
                                        <Input.TextArea
                                            autoSize={{ minRows: 1 }}
                                            value={noteContent}
                                            onChange={handleContentChange}
                                            placeholder="Note content"
                                            variant="borderless"
                                            style={{ paddingLeft: "16px" }}
                                        />
                                    )}
                                </div>
                            </Form.Item>

                            {reminderTime && (
                                <Flex align="center" gap={4} className={styleCss.reminder} onClick={() => setIsRemainderModalOpen(true)} style={{ marginLeft: "16px" }}>
                                    <FiClock size={16} />
                                    {dayjs(reminderTime)?.format("MMM D, h:mm A")}
                                    <IoClose
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeReminder();
                                        }}
                                    />
                                </Flex>
                            )}

                            {!!selectedLabels && selectedLabels.length > 0 && (
                                <>
                                    <TweenOneGroup
                                        className={styleCss.tagCollection}
                                        appear={false}
                                        enter={{ scale: 0.8, opacity: 0, type: "from", duration: 100 }}
                                        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                                        onEnd={(e) => {
                                            if (e.type === "appear" || e.type === "enter") {
                                                (e.target as any).style = "display: inline-block";
                                            }
                                        }}
                                    >
                                        {selectedLabels?.map((tag: ITags) => (
                                            <Tag
                                                key={tag?._id}
                                                color={tag?.color}
                                                bordered={false}
                                                closable
                                                onClose={(e) => {
                                                    e.preventDefault();
                                                    handleRemoveNoteLabel(tag);
                                                }}
                                            >
                                                {tag?.title}
                                            </Tag>
                                        ))}
                                    </TweenOneGroup>
                                </>
                            )}

                            {doc.length > 0 && (
                                <div style={{ padding: "5px 15px", paddingBottom: "0px" }}>
                                    {doc?.map((image: any) => (
                                        <Flex align="center" className={styleCss.documentView} justify="space-between" gap={8}>
                                            <Flex align="center" gap={5} justify="">
                                                <IoDocumentTextOutline size={26} style={{ width: "26px", minWidth: "26px", maxWidth: "26px" }} />
                                                <Typography.Text ellipsis style={{ width: "200px", textAlign: "start" }}>
                                                    {image?.localName}
                                                </Typography.Text>
                                            </Flex>
                                            <Dropdown overlay={menu(image)} trigger={["click"]} placement="bottom">
                                                <MdOutlineMoreVert size={20} style={{ cursor: "pointer", width: "20px", minWidth: "20px", maxWidth: "20px" }} />
                                            </Dropdown>
                                        </Flex>
                                    ))}
                                </div>
                            )}

                            <Flex className="NoteTimeWrapper" justify="space-between" align="center">
                                <div style={{ paddingLeft: "10px" }}>
                                    {!!share_to && share_to.length > 0 && (
                                        <Avatar.Group
                                            max={{
                                                count: 3,
                                                style: { color: "#ffffffd6", backgroundColor: "#76a3da", fontSize: "14px" },
                                                popover: { rootClassName: "AsssignnePopover" },
                                            }}
                                            size={30}
                                        >
                                            {share_to?.map((asn: any, idx: any) => {
                                                return (
                                                    <Tooltip title={asn?.full_name} key={idx}>
                                                        <Avatar
                                                            style={{
                                                                fontSize: "14px",
                                                                backgroundColor: colors[idx % colors?.length],
                                                                marginLeft: "-5px",
                                                            }}
                                                        >
                                                            {getInitials(asn.full_name)}
                                                        </Avatar>
                                                    </Tooltip>
                                                );
                                            })}
                                        </Avatar.Group>
                                    )}
                                </div>
                                <Tooltip title={`Created ${formatDateTime(created_on)}`} arrow={false} placement="bottom">
                                    <span>Edited {formatDateTime(updated_on)}</span>
                                </Tooltip>
                            </Flex>
                        </div>
                        <Flex justify="space-between" className="NoteFooter">
                            <Flex align="center" gap={20}>
                                {!(activeTab === "bin") ? (
                                    <CardFooter
                                        isHover={true}
                                        onColorChange={handleColorChange}
                                        onCheckboxToggle={handleCheckboxToggle}
                                        showCheckboxes={showCheckboxes}
                                        id={_id}
                                        onUserListChange={handleUserListChange}
                                        selectedLabels={selectedLabels}
                                        setSelectedLabels={setSelectedLabels}
                                        userShareList={share_to}
                                        reminder={reminderTime}
                                        setReminder={(val: any) => {
                                            setReminderTime(val);
                                            UpdateReminderByNoteId(_id, val);
                                        }}
                                        isRemainderOptionOpen={isRemainderModalOpen}
                                        setIsRemainderOptionOpen={setIsRemainderModalOpen}
                                        footerType={NOTES_FOOTER_TYPE.editNoteModal}
                                        setUploadFileList={(value) => {
                                            setInitFileList(value);
                                        }}
                                        uploadFileList={initFileList}
                                        setIsModalOpen={setIsModalOpen}
                                    />
                                ) : (
                                    <div>
                                        <DeleteForEver id={_id} />
                                        <RestoreFromBin id={_id} />
                                    </div>
                                )}
                            </Flex>
                            <Button shape="default" onClick={handleModalClose} className="closeBtn">
                                Close
                            </Button>
                        </Flex>
                    </Modal>
                )}
            </Form>
        </>
    );
};

export default NoteCard;
