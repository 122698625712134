import { BellOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Flex } from "antd";
import rightSideBarCss from './style.module.scss'

interface RightSideBar {
    taskDrawerOpen: any;
}

function RightSideBar({ taskDrawerOpen }: RightSideBar) {
    return (
        <div className={rightSideBarCss.sidebar}>
            <Flex align='center' className={`${rightSideBarCss.rightSidebar} ${taskDrawerOpen ? rightSideBarCss.light : ''}`} gap={15}>
                <Button type='text'><BellOutlined style={{ fontSize: 20 }} /></Button>
                <div className={`${rightSideBarCss.divider} ${taskDrawerOpen ? rightSideBarCss.dividerLight : ''}`} />
                <Button type='text'><QuestionCircleOutlined style={{ fontSize: 20 }} /></Button>
                <Button type='text'><SearchOutlined style={{ fontSize: 20 }} /></Button>
                <div className={`${rightSideBarCss.divider} ${taskDrawerOpen ? rightSideBarCss.dividerLight : ''}`} />
                <Avatar style={{ backgroundColor: '#C27D7D', color: '#fff' }}>OP</Avatar>
                <Avatar style={{ backgroundColor: '#5C9688', color: '#fff' }}>BM</Avatar>
            </Flex>
        </div>
    )
}

export default RightSideBar