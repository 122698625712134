import { Button, Divider, Flex, Form, MenuProps, Tabs, TabsProps, Typography } from "antd";
import React, { useRef, useState } from "react";
import ContactStyleCss from "./style.module.scss";
import FormStyleCss from "./ContactForm/form.module.scss";
import DrawerCustom from "../../components/Drawer";
import { contactDrawer } from "../../action";
import { DRAWER_TYPE } from "../../utils/const";
import { connect } from "react-redux";
import { RootState } from "../../reducer";
import { UserInterface } from "../../reducer/loginReducer";
import ContactForm from "./ContactForm";
const caretDown = (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.44853 0.5C0.913985 0.5 0.646285 1.14629 1.02426 1.52426L4.57574 5.07574C4.81005 5.31005 5.18995 5.31005 5.42426 5.07574L8.97574 1.52426C9.35372 1.14628 9.08601 0.5 8.55147 0.5L1.44853 0.5Z"
            fill="#6F7994"
        />
    </svg>
);
const { Text } = Typography;
const { TabPane } = Tabs;
type TargetKey = React.MouseEvent | React.KeyboardEvent | string;
interface CreateContactPropsInterface {
    contactDrawer: (val: any) => void;
    contactDrawerOpen?: "CREATE_CONTACT" | null | undefined;
}
function CreateContact({ contactDrawer, contactDrawerOpen }: CreateContactPropsInterface) {
    const [personList, setpersonList] = useState<any>([
        { 
            label: "Main Person", 
            children: "Content of new Tab", 
            key: `newTab0`,
            closable: false, 
        }
    ]);
    const [activeKey, setActiveKey] = useState(personList[0].key);
    const newTabIndex = useRef(1);
    const drawerClose = () => {
        contactDrawer(null);
    };
    const [form] = Form.useForm();
    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "General",
            children: "",
        },
        {
            key: "2",
            label: "Invoices",
            children: "",
        },
        {
            key: "3",
            label: "Deals",
            children: "",
        },
        {
            key: "4",
            label: "History",
            children: "",
        },
        {
            key: "5",
            label: "More",
            children: "",
        },
    ];
    const handleMinimize = () => {};
    const openCreateModel = () => {
        contactDrawer(DRAWER_TYPE.CONTACT_DRAWER);
    };
    const onTabChange = (val: any) => {
        console.log(val);
    };
    const handleFinish = (val: any) => {
        console.log(val);
    };
    const handleFinishFailed = (val: any) => {
        console.log(val);
    };
    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    const add = () => {
        const key = newTabIndex.current++
        const newActiveKey = `newTab${key}`;
        const newPanes = [...personList];
        newPanes.push({ label: `Person ${key}`, children: "Content of new Tab", key: newActiveKey });
        setpersonList(newPanes);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: TargetKey) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        personList.forEach((item: any, i: number) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = personList.filter((item: any) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        setpersonList(newPanes);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            add();
        } else {
            remove(targetKey);
        }
    };
    return (
        <>
            <Button onClick={() => openCreateModel()} className={ContactStyleCss.contactBtn}>
                CREATE
            </Button>
            <DrawerCustom
                handleMinimize={() => handleMinimize()}
                drawerCloseHandle={() => drawerClose()}
                open={contactDrawerOpen === DRAWER_TYPE.CONTACT_DRAWER}
                width={820}
                closeText="CONTACT"
            >
                <div style={{ padding: "23px 12px" }}>
                    <Text className="drawer-title">New Contact</Text>
                </div>
                <Tabs className={ContactStyleCss.mainTab} defaultActiveKey="1" items={items} onChange={onTabChange} />

                <Form form={form} name="dynamic_form" autoComplete="off" layout="vertical" onFinish={handleFinish} onFinishFailed={handleFinishFailed} scrollToFirstError>
                    <Tabs 
                        className={ContactStyleCss.contactFormTab} 
                        type="editable-card" 
                        onChange={onChange} 
                        activeKey={activeKey} 
                        onEdit={onEdit}
                    >
                        {personList.map((per:any, index:number) => (
                            <TabPane closable={per.closable} tab={per.label} key={per.key} tabKey={`${per.key}`}>
                                <ContactForm form={form} formId={index} />
                                <Divider style={{
                                    borderBlockStart: '1px solid rgba(219, 223, 233, 1)',
                                    margin: 0
                                }} />
                                <div className="contact-form-footer">
                                    <Flex gap={15} justify="end">
                                        <Button className={FormStyleCss.cancelButton}>Cancel</Button>
                                        <Button className={FormStyleCss.formButton} htmlType="submit">Save</Button>
                                    </Flex>
                                </div>
                            </TabPane>
                        ))}
                    </Tabs>
                </Form>
            </DrawerCustom>
        </>
    );
}
const mapStateToProps = ({ layout }: RootState) => {
    const { contactDrawerOpen } = layout;
    return {
        contactDrawerOpen,
    };
};
export default connect(mapStateToProps, { contactDrawer })(CreateContact);
