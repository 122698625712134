import React from 'react'
import { LocalStorageService } from '../utils/LocalStorage';
import { connect } from 'react-redux';
import { RootState } from '../reducer';
import { UserInterface } from '../reducer/loginReducer';
import { Navigate } from 'react-router-dom';
import { ROLE, UTILS } from '../utils/const';
import { isLogin, setProfileData, setUserData } from '../action';

const ProtectRoute = ({ 
    layout: Layout, 
    component: Component, 
    isUserLogin, 
    user, 
    role,
    isLogin, 
    setProfileData, 
    setUserData,
    ...rest }: any) => {
    const userData:UserInterface = user || LocalStorageService.getItem(UTILS.user);

    const isAuthenticated = () => {
        const token = LocalStorageService.getItem(UTILS.token)
        return (isUserLogin || token)
    }
    if (!isAuthenticated()) {
        return <Navigate to="/login" />;
    }

    // if((userData?.role === ROLE.agentAdmin && role !== ROLE.agentAdmin) ||
    // (userData?.role === ROLE.agentUser && role !== ROLE.agentUser) ||
    // (userData?.role === ROLE.admin && role !== ROLE.admin)){
    //     if(userData?.role === ROLE.agentAdmin){
    //         return <Navigate to={'/team'} />
    //     }else if(userData?.role === ROLE.agentUser){
    //         return <Navigate to={'/inquiry'} />
    //     }else {
    //         return <Navigate to={'/login'} />
    //     }
    // }
    
    return (
        <Layout user={user}  isLogin={isLogin} setProfileData={setProfileData} setUserData={setUserData}>
            <Component {...rest} />
        </Layout>
    )
}
const mapStateToProps = ({ userData }: RootState) => {
    const {isUserLogin, user}= userData;
    return { isUserLogin, user };
  };
export default connect(mapStateToProps, { isLogin, setProfileData, setUserData}) (ProtectRoute)
