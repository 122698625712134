import { ArrowUpOutlined, CloseOutlined, DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Flex, Form, Input, InputRef, Progress, Space, Tag, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import styleCss from './style.module.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { subTaskData, subTaskDataListInterface } from './CreateTask';

const { Panel } = Collapse;

interface CheckListPropsInterface {
    data: subTaskDataListInterface;
    taskListChange: (val: any) => void
    removeSubTaskList: () => void;
}
function CheckList({ data, taskListChange, removeSubTaskList }: CheckListPropsInterface) {
    const [form] = Form.useForm();
    const [checklist, setChecklist] = useState<subTaskData[]>([...data.task]);
    const [inputValue, setInputValue] = useState<string>('');
    const [inputVisible, setInputVisible] = useState<boolean>(false);
    const [percent, setPercent] = useState<number>(0);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const inputRef = useRef<InputRef>(null);

    const addTask = () => {
        if (inputValue.trim()) {
            setChecklist([...checklist, { title: inputValue, is_completed: false }]);
            setInputValue('');
            form.resetFields();
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addTask();
        }
    };

    const handleBlur = () => {
        addTask();
        setInputVisible(false);
    };

    const handleRemove = (index: number) => {
        const updatedChecklist = [...checklist];
        updatedChecklist.splice(index, 1);
        setChecklist(updatedChecklist);
    };

    const handleCheckboxChange = (index: number) => {
        const updatedChecklist = [...checklist];
        updatedChecklist[index].is_completed = !updatedChecklist[index].is_completed;
        setChecklist(updatedChecklist);
    };

    const handleDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(checklist);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setChecklist(items);
    };

    const calculateProgress = () => {
        if (checklist.length === 0) {
            return 0;
        }

        const checkedCount = checklist.filter(item => item.is_completed).length;
        return Math.round((checkedCount / checklist.length) * 100);
    };

    useEffect(() => {
        const progress = calculateProgress();
        setPercent(progress);
        inputRef?.current?.focus();
        taskListChange({ name: data.name, task: checklist });

        if (checklist.length > 0 && checklist.filter(item => item.is_completed).length === checklist.length) {
            setIsCollapsed(true);
        }
    }, [checklist]);

    const titleChangeHandle = (val: string) => {
        taskListChange({ name: val, task: checklist });
    };

    // const handleTitleChange = (index: number, e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
    //     const newTitle = e.currentTarget.value.trim();

    //     if (newTitle !== '') {
    //         const updatedChecklist = [...checklist];
    //         updatedChecklist[index].title = newTitle;
    //         setChecklist(updatedChecklist);
    //     }

    //     if (e.type === 'blur' || (e.type === 'keydown' && (e as React.KeyboardEvent<HTMLInputElement>).key === 'Enter')) {
    //         setEditIndex(null);
    //     }
    // };

    const handleTitleChange = (index: number, e: React.FocusEvent<HTMLInputElement>) => {
        const newTitle = e.currentTarget.value.trim();

        if (newTitle !== '') {
            const updatedChecklist = [...checklist];
            updatedChecklist[index].title = newTitle;
            setChecklist(updatedChecklist);
        }
        setEditIndex(null);
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const newTitle = e.currentTarget.value.trim();

            if (newTitle !== '') {
                const updatedChecklist = [...checklist];
                updatedChecklist[index].title = newTitle;
                setChecklist(updatedChecklist);
            }

            setEditIndex(null);
        }
    };

    const handleCollapseChange = (key: string | string[]) => {
        const isOpen = !!key.length;
        setIsCollapsed(!isOpen);
    };

    return (
        <>
            <div className={styleCss.checkListWrapper}>
                <div className={styleCss.TaskDescription}>
                    <div className={styleCss.checkList}>
                        <Collapse collapsible='icon' activeKey={isCollapsed ? [] : ['1']} ghost onChange={handleCollapseChange} expandIconPosition="end"  expandIcon={() => (isCollapsed ? <DownOutlined /> : <UpOutlined />)}>
                            <Panel
                                header={
                                    <>
                                        <Flex align='center'>
                                            <Typography.Title 
                                                onClick={(e) => e.stopPropagation()} 
                                                className={styleCss.subtaskTitle} 
                                                editable={{ 
                                                    onChange: titleChangeHandle, 
                                                    enterIcon: false, 
                                                    icon: <EditOutlined className="edit-icon"  style={{ color: "#858c96" }} />
                                                    }} 
                                                level={5}>
                                                {data.name}
                                            </Typography.Title>
                                            {checklist.length > 0 && (
                                                <>
                                                    <Progress percent={percent} type="line" style={{ width: '12%', margin: '0 20px' }} />
                                                    <Typography style={{ marginBottom: '0px', fontSize: '12px' }}>
                                                        {`Completed ${checklist.filter(item => item.is_completed).length} out of ${checklist.length}`}
                                                    </Typography>
                                                </>
                                            )}
                                        </Flex>
                                    </>
                                }
                                key="1"
                            >
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="droppable-tags">
                                        {(provided) => (
                                            <>
                                                <div {...provided.droppableProps} ref={provided.innerRef} >
                                                    {checklist.map((item, index) => (
                                                        <Draggable key={index} draggableId={`tag-${index}`} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className='tagList'
                                                                >
                                                                    <Tag className='sub-task-item' color="blue">
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                            <Space>
                                                                                <Checkbox
                                                                                    checked={item.is_completed}
                                                                                    onChange={() => handleCheckboxChange(index)}
                                                                                />
                                                                                <div>
                                                                                    {editIndex === index ? (
                                                                                        <Input
                                                                                            placeholder="Enter task name"
                                                                                            defaultValue={item.title}
                                                                                            onBlur={(e) => handleTitleChange(index, e)}
                                                                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                                                                            autoFocus
                                                                                            style={{ width: '500px', paddingLeft: '7px' }}
                                                                                            size='large'
                                                                                        />
                                                                                    ) : (
                                                                                        <div style={{ cursor: 'pointer' }}>
                                                                                            <Typography.Text>{item.title || 'Enter task name'}</Typography.Text>
                                                                                            <button
                                                                                                onClick={() => setEditIndex(index)}
                                                                                                style={{
                                                                                                    backgroundColor: 'transparent',
                                                                                                    border: 'none'
                                                                                                }}>
                                                                                                <EditOutlined
                                                                                                    style={{ paddingLeft: '10px', color: "#858c96" }} />
                                                                                            </button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </Space>
                                                                            <DeleteOutlined
                                                                                onClick={() => handleRemove(index)}
                                                                                style={{ marginLeft: 8, cursor: 'pointer' }}
                                                                                className='deleteIcon'
                                                                            />
                                                                        </div>
                                                                    </Tag>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            </>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <Form
                                    form={form}
                                    name="checklistForm"
                                    initialValues={{ subtask: checklist }}
                                >
                                    <div>
                                        {inputVisible &&
                                            <div style={{padding: "4px 0px", borderBottom: "1px solid rgba(217, 217, 217, 0.4196078431)"}}>
                                                <Form.Item
                                                    name="newTask"
                                                    className='subtask-input-item'
                                                >
                                                    <Input
                                                        addonBefore={<Checkbox style={{ marginRight: 8 }} />}
                                                        addonAfter={<CloseOutlined onClick={() => setInputValue('')} style={{ fontSize: '14px' }} />}
                                                        value={inputValue}
                                                        onChange={(e) => setInputValue(e.target.value)}
                                                        onKeyPress={handleKeyPress}
                                                        onBlur={handleBlur}
                                                        size='large'
                                                        placeholder='Enter Task Name...'
                                                        ref={inputRef}
                                                        styles={{
                                                            input: {
                                                                borderRadius: 6
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        }
                                        <Flex style={{paddingTop: 6}} align='center' justify='space-between'>
                                            <Button
                                                style={{ color: "#80868e", fontSize: '14px', padding: 0 }}
                                                size='large'
                                                type="link"
                                                onClick={() => setInputVisible(true)}
                                                icon={<PlusOutlined style={{ fontSize: '11px', lineHeight: '1' }} />}
                                            >
                                                Add Item
                                            </Button>
                                            <Button
                                                style={{ color: "#b73232", fontSize: '14px', padding: 0, borderBottom: '1px dashed #b73232', borderRadius: '0px', height: '22px' }}
                                                type="link"
                                                onClick={() => removeSubTaskList()}
                                            >
                                                Delete SubTask List
                                            </Button>
                                        </Flex>
                                    </div>
                                </Form>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
                {isCollapsed && (
                    <>
                        <div
                            className={`${styleCss.collapseBase} ${styleCss.collapse1}`}
                            style={{ animationDelay: !isCollapsed ? '1s' : '0s' }}
                        ></div>
                        <div
                            className={`${styleCss.collapseBase} ${styleCss.collapse2}`}
                            style={{ animationDelay: !isCollapsed ? '1s' : '0s' }}
                        ></div>
                    </>
                )}
            </div >
        </>
    );
}

export default CheckList;
