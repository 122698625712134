import { Dispatch, SetStateAction, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Avatar, Button, DatePicker, Dropdown, Flex, List, MenuProps, Select, Space, Tooltip } from "antd";
import { MenuOutlined, SettingOutlined } from "@ant-design/icons";
import webSocketService from "../../utils/websocketService";
import fireActiveIcon from "../../assets/fireactive.svg";
import AxiosService from "../../utils/APIService";
import Taskcss from "./style.module.scss";
import Icon from "./Icon";
import dayjs from "dayjs";
import moment from "moment";
import { UserInterface } from "../../reducer/loginReducer";
import { RootState } from "../../reducer";
import { formateDate, getInitials, statusClassName } from "../../utils/commonFunctions";
import { INQUIRY_TYPE, INQUIRY_TYPE_STATUS, TASK_STATUS } from "../../utils/const";
import { connect } from "react-redux";
import { useInquiry } from ".";
import { UserInvite } from "../../components/UserInviteModel";
import { openTask } from "../../action";

interface TaskPropsInterface {
  taskList: any;
  setTaskList: Dispatch<SetStateAction<any>>;
  user: UserInterface | null | undefined;
  pendingTasks: any[];
  openTask: (val: string) => void;
}

const colors = ["#76a3da"];

const Task = ({ setTaskList, taskList, user, pendingTasks, openTask }: TaskPropsInterface) => {
  const { assignList }: any = useInquiry();

  const apiService = new AxiosService();
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const parseDate = (dateStr: string) => {
    const date: any = dayjs(dateStr, "DD-MM-YYYY HH:mm");
    return new Date(date);
  };
  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const sourceColumn = taskList[source.droppableId];
    const destColumn = taskList[destination.droppableId];
    const [removed] = sourceColumn.splice(source.index, 1);
    destColumn.splice(destination.index, 0, removed);

    if (source.droppableId !== destination.droppableId) {
      let taskData = destColumn.find((tsk: any) => tsk.task_id === draggableId);
      if (destination.droppableId == "Today") {
        taskData.task.due_date = moment()
          .set({
            hour: moment(taskData.task.due_date, "DD-MM-YYYY HH:mm").hour(),
            minute: moment(taskData.task.due_date, "DD-MM-YYYY HH:mm").minute(),
          })
          .format("DD-MM-YYYY HH:mm");
        destColumn.sort((a: any, b: any) => parseDate(a.task.due_date).getTime() - parseDate(b.task.due_date).getTime());
      } else if (destination.droppableId == "Up-coming") {
        taskData.task.due_date = moment(taskData.task.due_date, "DD-MM-YYYY HH:mm").add(1, "day").format("DD-MM-YYYY HH:mm");
      }
    }

    setTaskList({
      ...taskList,
      [source.droppableId]: sourceColumn,
      [destination.droppableId]: destColumn,
    });

    const taskWithOrder = sourceColumn.map((dt: any, i: number) => ({ ...dt, task: { ...dt.task, order: i } }));
    if (destination.droppableId !== source.droppableId) {
      const destinationId: string = destination.droppableId === "Today" ? TASK_STATUS.inprogress : TASK_STATUS.pending;

      webSocketService.sendMessage("taskPositionChange", {
        tntId: user?.tenant_id,
        taskId: draggableId,
        destination: destinationId,
        userId: user?._id,
      });
    }
    webSocketService.sendMessage("changeTaskOrder", { tntId: user?.tenant_id, taskList: taskWithOrder });
  };

  const items: MenuProps["items"] = [
    {
      key: TASK_STATUS.completedbyUser,
      label: "Move to Watchlist",
    },
    {
      key: TASK_STATUS.complete,
      label: "Move to Completed",
    },
    {
      key: "DELETE",
      label: <span style={{ color: "#ff0000b0" }}>Delete Request</span>,
      children: [
        { key: "Prise not Matched", label: "Prise not Matched" },
        { key: "Delay Reply", label: "Delay Reply" },
        { key: "Out of Scope", label: "Out of Scope" },
        { key: "Plan Canceled", label: "Plan Canceled" },
        { key: "Miss Behaviour", label: "Miss Behaviour" },
      ],
    },
  ];

  const handleTaskMove = (status: any, id: string) => {
    if (status.keyPath.includes("DELETE")) {
      apiService
        .post(`/inquiry/delete-request`, {
          reason: status.key,
          taskId: id,
        })
        .then((res: any) => {
          console.log(res.message);
        })
        .catch((e) => console.log(e));
    } else {
      apiService
        .put(`/inquiry/change-status/${id}`, {
          status: status.key,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((e) => console.log(e));
    }
  };

  const handleTaskClick = (item: any) => {
    openTask(item.task._id);
    if (!item.read_status) {
      webSocketService.sendMessage("taskRead", {
        tntId: user?.tenant_id,
        taskId: item.task._id,
        userId: user?._id,
      });
    }
  };

  const handleTaskSelect = (taskId: string) => {
    setSelectedTaskId(taskId === selectedTaskId ? null : taskId);
  };

  const handleDueDateChange = (date: any, id: string) => {
    if (date) {
      webSocketService.sendMessage("taskDueDateChange", {
        tntId: user?.tenant_id,
        taskId: id,
        userId: user?._id,
        date: date ? date.format("DD-MM-YYYY HH:mm") : null,
      });
    }
  };

  const handleChange = (val: string, id: string) => {
    webSocketService.sendMessage("taskStatusUpdate", {
      tntId: user?.tenant_id,
      taskId: id,
      userId: user?._id,
      status: val,
    });
  };

  const acceptTaskHandle = (id: string) => {
    apiService
      .post("/inquiry/accept", {
        taskId: id,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateAssigneeList = (val: any, id: string) => {
    webSocketService.sendMessage("taskAssigneeUpdate", {
      tntId: user?.tenant_id,
      taskId: id,
      assignee: val,
      userId: user?._id,
    });
  };

  return (
    <>
      {pendingTasks?.length > 0 && (
        <div className={Taskcss.taskCollaps}>
          <div className="task-header">
            <span className="length-count">{String(pendingTasks?.length)?.padStart(2, "0")}</span>
            <span>Inquiries</span>
          </div>
          <List size="small" bordered>
            <List.Item>
              <div className="task-grid-wrapper header" style={{ gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)" }}>
                <div>
                  <span style={{ marginLeft: "41px" }}>NAME</span>
                </div>
                <div>
                  <span>DUE DATE</span>
                </div>
                <div>
                  <span></span>
                </div>
              </div>
            </List.Item>
            {pendingTasks?.map((dt) => (
              <List.Item key={dt._id}>
                <div className="task-grid-wrapper" style={{ gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)" }}>
                  <Space size={16} align="center">
                    {dt.inquiry_type.length > 1 ? <Icon name={"MULTI"} /> : <Icon name={dt.inquiry_type[0]} />}
                    <span className="list-item-title">
                      {dt.title} {dt.is_high_prior && <img src={fireActiveIcon} />}
                    </span>
                  </Space>
                  <div>
                    <span>{formateDate(dt.due_date)}</span>
                  </div>
                  <div>
                    <Button onClick={() => acceptTaskHandle(dt._id)} type="primary" block className="accept-btn">
                      Accept
                    </Button>
                  </div>
                </div>
              </List.Item>
            ))}
          </List>
        </div>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        {taskList &&
          Object.entries(taskList).map(([colId, colTasks]: any) => (
            <Droppable droppableId={colId} key={colId}>
              {(provided: any) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className={Taskcss.taskCollaps}>
                  <div className="task-header">
                    <span className="length-count">{String(colTasks?.length)?.padStart(2, "0")}</span>
                    <span>{colId}</span>
                  </div>
                  <List size="small" bordered>
                    <List.Item>
                      <div className="task-grid-wrapper header" style={{ gridTemplateColumns: "1fr  minmax(160px, 160px) minmax(169px, 160px)" }}>
                        <Space>
                          <SettingOutlined style={{ lineHeight: "1", marginLeft: "35px", color: "#6f7994a3", marginRight: "1px" }} />
                          <span>NAME</span>
                        </Space>
                        <div>
                          <span>DUE DATE</span>
                        </div>
                        <div className="assigneebg">
                          <span>ASSIGNEE</span>
                        </div>
                      </div>
                    </List.Item>
                    {colTasks &&
                      colTasks?.map((item: any, index: any) => {
                        return (
                          <Draggable key={item.task._id} draggableId={item.task._id} index={index} isDragDisabled={dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm").isBefore()}>
                            {(prov) => (
                              <List.Item ref={prov.innerRef} {...prov.draggableProps} {...prov.dragHandleProps}>
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleTaskSelect(item.task._id);
                                  }}
                                  className="task-grid-wrapper"
                                  style={{
                                    gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)",
                                    backgroundColor: selectedTaskId === item.task._id ? "#f4fcde" : "",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Flex gap={10} align="center" justify="space-between">
                                    <Space>
                                      {item.task.inquiry_type?.length > 1 ? (
                                        <Tooltip title="Multiple Inquiries">
                                          <Icon name="MULTI" style={{ cursor: "pointer" }} />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip title={INQUIRY_TYPE[item?.task?.inquiry_type]?.title}>
                                          <Icon name={item?.task?.inquiry_type?.[0]} />
                                        </Tooltip>
                                      )}
                                      <div
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <Dropdown menu={{ items, onClick: (val: any) => handleTaskMove(val, item.task._id) }} trigger={["click"]} overlayClassName="menuDropdown">
                                          <Button type="link" style={{ padding: "0px", height: "auto" }}>
                                            <MenuOutlined style={{ lineHeight: "1" }} />
                                          </Button>
                                        </Dropdown>
                                      </div>
                                      <span
                                        className={`list-item-title name ${!item.read_status ? "unread" : ""}`}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleTaskClick(item);
                                        }}
                                      >
                                        {item.task.title} {item.task.is_high_prior && <img src={fireActiveIcon} />}
                                      </span>
                                    </Space>
                                    <div
                                      className="status"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <Select
                                        size="small"
                                        onChange={(e) => handleChange(e, item?.task._id)}
                                        value={item?.task?.task_status}
                                        options={Object.keys(INQUIRY_TYPE_STATUS)?.map((key) => ({
                                          value: key,
                                          label: INQUIRY_TYPE_STATUS[key]?.title?.toUpperCase(),
                                        }))}
                                        variant="borderless"
                                        popupClassName="statusSelect"
                                        suffixIcon={null}
                                        className={` ${statusClassName(item.task.task_status)}`}
                                      />
                                    </div>
                                  </Flex>

                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Flex align="center">
                                      <label htmlFor={item.task._id}>
                                        {dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm").isBefore(dayjs(), "second") ? (
                                          <Icon name="OVERDUECALENDER" />
                                        ) : dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm").isSame(dayjs(), "day") ? (
                                          <Icon name="TODAYCALENDER" />
                                        ) : (
                                          <Icon name="CALENDER" />
                                        )}
                                      </label>
                                      <DatePicker
                                        id={item.task._id}
                                        onChange={(val) => handleDueDateChange(val, item.task._id)}
                                        showTime={{ format: "HH:mm" }}
                                        className={`${Taskcss.taskDatePicker} ${
                                          dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm").isBefore(dayjs(), "second")
                                            ? Taskcss.overDue
                                            : dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm").isSame(dayjs(), "day")
                                            ? Taskcss.toDay
                                            : ""
                                        }`}
                                        value={dayjs(item?.task.due_date, "DD-MM-YYYY HH:mm")}
                                        variant="borderless"
                                        format={"MMM D, YYYY"}
                                        suffixIcon={null}
                                      />
                                    </Flex>
                                  </div>
                                  <div
                                    className="assigneebg"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Flex align="center" justify="space-between" className="assignee-cell" style={{ width: "100%" }}>
                                      <Avatar.Group
                                        max={{
                                          count: 3,
                                          style: { color: "#ffffffd6", backgroundColor: "#76a3da" },
                                          popover: { rootClassName: "AsssignnePopover" },
                                        }}
                                        size={28}
                                      >
                                        {item.task.assign_to.map((asn: any, idx: any) => {
                                          return (
                                            <Tooltip title={asn.full_name} key={idx}>
                                              <Avatar
                                                style={{
                                                  backgroundColor: colors[idx % colors.length],
                                                  marginLeft: "-6px",
                                                }}
                                              >
                                                {getInitials(asn.full_name)}
                                              </Avatar>
                                            </Tooltip>
                                          );
                                        })}
                                      </Avatar.Group>
                                      <UserInvite
                                        onSubmit={(val: any) => updateAssigneeList(val, item.task._id)}
                                        assignList={assignList}
                                        user={user}
                                        assigned={item.task.assign_to}
                                      />
                                    </Flex>
                                  </div>
                                </div>
                              </List.Item>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </List>
                </div>
              )}
            </Droppable>
          ))}
      </DragDropContext>
    </>
  );
};

const mapStateToProps = ({ userData }: RootState) => {
  const { user } = userData;
  return { user };
};

export default connect(mapStateToProps, { openTask })(Task);
