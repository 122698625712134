import { Col, Form, Row, Typography } from "antd";
import FormCss from "./form.module.scss";
import React, { useState } from "react";
import { InputBox } from "../../../components/FormInput";
import FileUpload from "../../../components/FileUpload";
import uploadSvg from "../../../assets/upload-svg.svg";
interface AdharcardDocPropsInterface {
    form: any;
    formId: any;
}
const { Title } = Typography;
const AdharcardDoc = ({ form, formId }: AdharcardDocPropsInterface) => {
    const [adharCard, setAdharCard] = useState<any[]>([]);
    const filePlaceholder = (
        <div className="flex flex-col items-center">
            <img className="mb-7" src={uploadSvg} style={{ width: "200px" }} />
            <div>
                <p style={{ color: "#1A73E8", fontSize: "14px" }}>Drag an Image here or click to upload</p>
                <span style={{ color: "#99A1BB", fontSize: "14px" }}>Upload an image in either a PNG or JPEG format. Maximum size: 2MB.</span>
            </div>
        </div>
    );
    return (
        <>
            <div className="pr-5 pl-5 pb-5">
                <Title level={4} className={`${FormCss.titleLevel4}`}>
                    Aadhar Card
                </Title>
                <Row gutter={[15, 15]}>
                    <Col span={24}>
                        <Form.Item className="passport-dropbox mb-1" name={[formId, "passportFront"]}>
                            <FileUpload
                                onRemove={() => {
                                    form.resetFields([formId, "passportFront"]);
                                }}
                                accept=".png, .jpeg, .jpg"
                                required
                                placeholder={filePlaceholder}
                                setFile={(val: any) => {
                                    setAdharCard(val);
                                }}
                                fileData={adharCard}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <InputBox label="Full Name" className="mb-0" placeholder="Enter full name" name={[formId, "adharcard_name"]} />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default AdharcardDoc;
