export const IS_LOGIN = "loginReducer/IS_LOGIN"
export const USER_DATA = "loginReducer/USER_DATA"
export const SET_PROFILE = "loginReducer/SET_PROFILE"

export const isLogin = (val:any) => (disptch:any) =>{
    return disptch({
        type: IS_LOGIN,
        payload: val
    });
}

export const setUserData = (val:any) => (disptch:any) => {
  return disptch({
    type: USER_DATA,
    payload: val
  })
}

export const setProfileData = (val:any) => (disptch:any) => {
  return disptch({
    type: SET_PROFILE,
    payload: val
  })
}
