import {IS_LOGIN, USER_DATA, SET_PROFILE} from "../action/loginAction"

export interface UserInterface{
    _id: String;
    full_name: String;
    email: String;
    contact_no: String;
    role: "ADMIN" | "AGENT_USER" | "AGENT_ADMIN";
    tenant_id: String;
}

export interface UserProfileInterface{
    departments: [{
        name: String;
        _id: String;
    }]
}

export interface LoginState {
    isUserLogin: boolean;
    user?: UserInterface | null;
    profile?: UserProfileInterface | null;
}
const initialState:LoginState = {
    isUserLogin: false,
    user: null,
    profile: null
}

const loginReducer = (state = initialState, action:any) =>{
    switch (action.type) {
        case IS_LOGIN:
            return {
                ...state,
                isUserLogin: action.payload
            }

        case USER_DATA:
            return {
                ...state,
                user: action.payload
            }
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload
            }
        default:
            return state;
    }
}

export default loginReducer
