import { Layout } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import { UserInterface, UserProfileInterface } from '../reducer/loginReducer'
import HeaderLayout from './Layout/Header/HeaderLayout'
import { connect } from 'react-redux'
import { RootState } from '../reducer'
import FooterLayout from './Layout/Footer/Footer'
import SideBarLayout from './Layout/SideBar/SideBarLayout'
import RightSideBar from './Layout/RightSideBar/RightSideBar'
import TaskDetails from '../pages/Inquiry/TaskInfo/TaskDetails'
import { closeTask } from '../action'

const { Content } = Layout
interface UserLayoutInterFace {
    children: ReactNode;
    user: UserInterface | null | undefined;
    isLogin: (val: boolean) => void;
    setProfileData: (val: UserProfileInterface | null) => void;
    setUserData: (val: UserInterface | null) => void;
    sidebarOpen: boolean;
    taskDrawerOpen: any;
    openTaskId: string;
    closeTask: () => void;
    contactDrawerOpen: any
}

const UserLayout = ({ children, 
    sidebarOpen, 
    taskDrawerOpen, 
    openTaskId, 
    closeTask,
    contactDrawerOpen }: UserLayoutInterFace) => {
    const [textVisible, setTextVisible] = useState(true);

    useEffect(() => {
        if (sidebarOpen) {
            setTextVisible(true);
        } else {
            const timer = setTimeout(() => setTextVisible(false), 200);
            return () => clearTimeout(timer);
        }
    }, [sidebarOpen]);

    return (
        <Layout className='userLayout'>
            <SideBarLayout sidebarOpen={sidebarOpen} />
            <Layout style={{ backgroundColor: 'transparent', transition: 'all 0.3s ease-in-out', }} className={`${sidebarOpen ? 'layoutSideBarOpen' : 'layoutSideBarClose'}`}>
                <HeaderLayout />
                <Layout style={{ minHeight: `calc(100vh - ${70 + 63}px)` }}>
                    <Content style={{ backgroundColor: "transparent" }}>
                        <div className='container'>
                            {children}
                        </div>
                    </Content>
                </Layout>
                <FooterLayout />
                <TaskDetails id={openTaskId} closeHandle={() => closeTask()} />
            </Layout>
            <RightSideBar taskDrawerOpen={taskDrawerOpen || contactDrawerOpen} />
        </Layout>
    )
}

const mapStateToProps = ({ layout, minTaskList }: RootState) => {
    const { sidebarOpen, taskDrawerOpen, contactDrawerOpen } = layout
    const { openTaskId } = minTaskList

    return { sidebarOpen, taskDrawerOpen, openTaskId, contactDrawerOpen }
}

export default connect(mapStateToProps, { closeTask })(UserLayout)