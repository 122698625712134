// Reminder Notes List
import { Typography } from "antd";
import { useNote } from ".";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { LuBell } from "react-icons/lu";
import NoteCard from "./NoteCard";
import Masonry from "react-masonry-css";
import styleCss from "./style.module.scss";

dayjs.extend(isSameOrAfter);

const ReminderNoteList = () => {
    const now = dayjs();
    const { noteList } = useNote();

    const breakpointColumnsObj = {
        default: 5,
        1400: 4,
        1300: 3,
        1100: 2,
        764: 1,
    };

    // Filter notes with past reminders
    const pastReminder = noteList.filter((noteObj) => {
        const reminderTime = dayjs(noteObj.note.reminder);
        return reminderTime.isBefore(now);
    });

    // Filter notes with future reminders
    const futureReminderNotes = noteList.filter((noteObj) => {
        const reminderTime = noteObj?.note?.reminder;
        if (reminderTime) {
            const reminderDate = dayjs(reminderTime);
            return reminderDate.isSameOrAfter(now);
        }
        return false;
    });

    return (
        <div className={styleCss.noteGirdViewContainer}>
            <div className={styleCss.filterTitle}>
                {!!pastReminder.length && (
                    <>
                        <Typography className="title" style={{ paddingTop: "0px" }}>
                            PAST
                        </Typography>

                        <Masonry breakpointCols={breakpointColumnsObj} className={styleCss.myMasonryGrid} columnClassName={styleCss.myMasonryGridColumn}>
                            {pastReminder?.map((note: any) => (
                                <NoteCard {...note} key={note?.id} />
                            ))}
                        </Masonry>
                    </>
                )}
                {!!pastReminder.length && !!futureReminderNotes?.length && (
                    <Typography className="title" style={{ paddingTop: "20px" }}>
                        UPCOMING
                    </Typography>
                )}
                {!!futureReminderNotes?.length && (
                    <Masonry breakpointCols={breakpointColumnsObj} className={styleCss.myMasonryGrid} columnClassName={styleCss.myMasonryGridColumn}>
                        {futureReminderNotes?.map((note: any) => (
                            <NoteCard {...note} key={note?.id} />
                        ))}
                    </Masonry>
                )}
            </div>
            {!pastReminder.length && !futureReminderNotes?.length && (
                <div className="no-data-notification">
                    <LuBell size={90} className="note-no-data-icon" />
                    <Typography className="no-data-text">Notes with upcoming reminders appear here</Typography>
                </div>
            )}
        </div>
    );
};

export default ReminderNoteList;
