import React from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Form} from 'antd';

interface ContactInputInterface {
    form: any;
    name: any;
    label?: string;
}

function ContactInput({ form, name, label }: ContactInputInterface) {
    return (
        <Form.Item
            name={name}
            rules={[
                {
                    required: true,
                    message: "Please enter phone number!",
                },
                {
                    validator(_, value) {
                        if (value && !isValidPhoneNumber(value)) {
                            return Promise.reject(new Error('Invalid Contact Number'));
                        }
                        return Promise.resolve();
                    },
                }
            ]}
            label={label}
        >
            <PhoneInput
                onChange={(val) => {
                    form.setFieldValue(name, val);
                }}
                placeholder="Enter phone number"
                limitMaxLength
            />
        </Form.Item>
    );
}

export default ContactInput;
