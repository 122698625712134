import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducer";
import { Tabs } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PassportDoc from "./PassportDoc";
import PancardDoc from "./PancardDoc";
import AdharcardDoc from "./AdharcardDoc";
import OtherDoc from "./OtherDoc";
interface FormDocumentPropsInterface {
    form:any;
    formId: any;
}
const FormDocument = ({form, formId}: FormDocumentPropsInterface) => {
    const [docMenu] = useState<any>([
        {
            label: `Passport`,
            key: '1',
            children: '',
            closable: false,
        },
        {
            label: `PAN Card`,
            key: '2',
            children: '',
            closable: false,
        },
        {
            label: `Adhar Card`,
            key: '3',
            children: '',
            closable: false,
        },
        {
            label: `Other Documents`,
            key: '4',
            children: '',
            closable: false,
        },
    ]);
    const [activeKey, setActiveKey] = useState(docMenu[0].key);
    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };
    return (
        <>
            
            <Tabs 
                defaultActiveKey="1" 
                type="card"
                size={"middle"} 
                items={docMenu} 
                className="pr-5 pl-5 pb-5"
                onChange={onChange}
            />
            {activeKey === '1' && <PassportDoc form={form} formId={formId} />}
            {activeKey === '2' && <PancardDoc form={form} formId={formId} />}
            {activeKey === '3' && <AdharcardDoc form={form} formId={formId} />}
            {activeKey === '4' && <OtherDoc form={form} formId={formId} />}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormDocument);
