import dayjs from "dayjs";
import { INQUIRY_TYPE_STATUS } from "./const";

export const capitalizeFirstLetter = (string: any) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeFirstLetterOfWord = (string: any) => {
    if (!string) return '';
    return string.replace(/(?:^|\s)\S/g, (char: any) => char.toUpperCase());
}

export const getInitials = (fullName: string): string => {
    if (!fullName) return '';
    const names = fullName.split(' ').slice(0, 1);
    return names.map(name => name.charAt(0).toUpperCase()).join('');
};

export const formateDate = (val: any) => {
    return dayjs(val, "DD-MM-YYYY HH:mm").format("MMM D, YYYY")
}
export const statusClassName = (taskStatus: string) => {
    const {className } =  INQUIRY_TYPE_STATUS[taskStatus];

    return className
};