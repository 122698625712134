import { MINIMIZE_TASK, CLOSE_MINIMIZE_TASK, CLOSE_TASK, OPEN_TASK } from "../action/taskActions";

interface minList {
    name: string;
    _id:string
}

export interface TaskState {
    taskList: minList[];
    openTaskId: string;
}
const initialState: TaskState = {
    taskList: [],
    openTaskId: ""
};

const taskReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MINIMIZE_TASK:
            return {
                ...state,
                taskList: !state.taskList.find(tl => tl._id === action.payload._id) ? [...state.taskList, action.payload] : [...state.taskList],
            };
        case CLOSE_MINIMIZE_TASK:
            return {
                ...state,
                taskList: state.taskList.filter(tsk => tsk._id !== action.payload),
            };
        case OPEN_TASK:
            return {
                ...state,
                openTaskId: action.payload
            }
        case CLOSE_TASK:
            return {
                ...state,
                openTaskId: ""
            }

        default:
            return state;
    }
};

export default taskReducer;
