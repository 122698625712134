import { Button, Card, Flex, Form } from 'antd'
import React from 'react'
import { InputBox } from '../../components/FormInput';
import AxiosService from '../../utils/APIService';
import { connect } from 'react-redux';
import { RootState } from '../../reducer';
import { isLogin, setProfileData, setUserData } from '../../action';
import { UserInterface, UserProfileInterface } from '../../reducer/loginReducer';
import { LocalStorageService } from '../../utils/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { ROLE, UTILS } from '../../utils/const';
interface LoginValueInterface{
    email: String,
    password: String,
}
interface LoginPropsIntergace{
    isLoading:boolean
    isLogin: (val:boolean) => void
    setUserData: (val:UserInterface | null) => void
    setProfileData: (val:UserProfileInterface | null) => void
}
function Login({ isLoading, isLogin, setUserData, setProfileData }:LoginPropsIntergace) {
    const navigate = useNavigate()

    const apiService = new AxiosService()

    const onFinish = (value:LoginValueInterface) => {
        apiService.post('/auth/sign-in', value)
            .then((res:any) => {
                isLogin(true)
                setUserData(res.user)
                setProfileData({...res.profile, _id: undefined})
                LocalStorageService.setItem(UTILS.token, res?.token)
                LocalStorageService.setItem(UTILS.user, res?.user)

                navigate('/inquiry')
                // if(res?.user?.role === ROLE.agentUser){
                // }else if(res?.user?.role === ROLE.agentAdmin){
                //     navigate('/team')
                // }
            })
            .catch(() => {
                isLogin(false)
                setUserData(null)
                setProfileData(null)
            })
    }
    return (
        <Flex justify='center'align='center' style={{height: "100vh"}}>
            <Card style={{
                maxWidth: 500,
                width: "100%"
            }}>
                <Form
                    name="Login"
                    onFinish={onFinish}
                    layout='vertical'
                >
                    <InputBox name={"email"} label='Email' rules={[{ required: true, message: 'Please Enter Email!' }]} />
                    <InputBox type='password' name={"password"} label='Password' rules={[{ required: true, message: 'Please Enter Password!' }]} />
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Flex>
    )
}
const mapStateToProps = ({loading}: RootState) => {
    const {
      isLoading
    } = loading
    return {
      isLoading,
    }
}
export default connect(mapStateToProps, {
    isLogin,
    setUserData,
    setProfileData
}) (Login)
