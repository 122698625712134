import { Button, Drawer, Flex } from "antd";
import React from "react";
import StyleCss from "./component.module.scss";
import { CheckOutlined, CloseOutlined, SyncOutlined } from "@ant-design/icons";
interface DrawerCustomPropsInterface {
    open: boolean;
    width: any;
    footer?: React.ReactNode[];
    drawerCloseHandle: () => void;
    handleMinimize: () => void;
    children: any;
    closeText:string;
}
const minimizeArrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
        <path
            fill="#525C69"
            d="M7.383 4.32a.686.686 0 0 0-.971 0l-.768.767a.686.686 0 0 0 0 .97l6.633 6.634H7.21a.686.686 0 0 0-.686.687v.882c0 .38.307.687.686.687h8.43a.689.689 0 0 0 .686-.687V5.831a.687.687 0 0 0-.686-.687h-1.132a.686.686 0 0 0-.687.687v4.926L7.383 4.319ZM5.987 17.715a.687.687 0 0 0-.687.687v.883c0 .379.308.686.687.686h12.327c.379 0 .686-.307.686-.686v-.883a.687.687 0 0 0-.686-.687H5.987Z"
        />
    </svg>
);
const DrawerCustom = ({ open, width, footer, drawerCloseHandle, handleMinimize, closeText, children }: DrawerCustomPropsInterface) => {
    return (
        <Drawer
            onClose={drawerCloseHandle}
            open={open}
            width={width}
            className={StyleCss.taskDrawer}
            styles={{
                header: {
                    display: "none",
                },
            }}
            footer={footer}
            style={{ boxShadow: "0px 0px 5px white" }}
        >
            <Flex onClick={drawerCloseHandle} className="close-label" align="center">
                <CloseOutlined style={{ fontSize: "12px" }} />
                <span>{closeText}</span>
            </Flex>

            <span className="minimize-label" onClick={handleMinimize}>
                {minimizeArrow}
            </span>

            <div className={`sync-label`}>
                <CheckOutlined style={{ color: "#525C69" }} />
            </div>
            {children}
        </Drawer>
    );
};

export default DrawerCustom;
