import { Avatar, Button, Dropdown, Flex, List, MenuProps, Space, Tooltip } from "antd";
import { MenuOutlined, SettingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import Taskcss from "./style.module.scss";
import AxiosService from "../../utils/APIService";
import webSocketService from "../../utils/websocketService";
import fireActiveIcon from "../../assets/fireactive.svg";
import Icon from "./Icon";
import { UserInterface } from "../../reducer/loginReducer";
import { RootState } from "../../reducer";
import { INQUIRY_TYPE, TASK_STATUS } from "../../utils/const";
import { getInitials, statusClassName } from "../../utils/commonFunctions";

interface PendingTaskInterface {
  user: UserInterface | null | undefined;
  filter: any;
}

const colors = ["#76a3da"];

function DeletedList({ user, filter }: PendingTaskInterface) {
  const apiService = new AxiosService();
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    webSocketService.onMessage("whishlistRefresh", (val) => {
      if (val.tenantId === user?.tenant_id) {
        fetchWishlist();
      }
    });
  }, [user]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchWishlist();
    }, 100);
    return () => clearTimeout(timeOut);
  }, [filter]);

  const formateDate = (val: any) => {
    return dayjs(val).format("MMM D, YYYY");
  };

  const fetchWishlist = () => {
    apiService
      .get("/inquiry/list", {
        isDeleted: true,
        ...filter,
      })
      .then((res: any) => {
        setList(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const items: MenuProps["items"] = [
    {
      key: TASK_STATUS.pending,
      label: "Move back to Task",
    },
    {
      key: TASK_STATUS.completedbyUser,
      label: "Move back to Watchlist",
    },
    {
      key: TASK_STATUS.complete,
      label: "Move back to Completed",
    },
  ];

  const handleTaskMove = (status: any, id: string) => {
    apiService
      .put(`/inquiry/change-status/${id}`, {
        status: status.key,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className={Taskcss.taskCollaps}>
        <div className="task-header">
          <span className="length-count">{String(list?.length)?.padStart(2, "0")}</span>
          <span>Deleted</span>
        </div>
        <List size="small" bordered>
          <List.Item>
            <div className="task-grid-wrapper header" style={{ gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)" }}>
              <Space>
                <SettingOutlined style={{ lineHeight: "1", marginLeft: "35px", color: "#6f7994a3", marginRight: "1px" }} />
                <span>NAME</span>
              </Space>
              <div>
                <span>DUE DATE</span>
              </div>
              <div className="assigneebg">
                <span>ASSIGNEE</span>
              </div>
            </div>
          </List.Item>
          {list?.map((dt) => {
            return (
              <List.Item key={dt._id}>
                <div className="task-grid-wrapper" style={{ gridTemplateColumns: "1fr minmax(160px, 160px) minmax(169px, 160px)" }}>
                  <Flex gap={10} align="center" justify="space-between">
                    <Space>
                      {dt.task.inquiry_type?.length > 1 ? (
                        <Tooltip title="Multiple Inquiries">
                          <Icon name="MULTI" style={{ cursor: "pointer" }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title={INQUIRY_TYPE[dt?.task?.inquiry_type]?.title}>
                          <Icon name={dt?.task?.inquiry_type?.[0]} style={{ cursor: "pointer" }} />
                        </Tooltip>
                      )}
                      <div onClick={(event) => event.stopPropagation()}>
                        <Dropdown menu={{ items, onClick: (val: any) => handleTaskMove(val, dt.task._id) }} trigger={["click"]}>
                          <Button type="link" style={{ padding: "0px", height: "auto" }}>
                            <MenuOutlined style={{ lineHeight: "1" }} />
                          </Button>
                        </Dropdown>
                      </div>
                      <span className="list-item-title">
                        {dt?.task?.title} {dt?.task?.is_high_prior && <img src={fireActiveIcon} />}
                      </span>
                    </Space>
                    <div style={{ pointerEvents: "none", cursor: "not-allowed" }} className="deleteStatus">
                      <span className={` ${statusClassName(dt.task.task_status)}`}>{dt?.task?.delete_reason?.toUpperCase()}</span>
                    </div>
                  </Flex>
                  <div>
                    <span>{formateDate(dt.task.due_date)}</span>
                  </div>
                  <div className="assigneebg">
                    <Flex align="center" className="assignee-cell" style={{ width: "100%" }}>
                      <Avatar.Group
                        max={{
                          count: 3,
                          style: { backgroundColor: "#76a3da" },
                          popover: { rootClassName: "AsssignnePopover" },
                        }}
                        size={28}
                      >
                        {dt.task.assign_to.map((asn: any, idx: any) => {
                          return (
                            <Tooltip title={asn.full_name} key={idx}>
                              <Avatar
                                style={{
                                  backgroundColor: colors[idx % colors.length],
                                  marginLeft: "-6px",
                                }}
                              >
                                {getInitials(asn.full_name)}
                              </Avatar>
                            </Tooltip>
                          );
                        })}
                      </Avatar.Group>
                    </Flex>
                  </div>
                </div>
              </List.Item>
            );
          })}
        </List>
      </div>
    </>
  );
}

const mapStateToProps = ({ userData }: RootState) => {
  const { user } = userData;
  return {
    user,
  };
};

export default connect(mapStateToProps, {})(DeletedList);
