import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducer";
import FormCss from "./form.module.scss";
import personSVG from "../../../assets/personAvtar.svg";
import pencilSVG from "../../../assets/pencil.svg";
import { Button, Col, Divider, Image, Row, Typography, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ContactInput from "./contactInput";
import { InputBox, InputSelect } from "../../../components/FormInput";
import { cities, country, getStateByCity, stateList } from "../../../utils/data";
import FormDocument from "./FormDocument";
const state = stateList()
const {Title} = Typography
interface ContactFormPropsInterface {
    formId: number;
    form: any;
}
const ContactForm = ({ formId, form }: ContactFormPropsInterface) => {
    const selectStateBaseOnCity = (val:any) => {
        form.setFieldValue([formId, "state"], getStateByCity(val))
    }
    useEffect(() => {
        form.setFieldValue([formId, "country"], "IN")
    }, []);
    return (
        <div className={FormCss.FormComponent}>
            <div className="pt-5 pr-5 pl-5">
                <Row gutter={[20, 0]}>
                    <Col span={24}>
                        <div className={FormCss.avatar}>
                            <Image src={personSVG} preview={false} />
                            <Upload maxCount={1} className="upload-button">
                                <div>
                                    <Image src={pencilSVG} preview={false} />
                                </div>
                            </Upload>
                        </div>
                    </Col>
                    <Col span={12}>
                        <InputBox className="input-field" name={[formId, "full_name"]} label="Name (First & Last Name)" placeholder="Enter name" />
                    </Col>
                    <Col span={12}>
                        <InputBox rules={[{ type: "email" }]} className="input-field" name={[formId, "email"]} label="Email" placeholder="Enter Email" />
                    </Col>
                    <Col span={12}>
                        <ContactInput form={form} name={[formId, "phone_no"]} label="Contact Number" />
                    </Col>
                    <Col span={12}>
                        <InputBox className="input-field" name={[formId, "reference"]} label="Reference By" placeholder="Enter name" />
                    </Col>
                    <Col span={24}>
                        <InputBox className="input-field" name={[formId, "address"]} label="Current Residents Address" placeholder="Enter address" />
                    </Col>
                    <Col span={12}>
                        <InputSelect 
                            name={[formId, "city"]} 
                            options={cities.map((ct:any) => ({
                                label: ct.name,
                                value: ct.name
                            }))} 
                            label="City"
                            placeholder="Select City" 
                            showSearch
                            onChangeHandle={selectStateBaseOnCity}
                        />
                    </Col>
                    <Col span={12}>
                        <InputBox className="input-field" name={[formId, "pincode"]} label="Pin Code" placeholder="Enter Pin Code" />
                    </Col>
                    <Col span={12}>
                        <InputSelect 
                            name={[formId, "state"]} 
                            options={state.map((st:string) => ({
                                label: st,
                                value: st
                            }))} 
                            label="State"
                            placeholder="Select State" 
                            showSearch
                        />
                    </Col>
                    <Col span={12}>
                        <InputSelect 
                            name={[formId, "country"]} 
                            options={country.map(ct => ({
                                label: ct.name,
                                value: ct.code
                            }))} 
                            label="Country" 
                            placeholder="Select Country"
                            showSearch
                        />
                    </Col>
                </Row>
            </div>
            <Divider style={{
                borderBlockStart: '1px solid rgba(219, 223, 233, 1)',
                margin: 0
            }} />
            <div className="p-5">
                <Title level={3} className={FormCss.titleLevel3}>Upload Document</Title>
            </div>
            <FormDocument form={form} formId={formId} />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
