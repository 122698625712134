import React from 'react';

interface IconProps {
    color?: string;
    size?: number;
}

const Frame: React.FC<IconProps> = ({ color = "#333333", size = 20 }) => {
    return (
        <svg
            width={size}
            height={(size * 20) / 21}
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.70833 17.5H4.29167C3.84964 17.5 3.42572 17.3244 3.11316 17.0118C2.80059 16.6993 2.625 16.2754 2.625 15.8333V4.16667C2.625 3.72464 2.80059 3.30072 3.11316 2.98816C3.42572 2.67559 3.84964 2.5 4.29167 2.5H15.9583C16.4004 2.5 16.8243 2.67559 17.1368 2.98816C17.4494 3.30072 17.625 3.72464 17.625 4.16667V12.5L15.0417 9.91667C14.7281 9.60931 14.3059 9.43813 13.8668 9.44032C13.4277 9.4425 13.0072 9.61788 12.6967 9.92833L5.125 17.5"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.792 16.25L14.292 13.75L16.792 16.25"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.292 18.3333V13.75"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.62467 9.16659C8.54515 9.16659 9.29134 8.42039 9.29134 7.49992C9.29134 6.57944 8.54515 5.83325 7.62467 5.83325C6.7042 5.83325 5.95801 6.57944 5.95801 7.49992C5.95801 8.42039 6.7042 9.16659 7.62467 9.16659Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Frame;
