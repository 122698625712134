import React, { useEffect, useState } from 'react'
import AxiosService from '../utils/APIService'
export interface TeamMemberInterface {
    full_name: string;
    _id: string
}
function useTeamMember() {
    const [assignList, setAssignList] = useState<TeamMemberInterface[]>([])

    const apiService = new AxiosService()

    // useEffect(() => {
    //     fetchAssignList()
    // }, [])

    const fetchAssignList = () => {
        apiService.get('/user/list')
            .then((res: any) => {
                setAssignList(res.data)
            })
            .catch(e => {
                console.log(e);
            })
    }
    
    return {
        assignList,
        fetchAssignList
    }
}

export default useTeamMember
