import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Button, Flex, Input, Modal, Space, Typography } from "antd";
import styleCss from "./style.module.scss";
import commonCss from "../../css/commonStyle.module.scss";
import NavTab from "../../components/NavTab";
import CreateNote from "./CreateNote";
import NoteList from "./NoteList";
import useTeamMember from "../../hooks/useTeamMember";
import AxiosService from "../../utils/APIService";
import { MdLabel, MdEdit, MdClose, MdCheck, MdDelete, MdAdd } from "react-icons/md";
import _ from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import { ArchiveForBulk, BellIcon, ColorPickerIcon, MoreOptionsIcon, PinNotes } from "./NotesFooterIcons";
import { NOTES_FOOTER_TYPE } from "../../utils/const";
import ReminderNoteList from "./ReminderNoteList";
import BinNoteList from "./BinNoteList";
import { NOTES_STATUS } from "../../utils/const";
import ArchiveNoteList from "./ArchiveNoteList";

export interface ILabels {
    created_on: any;
    title: string;
    user: string;
    __v: any;
    _id: string;
}

interface EditLabelState {
    id: string | null;
    value: string;
}

interface NoteContextType {
    assignList: any;
    noteList: any[];
    fetchList: () => void;
    fetchAllLabel: () => void;
    labelList: ILabels[];
    createLabel: (value: string) => void;
    addLabelByNoteId: (noteId: string, labelIds: string[]) => void;
    UpdateReminderByNoteId: (noteId: string, reminder: Date | null, refresh?: boolean) => void;
    UploadDocByNoteId: (noteId: string, file: any[], refresh?: boolean) => void;
    DeleteDocByNoteId: (noteId: string, file: string, refresh?: boolean) => void;
    updateShareList: (noteId: string, list: any) => void;
    getUniqIdsOnly: any;
    noteSelectHandle: (notes: any) => void;
    selectedNotes: any[];
    activeTab: string;
    changesNoteStatus: (noteId: string, status: string) => void;
    handleDeleteNotes: (noteId: string) => void;
    binNoteList: any[];
    archiveNoteList: any[];
    makeCopyById: (noteId: string) => void;
    bulkLabelChange: (noteIds: string[], labels: any[]) => void;
    bulkMakeCopy: (noteIds: string[]) => void;
    bulkStatusChange: (noteIds: string[], status: string) => void;
    bulkPinNotes: (noteIds: string[], value: boolean) => void;
    getNoteIds: (notes: any[]) => string[];
    emptyBin: (notes: any[]) => void;
    getArchiveNoteList: () => void;
}
const NoteContext = createContext<NoteContextType | undefined>(undefined);

export const useNote = () => {
    const context = useContext(NoteContext);
    if (!context) {
        throw new Error("useNote must be used within a NoteProvider");
    }
    return context;
};

function Notes() {
    const apiService = new AxiosService();
    const { assignList, fetchAssignList } = useTeamMember();
    const [noteList, setNoteList] = useState<any[]>([]);
    const [binNoteList, setBinNoteList] = useState<any[]>([]);
    const [archiveNoteList, setArchiveNoteList] = useState<any[]>([]);
    const [labelList, setLabelList] = useState<ILabels[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<any>({
        isModelOpen: false,
        id: null,
    });
    const [newLabel, setNewLabel] = useState<any>({ isCreate: true, value: "" });
    const [error, setError] = useState<string>("");
    const [editLabelState, setEditLabelState] = useState<EditLabelState>({ id: null, value: "" });
    const [bulkActionParams, setBulkActionParams] = useState({
        reminder: "",
    });
    const [selectedNotes, setSelectedNotes] = useState<any>([]);
    const [activeTab, setActiveTab] = useState<any>("notes");
    const [selectedLabels, setSelectedLabels] = useState<any[]>([]);
    const [isRemainderOpen, setIsRemainderOpen] = useState(false);

    const fetchList = () => {
        apiService.get("/notes/list").then((res: any) => {
            setNoteList(res.data);
        });
    };

    const fetchAllLabel = useCallback(() => {
        apiService.get("/notes-label/list").then((res: any) => {
            setLabelList(res.data);
        });
    }, [apiService]);

    const createLabel = (title: string) => {
        apiService.post("notes-label/create", { title: title }, false, false).then(() => {
            fetchAllLabel();
            setNewLabel({ isCreate: true, value: "" });
        });
    };

    const updateLabel = (id: string, title: string) => {
        apiService.put(`notes-label/update/${id}`, { title }, false, false).then(() => {
            fetchAllLabel();
            setEditLabelState({ id: null, value: "" });
        });
    };

    const deleteLabel = (id: string) => {
        apiService.delete(`notes-label/delete/${id}`, false, false).then(() => {
            fetchAllLabel();
            fetchList();
        });
    };

    const addLabelByNoteId = (noteId: string, labelIds: string[]) => {
        apiService.put(`notes/update-labels/${noteId}`, { labelIds: labelIds }, false, false).then(() => {
            fetchList();
        });
    };

    const UpdateReminderByNoteId = (noteId: string, reminder: Date | null, refresh = false) => {
        apiService.post(`notes/reminder-update/${noteId}`, { time: reminder }, false, false).then(() => {
            if (refresh) fetchList();
        });
    };

    const UploadDocByNoteId = (noteId: string, doc: any, refresh = false) => {
        apiService.put(`notes/file-upload/${noteId}`, doc, false, false).then(() => {
            if (refresh) fetchList();
        });
    };

    const updateShareList = (noteId: string, shareList: any) => {
        apiService.post(`notes/share-list-update/${noteId}`, { list: shareList }, false, false).then(() => {
            fetchList();
        });
    };

    const DeleteDocByNoteId = (noteId: string, fileName: any, refresh?: boolean) => {
        apiService.put(`notes/file-remove/${noteId}`, { fileName: fileName }, false, false).then(() => {
            if (refresh) fetchList();
        });
    };

    const getUniqIdsOnly = (data: any[]) => {
        return _.uniq(_.map(data, "_id"));
    };

    const changesNoteStatus = (id: string, status: string) => {
        apiService
            .put(`/notes/status-change/${id}`, { status }, false, false)
            .then(() => {
                getBinNoteList();
                fetchList();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getBinNoteList = () => {
        apiService.get("/notes/list", { status: NOTES_STATUS.deleted }).then((res: any) => {
            setBinNoteList(res.data);
        });
    };

    const getArchiveNoteList = () => {
        apiService.get("/notes/list", { status: NOTES_STATUS.archive }).then((res: any) => {
            setArchiveNoteList(res.data);
        });
    };

    const handleDeleteNotes = (id: string) => {
        apiService.delete(`/notes/delete/${id}`).then(() => {
            fetchList();
            getBinNoteList();
        });
    };

    const makeCopyById = (id: string) => {
        apiService.post(`/notes/clone-note/${id}`, {}).then(() => {
            fetchList();
        });
    };

    const bulkLabelChange = (ids: any[], labels: any[]) => {
        apiService.post(`/notes/bulk-label`, { ids, labels }).then(() => {
            fetchList();
            setSelectedNotes([]);
        });
    };

    const bulkMakeCopy = (ids: any[]) => {
        apiService.post(`/notes/bulk-note-clone`, { ids }).then(() => {
            fetchList();
            setSelectedNotes([]);
        });
    };

    const bulkStatusChange = (ids: any[], status: string) => {
        apiService.post(`/notes/bulk-status-change`, { ids, status }).then(() => {
            fetchList();
            setSelectedNotes([]);
        });
    };

    const bulkColorChange = (ids: any[], color: string) => {
        apiService.post(`/notes/bulk-color-change`, { ids, color }).then(() => {
            fetchList();
            setSelectedNotes([]);
        });
    };

    const bulkReminder = (ids: any[], reminder: Date | null) => {
        apiService.post(`notes/bulk-reminder`, { ids, time: reminder }, false, false).then(() => {
            fetchList();
            setSelectedNotes([]);
        });
    };

    const bulkPinNotes = (ids: any[], value: boolean) => {
        apiService.post(`/notes/bulk-pin`, { ids, isPinned: value }).then(() => {
            fetchList();
            setSelectedNotes([]);
        });
    };

    const emptyBin = (ids: any[]) => {
        apiService.post(`/notes/all-delete`, { ids }).then(() => {
            getBinNoteList();
        });
    };

    useEffect(() => {
        fetchAssignList();
        fetchList();
        fetchAllLabel();
    }, []);

    useEffect(() => {
        setSelectedNotes([]);
        if (activeTab === "bin") getBinNoteList();
        if (activeTab === "archive") getArchiveNoteList();
        if (activeTab === "notes") fetchList();
    }, [activeTab]);

    const viewItem = [
        {
            key: "1",
            label: "List view",
            children: "",
        },
        {
            key: "2",
            label: "Grid view",
            children: "",
        },
    ];

    const filterView = [
        {
            key: "notes",
            label: "Notes",
            children: "",
        },
        {
            key: "reminders",
            label: "Reminders",
            children: "",
        },
        {
            key: "editLabels",
            label: "Edit labels",
            children: "",
        },
        {
            key: "archive",
            label: "Archive",
            children: "",
        },
        {
            key: "bin",
            label: "Bin",
            children: "",
        },
    ];

    const onChange = (key: string) => {
        setActiveTab(key);
        if (key === "editLabels") {
            setTimeout(() => {
                setIsModalVisible(true);
            }, 0);
        }
    };

    const handleCreateLabel = () => {
        const trimmedValue = newLabel.value.trim();
        if (!trimmedValue) return;
        const isLabelExists = labelList?.some(({ title }) => title === trimmedValue);
        if (isLabelExists) {
            setError("Label already exists.");
        } else {
            createLabel(trimmedValue);
            setError("");
        }
    };

    const handleEditLabelChange = (id: string | null, value: string) => {
        setEditLabelState({ id, value: value.trim() });
    };

    const handleSaveEdit = () => {
        const { id, value } = editLabelState;
        if (id && value) {
            updateLabel(id, value);
            setEditLabelState({ id: null, value: "" });
        }
    };

    const noteSelectHandle = (data: any) => {
        const isIncluded = selectedNotes.some((dt: any) => dt?._id === data?._id);
        if (isIncluded) {
            setSelectedNotes(selectedNotes.filter((dt: any) => dt?._id !== data?._id));
        } else {
            setSelectedNotes([...selectedNotes, data]);
        }
    };

    const getNoteIds = (notes: any[]) => {
        const notesIds = notes?.map((item) => {
            return item?.note?._id;
        });
        return notesIds;
    };

    const onColorChange = (selectedColor: string) => {
        bulkColorChange(getNoteIds(selectedNotes), selectedColor);
    };

    const handleBulkReminder = (date: any) => {
        bulkReminder(getNoteIds(selectedNotes), date);
        setIsRemainderOpen(false);
    };

    return (
        <>
            <NoteContext.Provider
                value={{
                    assignList,
                    noteList,
                    fetchList,
                    fetchAllLabel,
                    labelList,
                    createLabel,
                    addLabelByNoteId,
                    getUniqIdsOnly,
                    UpdateReminderByNoteId,
                    updateShareList,
                    UploadDocByNoteId,
                    DeleteDocByNoteId,
                    noteSelectHandle,
                    selectedNotes,
                    activeTab,
                    changesNoteStatus,
                    binNoteList,
                    handleDeleteNotes,
                    archiveNoteList,
                    makeCopyById,
                    bulkLabelChange,
                    bulkMakeCopy,
                    bulkStatusChange,
                    bulkPinNotes,
                    getNoteIds,
                    emptyBin,
                    getArchiveNoteList,
                }}
            >
                <Flex justify="space-between" align="center" style={{ marginTop: 2 }} className={commonCss.mytaskBlock}>
                    <Space size={20} align="center">
                        <Typography.Title level={3} className={commonCss.pageTitle}>
                            Notes
                        </Typography.Title>
                        <div style={{ width: "210px" }}>
                            <NavTab items={viewItem} />
                        </div>
                    </Space>
                    <div style={{ width: "440px" }}>
                        <NavTab items={filterView} onChange={onChange} activeKey={activeTab} defaultActiveKey={"notes"} />
                    </div>
                </Flex>

                <div className={styleCss.noteView}>
                    <div className={`${styleCss.selectDrawer} ${selectedNotes.length > 0 ? styleCss.show : ""}`}>
                        <Flex align="center" justify="space-between">
                            <Flex align="center">
                                <Button onClick={() => setSelectedNotes([])} className={styleCss.closeBtn} shape="circle" type="text" icon={<MdClose size={25} />} />
                                <Typography.Text className={styleCss.selectText}>{selectedNotes.length} selected</Typography.Text>
                            </Flex>
                            <Flex align="center" gap={24} style={{ paddingRight: 20 }}>
                                <PinNotes />
                                <BellIcon
                                    isRemainderOptionOpen={isRemainderOpen}
                                    setIsRemainderOptionOpen={setIsRemainderOpen}
                                    reminder={""}
                                    setReminder={handleBulkReminder}
                                    footerType={NOTES_FOOTER_TYPE.bulkAction}
                                />
                                <ColorPickerIcon onColorChange={onColorChange} footerType={NOTES_FOOTER_TYPE.bulkAction} />
                                <ArchiveForBulk />
                                <MoreOptionsIcon
                                    isMoreOptionOpen={false}
                                    selectedLabels={selectedLabels}
                                    setSelectedLabels={setSelectedLabels}
                                    footerType={NOTES_FOOTER_TYPE.bulkAction}
                                />
                            </Flex>
                        </Flex>
                    </div>
                    {(activeTab === "notes" || activeTab === "editLabels") && <CreateNote />}
                    {(activeTab === "notes" || activeTab === "editLabels") && <NoteList />}
                    {activeTab === "reminders" && <ReminderNoteList />}
                    {activeTab === "archive" && <ArchiveNoteList />}
                    {activeTab === "bin" && <BinNoteList />}
                </div>

                {/* Edit Label model */}
                <Modal
                    open={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    afterClose={() => setActiveTab("notes")}
                    footer={null}
                    width={300}
                    closable={false}
                    className={styleCss.editLabel}
                    centered
                >
                    <div className="labelContent">
                        <Typography>Edit labels</Typography>
                        <Flex align="center" style={{ margin: "10px 0" }}>
                            {newLabel.isCreate ? (
                                <MdClose
                                    size={28}
                                    color="#4b4b4b"
                                    onClick={() => {
                                        setNewLabel({ isCreate: false, value: "" });
                                        setError("");
                                    }}
                                />
                            ) : (
                                <MdAdd size={28} color="#4b4b4b" onClick={() => setNewLabel({ isCreate: true, value: "" })} />
                            )}
                            <div>
                                <Input
                                    value={newLabel.value}
                                    placeholder="Create new label"
                                    onChange={(e) => setNewLabel({ isCreate: true, value: e.target.value })}
                                    onPressEnter={handleCreateLabel}
                                    autoFocus
                                    variant="borderless"
                                />
                            </div>
                            {newLabel.isCreate && <MdCheck color="#4b4b4b" size={28} onClick={handleCreateLabel} style={{ marginLeft: "8px" }} />}
                        </Flex>
                        {error && <Typography.Text className="error">{error}</Typography.Text>}
                        {labelList?.map((label) => (
                            <>
                                <Flex align="center" key={label._id} className="labelList">
                                    <MdLabel size={28} color="gray" />
                                    {editLabelState.id === label._id ? (
                                        <>
                                            <div>
                                                <Input
                                                    value={editLabelState.value}
                                                    onChange={(e) => handleEditLabelChange(label._id, e.target.value)}
                                                    onPressEnter={handleSaveEdit}
                                                    autoFocus
                                                    variant="borderless"
                                                />
                                            </div>
                                            <MdCheck size={28} onClick={handleSaveEdit} />
                                        </>
                                    ) : (
                                        <>
                                            <Typography.Text style={{ flex: 1 }}>{label.title}</Typography.Text>
                                            <MdEdit
                                                color="gray"
                                                size={28}
                                                onClick={() => {
                                                    handleEditLabelChange(label._id, label.title);
                                                    setNewLabel({ isCreate: false, value: "" });
                                                }}
                                                style={{ marginRight: "2px" }}
                                            />
                                            <MdDelete
                                                color="gray"
                                                size={28}
                                                onClick={() =>
                                                    setIsDelete({
                                                        isModelOpen: true,
                                                        id: label._id,
                                                    })
                                                }
                                            />
                                        </>
                                    )}
                                </Flex>
                            </>
                        ))}
                    </div>
                    <div className="labelFooter">
                        <Flex justify="end">
                            <Button onClick={() => setIsModalVisible(false)}>Done</Button>
                        </Flex>
                    </div>
                    <Modal
                        centered
                        open={isDelete?.isModelOpen}
                        onCancel={() => setIsDelete({ isModelOpen: false, id: null })}
                        width={430}
                        footer={false}
                        closable={false}
                        className={styleCss.confirmDelete}
                    >
                        <Typography>We'll delete this label and remove it from all of your Keep notes. Your notes won’t be deleted.</Typography>
                        <Flex justify="end">
                            <Button onClick={() => setIsDelete({ isModelOpen: false, id: null })} className="cancelBtn">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    deleteLabel(isDelete?.id);
                                    setIsDelete({ isModelOpen: false, id: null });
                                }}
                                className="deleteBtn"
                            >
                                Delete
                            </Button>
                        </Flex>
                    </Modal>
                </Modal>
            </NoteContext.Provider>
        </>
    );
}

export default Notes;
