import React, { useState } from 'react'
import { Button, Col, Flex, List, Row, Typography } from 'antd';
import FormCss from "./form.module.scss";
import { CloseOutlined, DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import FileUpload from '../../../components/FileUpload';
import { InputBox, InputSelect } from '../../../components/FormInput';
import publishSvg from "../../../assets/publish.svg";

const { Title } = Typography;
interface OtherDocPropsInterface{
    form: any;
    formId: any;
}
const OtherDoc = ({form, formId}:OtherDocPropsInterface) => {
    const [historyData, setHistoryData] = useState([
        {
            id: "1",
            name: "Driving Licence",
            expDate: "25/09/2024"
        },
        {
            id: "2",
            name: "PAN Card",
            expDate: "08/07/2024"
        },
        {
            id: "3",
            name: "GST",
            expDate: "01/05/2024"
        },
        {
            id: "4",
            name: "Aadhar Card",
            expDate: "22/04/2024"
        }
    ]);
    const [addPassport, setAddPassport] = useState(false);
    const [doc, setDoc] = useState([]);
    const filePlaceholderMd = (
        <div className="flex flex-col items-center">
            <img className="mb-7" src={publishSvg} style={{ width: "40px" }} />
            <div>
                <p style={{ color: "#1A73E8", fontSize: "14px" }}>Drag an Image here or click to upload</p>
                <span style={{ color: "#99A1BB", fontSize: "14px" }}>Upload an image in either a PNG or JPEG format. Maximum size: 2MB.</span>
            </div>
        </div>
    );
    const toggleDoc = () => {
        setAddPassport(!addPassport)
    }
  return (
    <>
        <div className="p-5">
                <Title level={4} className={`${FormCss.titleLevel4}`}>
                    Full Passport 
                </Title>
                <Button onClick={toggleDoc} type="primary" className={`${FormCss.addButton} mb-1`} block icon={<PlusOutlined />}>Add Other Document</Button>
                {addPassport && <div className={`${FormCss.uploadCard} mb-2`}>
                    <Flex justify="space-between" align="center" className="card-header">
                        <Title className='card-title' level={2}>Upload</Title>
                        <Button onClick={toggleDoc} type="link" style={{color: "inherit"}}><CloseOutlined style={{fontSize: "15px"}} /></Button>
                    </Flex>
                    <div className="card-body">
                        <div className="space-wrapper">
                            <div className="passport-dropbox-md">
                                <Row gutter={[0, 15]}>
                                    <Col span={24}>
                                        <FileUpload
                                                accept=".png, .jpeg, .jpg"
                                                required
                                                placeholder={filePlaceholderMd}
                                                setFile={(val: any) => {
                                                    setDoc(val);
                                                }}
                                                fileData={doc}
                                            />
                                    </Col>
                                    <Col span={24}>
                                    <InputSelect options={[{label: "GST", value: "GST"}, {label: "Driving Licence", value: "Driving Licence"}]} label='Select Type' placeholder='Select Document'/>
                                        {/* <InputBox type="date" label="Date" className="mb-0" /> */}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Flex justify="flex-end">
                            <Button className={FormCss.formButton} type="primary">Upload</Button>
                        </Flex>   
                    </div>
                </div>}
                <div className={`${FormCss.uploadCard}`}>
                    <Flex justify="space-between" align="center" className="card-header">
                        <Title level={3} style={{marginBottom: 0}} className={FormCss.titleLevel3}>Uploaded Documents </Title>
                    </Flex>
                    <div className="card-body">
                        <List
                            size="small"
                            className={FormCss.docList}
                            header={<div className="doc-list-header">
                                <div>Name</div>
                                <div>Expiry Date</div>
                                <div>Action</div>
                            </div>}
                            dataSource={historyData}
                            renderItem={(item) => (
                                <List.Item className="doc-list-item" key={item.id}>
                                    <div>{item.name}</div>
                                    <div>{item.expDate}</div>
                                    <div>
                                        <Flex gap={16}>
                                            <Button style={{color: "rgba(153, 161, 187, 1)"}} type="link" icon={<EyeOutlined />} />
                                            <Button style={{color: "rgba(153, 161, 187, 1)"}} type="link" icon={<DeleteOutlined />} />
                                        </Flex>
                                    </div>
                                </List.Item>)}
                        />
                    </div>
                </div>
            </div>
    </>
  )
}

export default OtherDoc