export const MINIMIZE_TASK = "loginReducer/MINIMIZE_TASK"
export const CLOSE_MINIMIZE_TASK = "loginReducer/CLOSE_MINIMIZE_TASK"
export const OPEN_TASK = "loginReducer/OPEN_TASK"
export const CLOSE_TASK = "loginReducer/CLOSE_TASK"

export const setMinimizeTask = (val:any) => (disptch:any) =>{
    return disptch({
        type: MINIMIZE_TASK,
        payload: val
    });
}
export const closeMinimizeTask = (val:any) => (disptch:any) =>{
    return disptch({
        type: CLOSE_MINIMIZE_TASK,
        payload: val
    });
}
export const openTask = (val:any) => (dispatch:any) => {
    return dispatch({
        type: OPEN_TASK,
        payload: val
    });
}
export const closeTask = () => (dispatch:any) => {
    return dispatch({
        type: CLOSE_TASK
    });
}