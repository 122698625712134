import { DownOutlined, MenuOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Dropdown, Flex, Image, Input, Layout, MenuProps, Segmented, Space, Typography } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import logo from '../assets/logo.svg'
import { LocalStorageService } from '../../../utils/LocalStorage'
import { UserInterface } from '../../../reducer/loginReducer'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { RootState } from '../../../reducer'
import { isLogin, setProfileData, setUserData, setSidebarToggle } from '../../../action'
import downArrow from '../../../assets/down-arrow.svg'
import headerCss from './style.module.scss'

interface HeaderLayoutPropsInterface {
    user: UserInterface | null | undefined;
    isLogin: (val: boolean) => void;
    setProfileData: (val: any) => void;
    setUserData: (val: any) => void;
    setSidebarToggle: (val: boolean) => void;
    sidebarOpen: boolean
}
function HeaderLayout({ user, setProfileData, isLogin, setUserData, sidebarOpen, setSidebarToggle }: HeaderLayoutPropsInterface) {
    const [date, setDate] = useState(new Date());
    const navigate = useNavigate()
    const menuItems: MenuProps['items'] = [
        {
            key: '1',
            label: 'Logout'
        },
    ];

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);

        return () => {
            clearInterval(timerID);
        };
    }, []);
    const tick = useCallback(() => {
        setDate(new Date());
    }, []);
    const options: any = { hour: '2-digit', minute: '2-digit', hour12: false };
    const timeString = useMemo(() => date.toLocaleTimeString('en-GB', options), [date]);;
    const menuItemSelect = (val: any) => {
        switch (val.key) {
            case '1':
                LocalStorageService.removeItem('token')
                LocalStorageService.removeItem('user')
                isLogin(false)
                setProfileData(null)
                setUserData(null)
                navigate('/login')
                break;
        }
    }
    return (
        <Layout.Header className={headerCss.customHeader}>
            <div className={headerCss.container}>
                <Flex justify='space-between' align='center'>
                    <Flex style={{ width: "100%" }} align='center'>
                        <Flex align='center' style={{ width: '44.5%', marginRight: "1.8%" }}>
                            <Button icon={<MenuOutlined style={{ fontSize: 22 }} />} type='text' onClick={() => setSidebarToggle(!sidebarOpen)} />
                            <Input.Search
                                styles={{
                                    affixWrapper: {
                                        backgroundColor: "#F5F6FA1A",
                                        border: "none",
                                        borderRadius: 50,
                                        color: "#fff",
                                        padding: "7px 16px"
                                    },
                                    input: {
                                        paddingLeft: 6
                                    }
                                }}
                                prefix={<SearchOutlined />}
                                placeholder='Search'
                                className={headerCss.customSearch}
                                size='large'
                            />
                        </Flex>
                    </Flex>
                    <Typography.Text className={headerCss.clockText}>{timeString}</Typography.Text>
                    <Dropdown menu={{ items: menuItems, onClick: menuItemSelect }} trigger={['click']}>
                        <div className={headerCss.profileLink}>
                            <Flex align='center'>
                                <Avatar size={36} icon={<UserOutlined />} style={{ marginRight: 8, background: "rgb(255 255 255 / 32%)" }} />
                                <div className='profile-dropdown'>
                                    <Flex align='center'>
                                        <Typography.Text className='user-name'>{user?.full_name}</Typography.Text>
                                        <img src={downArrow} />
                                    </Flex>
                                </div>
                            </Flex>
                        </div>
                    </Dropdown>
                </Flex>
            </div>
        </Layout.Header>
    )
}
const mapStateToProps = ({ layout, userData }: RootState) => {
    const { sidebarOpen } = layout;
    const { user } = userData;
    return { sidebarOpen, user }
}
export default connect(mapStateToProps, { setUserData, isLogin, setProfileData, setSidebarToggle })(HeaderLayout)