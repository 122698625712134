import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { isLogin, onLoading, setProfileData, setUserData } from "../action";
import { store } from "..";
import { LocalStorageService } from "./LocalStorage";
import { message } from "antd";  // Make sure you import your history object if you use react-router

interface AxiosServiceOptions {
    baseURL: string;
    timeout: number;
    headers?: Record<string, string>;
}

class AxiosService {
    private instance: AxiosInstance;

    constructor(options?: AxiosServiceOptions) {
        const token = LocalStorageService.getItem("token");
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: options?.timeout,
            headers: {
                Authorization: `Bearer ${token}`
            },
        });

        this.instance.interceptors.response.use(
            response => response,
            (error: AxiosError) => {
                if (error.response?.status === 401) {
                    LocalStorageService.removeItem('token')
                    LocalStorageService.removeItem('user')
                    store.dispatch<any>(isLogin(false))
                    store.dispatch<any>(setProfileData(null)) 
                    store.dispatch<any>(setUserData(null))
                    // window.location.href = "/login";
                }
                return Promise.reject(error);
            }
        );
    }
    public get<T>(url: string, params?: any, loading: boolean = true) {
        if (loading) store.dispatch<any>(onLoading(true));
        return this.instance
            .get<T>(url, { params })
            .then((response: AxiosResponse<T>) => {
                if (loading) store.dispatch<any>(onLoading(false));
                return response.data;
            })
            .catch((error: AxiosError) => {
                if (loading) store.dispatch<any>(onLoading(false));
                return error
            });
    }

    public post<T>(url: string, data: any, loading: boolean = true, showMessage:Boolean = true): Promise<T> {
        if (loading) store.dispatch<any>(onLoading(true));
        return this.instance
            .post<T>(url, data)
            .then((response: AxiosResponse<T>) => {
                let res:any = response.data
                if (loading) store.dispatch<any>(onLoading(false));
                if (showMessage) message.success(res.message)
                return res
            })
            .catch((error: AxiosError) => {
                let res:any = error.response?.data
                if (loading) store.dispatch<any>(onLoading(false));
                if(res){
                    if (showMessage) message.error(res?.message)
                    throw res
                }else{
                    if (showMessage) message.error(`Error posting data: ${error.message}`)
                    throw new Error(`Error posting data: ${error.message}`);
                }
            })
    }

    public put<T>(url: string, data: any, loading: boolean = true, showMessage:Boolean = true): Promise<T> {
        if (loading) store.dispatch<any>(onLoading(true));

        return this.instance
            .put<T>(url, data)
            .then((response: AxiosResponse<T>) => {
                let res:any = response.data
                if (loading) store.dispatch<any>(onLoading(false));
                if(showMessage) message.success(res.message)
                    return res
            })
            .catch((error: AxiosError) => {
                let res:any = error.response?.data
                if (loading) store.dispatch<any>(onLoading(false));
                if(res) {
                    if (showMessage) message.error(res?.message)
                    return res;
                }else {
                    if (showMessage) message.error(`Error putting data: ${error.message}`)
                    throw new Error(`Error putting data: ${error.message}`);
                }
            })
        }

    public delete<T>(url: string, loading: boolean = true,showMessage:Boolean = true): Promise<T> {
            if (loading) store.dispatch<any>(onLoading(true));
            return this.instance
            .delete<T>(url)
            .then((response: AxiosResponse<T>) => {
                let res:any = response.data
                if(showMessage) message.success(res.message)
                if (loading) store.dispatch<any>(onLoading(false));
                return res
            })
            .catch((error: AxiosError) => {
                let res:any = error.response?.data
                if (loading) store.dispatch<any>(onLoading(false));
                if(res) {
                    if (showMessage) message.error(res?.message)
                    return res;
                }else {
                    if (showMessage) message.error(`Error putting data: ${error.message}`)
                        throw new Error(`Error deleting data: ${error.message}`);
                }
            })
    }
}

export default AxiosService;