// NoteList
import { useEffect, useState } from "react";
import { Flex, Tag, Typography } from "antd";
import { useNote } from ".";
import Masonry from "react-masonry-css";
import NoteCard from "./NoteCard";
import styleCss from "./style.module.scss";

const { Title } = Typography;

const NoteList = () => {
    const { noteList, labelList } = useNote();
    const [filteredNotesList, setFilteredNotesList] = useState(noteList);
    const [selectedLabelId, setSelectedLabelId] = useState<string | null>(null);

    const breakpointColumnsObj = {
        default: 5,
        1400: 4,
        1300: 3,
        1100: 2,
        764: 1,
    };

    useEffect(() => {
        setFilteredNotesList(selectedLabelId ? noteList?.filter((note) => note?.tag?.some((tag: any) => tag?._id === selectedLabelId)) : noteList);
    }, [selectedLabelId, noteList]);

    const handleTagClick = (tagId: string) => {
        setSelectedLabelId(selectedLabelId === tagId ? null : tagId);
    };

    const PinnedNote = filteredNotesList?.filter((note) => note?.is_pinned);

    const OtherNote = filteredNotesList?.filter((note) => !note?.is_pinned);

    return (
        <div className={styleCss.noteGirdViewContainer}>
            <div className={styleCss.labelContainer}>
                {labelList?.length > 0 && (
                    <Flex align="flex-start" gap={10}>
                        <Title level={4} className={styleCss.labelTitle}>
                            Labels
                        </Title>
                        <Flex gap={8} wrap className={styleCss.tag}>
                            {labelList?.map(({ _id, title }) => (
                                <Tag
                                    color={selectedLabelId === _id ? "#00000096" : "#00000057"}
                                    key={_id}
                                    bordered={false}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleTagClick(_id)}
                                >
                                    {title}
                                </Tag>
                            ))}
                        </Flex>
                    </Flex>
                )}
            </div>

            <div className={styleCss.filterTitle}>
                {!!PinnedNote?.length && (
                    <>
                        <Typography className="title" style={{ paddingTop: "0px" }}>
                            PINNED
                        </Typography>

                        <Masonry breakpointCols={breakpointColumnsObj} className={styleCss.myMasonryGrid} columnClassName={styleCss.myMasonryGridColumn}>
                            {PinnedNote?.map((note: any) => (
                                <NoteCard {...note} key={note?.id} />
                            ))}
                        </Masonry>
                    </>
                )}
                {!!PinnedNote.length && !!OtherNote?.length && (
                    <Typography className="title" style={{ paddingTop: "20px" }}>
                        OTHERS
                    </Typography>
                )}
                <Masonry breakpointCols={breakpointColumnsObj} className={styleCss.myMasonryGrid} columnClassName={styleCss.myMasonryGridColumn}>
                    {OtherNote?.map((note: any) => (
                        <NoteCard {...note} key={note?.id} />
                    ))}
                </Masonry>
            </div>
        </div>
    );
};

export default NoteList;
