import React from "react";
import SearchHeader from "../../components/SearchHeader";
import { Avatar, Button, Image, Space, Table, Typography } from "antd";
import CreateContact from "./CreateContact";
import DataTable, { TableColumnInterface } from "../../components/DataTable";
import passport from "../../assets/passport-enb.png";
import panCard from "../../assets/pancard-enb.png";
import adharCard from "../../assets/adharcard-enb.png";
import docIcon from "../../assets/docIcon.svg";
import { DownOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import styleCss from "./style.module.scss"

function index() {
    const column: any[] = [
        Table.SELECTION_COLUMN,
        Table.EXPAND_COLUMN,
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (val:any) => <>
                <Space size={10}>
                    <Avatar size={28} icon={<UserOutlined />} />
                    <span>{val}</span>
                </Space>
            </>
        },
        {
            title: "Contact Number",
            dataIndex: "contactNumber",
            key: "contactNumber",
            width: "180px"
        },
        {
            title: "Reference by",
            dataIndex: "ref",
            key: "ref",
        },
        {
            title: "Tag",
            dataIndex: "tag",
            key: "tag",
            render: (val: string) => <div className={`${styleCss.contactLabel} ${val === "Regular" ? styleCss.red : styleCss.green}`}>{val}</div>,
            width: "100px"
        },
        {
            title: "Document",
            dataIndex: "doc",
            key: "doc",
            render: (val: string) => (
                <>
                    <Space>
                        <Image src={passport} preview={false} />
                        <Image src={panCard} preview={false} />
                        {/* <Image src={adharCard} preview={false} /> */}
                        <Image src={docIcon} preview={false} />
                    </Space>
                </>
            ),
            width: "180px"
        }
    ];
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: any) => console.log(record),
    };
    return (
        <div>
            <SearchHeader title={"Passenger List"}>
                <CreateContact />
            </SearchHeader>
            <div style={{marginTop: 15}}>
                <DataTable
                    column={column}
                    data={[
                        {
                            key: "1",
                            name: "Mr. Raghunath Chandra Narayan Prasad",
                            contactNumber: "+91-9868745698",
                            ref: "bipin@546gmail.com",
                            tag: "Premium",
                            children: [
                                {
                                    key: "1-1",
                                    name: "Mr. Bharath Kumar Jagannatha Prasad Gupta",
                                    contactNumber: "+91-9868745698",
                                    ref: "dratime45+8gmail.com",
                                    tag: "Regular",
                                },
                                {
                                    key: "1-2",
                                    name: "Mr.Lakshmipriya Ramachandra Varma Nair",
                                    contactNumber: "+91-9868745698",
                                    ref: "dratime45+8gmail.com",
                                    tag: "Regular",
                                }
                            ]
                        },
                        {
                            key: "2",
                            name: "user4",
                            contactNumber: "+91 9996665554",
                            ref: "smit@google.com",
                            tag: "Regular",
                            children: [
                                {
                                    key: "2-1",
                                    name: "user5",
                                    contactNumber: "+91 9996665554",
                                    ref: "smit@google.com",
                                    tag: "Regular",
                                }
                            ]
                        }
                    ]}
                    rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                    }}
                    expandable={{
                        expandIcon: ({ expanded, onExpand, record }:any) =>
                            record.children ? (
                                expanded ? (
                                    <DownOutlined onClick={(e) => onExpand(record, e)} />
                                ) : (
                                    <RightOutlined onClick={(e) => onExpand(record, e)} />
                                )
                            ) : null,
                        rowExpandable: (record:any) => !!record.children,
                        expandedRowRender: () => {return null}
                    }}
                    pagination={false}
                />
            </div>
        </div>
    );
}

export default index;
