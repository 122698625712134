export const SIDEBAR_CHANGE = "layoutReducer/SIDEBAR_CHANGE";
export const CREATE_TASK_TOGGLE = "layoutReducer/CREATE_TASK_TOGGLE";
export const CONTACT_DRAWER_TOGGLE = "layoutReducer/CONTACT_DRAWER_TOGGLE";

export const setSidebarToggle = (val: boolean) => (disptch: any) => {
    return disptch({
        type: SIDEBAR_CHANGE,
        payload: val,
    });
};

export const setTaskDrawerOpen = (val: boolean) => (disptch: any) => {
    return disptch({
        type: CREATE_TASK_TOGGLE,
        payload: val,
    });
};

export const contactDrawer = (val: boolean) => (disptch: any) => {
    return disptch({
        type: CONTACT_DRAWER_TOGGLE,
        payload: val,
    });
};