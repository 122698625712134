import { Reducer, combineReducers } from "redux";
import loadingReducer, { LoadingState } from "./loadingReducer";
import logingReducer, { LoginState } from "./loginReducer";
import layoutReducer, { LayoutState } from "./layoutReducer";
import TaskReducer, { TaskState } from "./taskReducer";
export interface RootState {
    loading: LoadingState;
    userData: LoginState;
    layout: LayoutState;
    minTaskList: TaskState;
}
const rootReducer: Reducer<RootState> = combineReducers({
    loading: loadingReducer,
    userData: logingReducer,
    layout: layoutReducer,
    minTaskList: TaskReducer,
});
export default rootReducer;