import { SIDEBAR_CHANGE, CREATE_TASK_TOGGLE, CONTACT_DRAWER_TOGGLE } from "../action/layoutActions";
import { DRAWER_TYPE } from "../utils/const";

export interface LayoutState {
    sidebarOpen: boolean;
    taskDrawerOpen:  "CREATE_TASK" | "TASK_DETAIL" | "CREATE_CONTACT" | null | undefined;
    contactDrawerOpen:  "CREATE_CONTACT" | null | undefined;
}
const initialState: LayoutState = {
    sidebarOpen: false,
    taskDrawerOpen: null,
    contactDrawerOpen: null
};

const layoutReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SIDEBAR_CHANGE:
            return {
                ...state,
                sidebarOpen: action.payload,
            };

        case CREATE_TASK_TOGGLE:
            return {
                ...state,
                taskDrawerOpen: action.payload,
            }
        case CONTACT_DRAWER_TOGGLE:
            return {
                ...state,
                contactDrawerOpen: action.payload,
            }
        default:
            return state;
    }
};

export default layoutReducer;
