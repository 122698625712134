//  CardFooter

import React, { useEffect, useState } from "react";
import { BellIcon, UserPlusIcon, ColorPickerIcon, FrameIcon, MoreOptionsIcon, UndoIcon, RedoIcon } from "./NotesFooterIcons";
import { useNote } from ".";
import { Flex, Tooltip, UploadFile } from "antd";
import { connect } from "react-redux";
import { RootState } from "../../reducer";
import { UserInterface } from "../../reducer/loginReducer";
import styleCss from "./style.module.scss";
import { NOTES_FOOTER_TYPE } from "../../utils/const";

interface CardFooterProps {
    isHover: boolean;
    onColorChange: (color: string) => void;
    onCheckboxToggle: () => void;
    showCheckboxes: boolean;
    id?: string;
    onUserListChange: (val: any[]) => void;
    selectedLabels: any[];
    setSelectedLabels: (val: any[]) => void;
    userShareList: any[];
    reminder: string;
    setReminder: (data: string) => void;
    isRemainderOptionOpen: boolean;
    setIsRemainderOptionOpen: (data: boolean) => void;
    uploadFileList?: UploadFile[];
    setUploadFileList?: React.Dispatch<React.SetStateAction<UploadFile[]>>;
    user?: UserInterface | undefined | null;
    footerType: string | undefined;
    setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardFooter: React.FC<CardFooterProps> = ({
    isHover,
    onColorChange,
    onCheckboxToggle,
    showCheckboxes,
    id = "",
    onUserListChange,
    selectedLabels,
    setSelectedLabels,
    userShareList,
    reminder,
    setReminder,
    isRemainderOptionOpen,
    setIsRemainderOptionOpen,
    uploadFileList,
    setUploadFileList,
    user,
    footerType = NOTES_FOOTER_TYPE.cardBottom,
    setIsModalOpen,
}) => {
    const [isColorPopoverOpen, setIsColorPopoverOpen] = useState(false);
    const [isMoreOptionOpen, setIsMoreOptionOpen] = useState(false);
    const { assignList } = useNote();
    const [isShareOpen, setIsShareOpen] = useState(false);

    const [teamList, setTeamList] = useState([]);
    const [shareList, setShareList] = useState<any>([]);

    useEffect(() => {
        if (user && assignList && assignList.length > 0) {
            setTeamList(assignList.filter((dt: any) => dt._id !== user._id));
        }
    }, [assignList, user]);

    useEffect(() => {
        setShareList(userShareList);
    }, [userShareList]);

    const handleVisibleChange = (visible: boolean) => {
        setIsColorPopoverOpen(visible);
    };

    return (
        <>
            {footerType === NOTES_FOOTER_TYPE.cardBottom ? (
                <>
                    <div
                        className={`${styleCss.placeholder} ${
                            !isHover && !isColorPopoverOpen && !isMoreOptionOpen && !isShareOpen && !isRemainderOptionOpen ? "" : styleCss.showSpace
                        }`}
                    ></div>
                    <Flex
                        justify="space-between"
                        className={`${styleCss.cardFooter} ${
                            !isHover && !isColorPopoverOpen && !isMoreOptionOpen && !isShareOpen && !isRemainderOptionOpen ? "" : styleCss.showBlock
                        }`}
                        onClick={(event) => event.stopPropagation()}
                    >
                        <BellIcon
                            isRemainderOptionOpen={isRemainderOptionOpen}
                            setIsRemainderOptionOpen={setIsRemainderOptionOpen}
                            reminder={reminder}
                            setReminder={setReminder}
                            footerType={footerType}
                        />
                        <UserPlusIcon shareOpen={isShareOpen} setSherOpen={setIsShareOpen} teamList={teamList} userList={onUserListChange} shareList={shareList} />
                        <ColorPickerIcon onColorChange={onColorChange} handleVisibleChange={handleVisibleChange} isColorPopoverOpen={isColorPopoverOpen} footerType={footerType} />
                        <FrameIcon id={id} footerType={footerType} />
                        <MoreOptionsIcon
                            isMoreOptionOpen={isMoreOptionOpen}
                            setIsMoreOptionOpen={setIsMoreOptionOpen}
                            onCheckboxToggle={onCheckboxToggle}
                            showCheckboxes={showCheckboxes}
                            id={id}
                            selectedLabels={selectedLabels}
                            setSelectedLabels={setSelectedLabels}
                            footerType={footerType}
                        />
                    </Flex>
                </>
            ) : (
                <>
                    <BellIcon
                        isRemainderOptionOpen={isRemainderOptionOpen}
                        setIsRemainderOptionOpen={setIsRemainderOptionOpen}
                        reminder={reminder}
                        setReminder={setReminder}
                        footerType={footerType}
                    />
                    <UserPlusIcon shareOpen={isShareOpen} setSherOpen={setIsShareOpen} teamList={teamList} userList={onUserListChange} shareList={shareList} />
                    <ColorPickerIcon onColorChange={onColorChange} handleVisibleChange={handleVisibleChange} isColorPopoverOpen={isColorPopoverOpen} footerType={footerType} />
                    <FrameIcon uploadFileList={uploadFileList} setUploadFileList={setUploadFileList} id={id} footerType={footerType} />
                    <MoreOptionsIcon
                        isMoreOptionOpen={isMoreOptionOpen}
                        setIsMoreOptionOpen={setIsMoreOptionOpen}
                        onCheckboxToggle={onCheckboxToggle}
                        showCheckboxes={showCheckboxes}
                        id={id}
                        selectedLabels={selectedLabels}
                        setSelectedLabels={setSelectedLabels}
                        footerType={footerType}
                        setIsModalOpen={setIsModalOpen ? () => setIsModalOpen((prev) => !prev) : undefined}
                    />
                    {/* <UndoIcon />
                    <RedoIcon /> */}
                </>
            )}
        </>
    );
};
const mapStateToProps = ({ userData }: RootState) => {
    const { user } = userData;

    return { user };
};
export default connect(mapStateToProps, {})(CardFooter);
