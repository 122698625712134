import { Button, Divider, Dropdown, Flex, Layout, Row, Select, Tabs, Tag, Typography } from 'antd'
import { CopyrightOutlined, RightOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import footerCss from './style.module.scss'
import { TweenOneGroup } from 'rc-tween-one';
import { connect } from 'react-redux';
import { RootState } from '../../../reducer';
import { TaskState } from '../../../reducer/taskReducer';
import { closeMinimizeTask, openTask } from '../../../action';

const { Footer } = Layout;

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;
const options = [
    { value: 'English', label: 'English' },
    { value: 'Turkish', label: 'Turkish' },
];

const defaultPanes = [
    { label: 'Tab 1', key: '1' },
    { label: 'Tab 2', key: '2' },
    { label: 'Tab 3', key: '3' },
];

const spread = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.16683 9.99996C9.16683 10.4602 9.53993 10.8333 10.0002 10.8333C10.4604 10.8333 10.8335 10.4602 10.8335 9.99996C10.8335 9.53972 10.4604 9.16663 10.0002 9.16663C9.53993 9.16663 9.16683 9.53972 9.16683 9.99996Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.9998 9.99996C14.9998 10.4602 15.3729 10.8333 15.8332 10.8333C16.2934 10.8333 16.6665 10.4602 16.6665 9.99996C16.6665 9.53972 16.2934 9.16663 15.8332 9.16663C15.3729 9.16663 14.9998 9.53972 14.9998 9.99996Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.33333 9.99996C3.33333 10.4602 3.70643 10.8333 4.16667 10.8333C4.6269 10.8333 5 10.4602 5 9.99996C5 9.53972 4.6269 9.16663 4.16667 9.16663C3.70643 9.16663 3.33333 9.53972 3.33333 9.99996Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
) 

const menu = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8335 10H17.5002" stroke="#828b95" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.8335 15H17.5002" stroke="#828b95" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.8335 5H17.5002" stroke="#828b95" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.5 10H3.33333" stroke="#828b95" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.5 15H3.33333" stroke="#828b95" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.5 5H3.33333" stroke="#828b95" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.6665 10H7.49984" stroke="#828b95" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.6665 15H7.49984" stroke="#828b95" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.6665 5H7.49984" stroke="#828b95" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const rightoutlined = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 15L12 10L7 5" stroke="#828b95" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)
interface FooterPropsInterface {
    taskList?: any[];
    closeMinimizeTask: (val:string) => void; 
    openTask: (val:string) => void
}
function FooterLayout({taskList, closeMinimizeTask, openTask}:FooterPropsInterface) {

    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [activeKey, setActiveKey] = useState(defaultPanes[0].key);
    const [items, setItems] = useState(defaultPanes);
    const [showTabs, setShowTabs] = useState(false);
    const newTabIndex = useRef(0);
    const contentRef = useRef<HTMLDivElement>(null);

    const onChange = (key: string) => {
        setActiveKey(key);
    };

    const add = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        setItems([
            ...items,
            { label: 'New Tab', key: newActiveKey },
        ]);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: TargetKey) => {
        const targetIndex = items.findIndex((pane) => pane.key === targetKey);
        const newPanes = items.filter((pane) => pane.key !== targetKey);
        if (newPanes.length && targetKey === activeKey) {
            const { key } =
                newPanes[
                targetIndex === newPanes.length ? targetIndex - 1 : targetIndex
                ];
            setActiveKey(key);
        }
        setItems(newPanes);
    };

    const onEdit = (targetKey: TargetKey, action: 'add' | 'remove') => {
        if (action === 'add') {
            add();
        } else {
            remove(targetKey);
        }
    };

    const handleChange = (value: string) => {
        setSelectedLanguage(value);
    };

    const handleRightOutlinedClick = () => {
        setShowTabs(!showTabs);
    };

    const handleClose = (removedTag: any) => {
        closeMinimizeTask(removedTag._id)
    };

    const taskTagClick = (id:string) => {
        openTask(id);
    }
    return (
        < >
            <Footer className={footerCss.footer}>
                <Row justify='space-between' align={'middle'}>
                    <Flex align='center' justify='space-between' gap={10} className={footerCss.footerSelect}>
                        <Button style={{ padding: "7px 14.5px", height: "auto", width: "auto", fontWeight: "300" }} type='default'>ATULAM</Button>
                        <Select
                            value={selectedLanguage}
                            style={{ width: 104, fontWeight: '300' }}
                            onChange={handleChange}
                            options={options}
                             />
                        <Flex align='center'>
                            <CopyrightOutlined style={{ fontSize: 18 }} />
                            <Typography style={{ paddingLeft: '5px', fontWeight: '300' }}>2024 ATULAM</Typography>
                        </Flex>
                        <Divider type="vertical" />
                        <Typography style={{ fontWeight: '300' }}>Implementation request</Typography>
                        <Divider type="vertical" />
                        <Typography style={{ fontWeight: '300' }}>Themes</Typography>
                        <Divider type="vertical" />
                        <Typography style={{ fontWeight: '300' }}>Print</Typography>
                    </Flex>
                </Row>
                {taskList && taskList?.length > 0 && <Flex gap={10} align='center' justify='space-between' ref={contentRef} className={`${footerCss.rightFooter} ${showTabs ? footerCss.show : footerCss.hide}`}>
                    <span className={footerCss.minCloseBtn} onClick={handleRightOutlinedClick}>{rightoutlined}</span>
                    <TweenOneGroup
                        appear={false}
                        enter={{ scale: 0.8, opacity: 0, type: 'from', duration: 100 }}
                        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                        onEnd={(e:any) => {
                            if (e.type === 'appear' || e.type === 'enter') {
                            (e.target as any).style = 'display: inline-block';
                            }
                        }}
                    >
                        {taskList?.map(opt => {
                            return (
                                <span key={opt._id} className='' style={{ display: 'inline-block' }}>
                                    <Tag
                                        closable
                                        onClose={(e) => {
                                            e.preventDefault();
                                            handleClose(opt);
                                        }}
                                        onClick={() => taskTagClick(opt._id)}
                                        className={footerCss.minimizeTag}
                                    >
                                        {opt.name}
                                    </Tag>
                                </span>
                            )
                        })}
                        </TweenOneGroup>
                    {/* <Button>{spread}</Button> */}
                    <span className={footerCss.minCloseBtn} onClick={handleRightOutlinedClick}>{menu}</span>
                </Flex>}

            </Footer>
        </>
    )
}
const mapStateToProps = ({minTaskList}:RootState) => {
    const {taskList} = minTaskList
    return {taskList}
}
export default connect(mapStateToProps, {closeMinimizeTask, openTask}) (FooterLayout)