// NotesFooterIcon

import React, { useEffect, useState, Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Button, Popover, Input, Checkbox, Menu, List, Typography, Upload, UploadProps, DatePicker, TimePicker, UploadFile, Divider, Flex, Tooltip } from "antd";
import { LuBellRing } from "react-icons/lu";
import { FiUserPlus } from "react-icons/fi";
import { MdOutlineColorLens, MdMoreVert, MdDeleteForever, MdRestoreFromTrash } from "react-icons/md";
import Frame from "../../assets/Frame";
import { BiUndo, BiRedo } from "react-icons/bi";
import styleCss from "./style.module.scss";
import { NOTES_BACKGROUND_COLOR, NOTES_FOOTER_TYPE, NOTES_STATUS } from "../../utils/const";
import { GoClock } from "react-icons/go";

import { UserInvite } from "../../components/UserInviteModel";
import { ILabels, useNote } from ".";
import AxiosService from "../../utils/APIService";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { IoArrowBackOutline } from "react-icons/io5";
import { PiCheckSquareBold } from "react-icons/pi";
import { RiInboxArchiveLine, RiPushpin2Fill, RiPushpin2Line } from "react-icons/ri";

dayjs.extend(utc);
const { Text } = Typography;

interface IBellIcon {
    isRemainderOptionOpen?: boolean;
    setIsRemainderOptionOpen?: (visible: boolean) => void;
    reminder: string;
    setReminder: (data: string) => void;
    footerType: string;
}
interface ColorPickerIconProps {
    onColorChange: (color: string) => void;
    handleVisibleChange?: (visible: boolean) => void;
    isColorPopoverOpen?: boolean;
    footerType: string;
}

interface MoreOptionsIconProps {
    isMoreOptionOpen: boolean;
    setIsMoreOptionOpen?: (moreOptionsOpen: boolean) => void;
    onCheckboxToggle?: () => void;
    showCheckboxes?: boolean;
    id?: string;
    selectedLabels: any[];
    setSelectedLabels: (val: any[]) => void;
    footerType: string;
    setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IUploadDocumentProps {
    uploadFileList?: UploadFile[];
    setUploadFileList?: React.Dispatch<React.SetStateAction<UploadFile[]>>;
    id: string;
    footerType: string;
}

interface ICheckBoxInCrateNote {
    onCheckboxToggle: () => void;
}

export const BellIcon: React.FC<IBellIcon> = ({ isRemainderOptionOpen, setIsRemainderOptionOpen, reminder, setReminder, footerType }) => {
    const [dateTime, setDateTime] = useState<{ date: dayjs.Dayjs | null; time: dayjs.Dayjs | null }>({ date: null, time: null });
    const [showDateTimePicker, setShowDateTimePicker] = useState<null | boolean>(false);

    useEffect(() => {
        if (footerType === NOTES_FOOTER_TYPE.bulkAction) setShowDateTimePicker(true);
    }, [footerType]);

    useEffect(() => {
        if (reminder) {
            const reminderDate = dayjs(reminder);
            setDateTime({
                date: reminderDate.startOf("day"),
                time: reminderDate,
            });
            setShowDateTimePicker(true);
        } else if (footerType !== NOTES_FOOTER_TYPE.bulkAction) {
            setShowDateTimePicker(false);
        }
    }, [reminder]);

    const handleOptionClick = useCallback(
        (option: string) => {
            const now = dayjs();
            let dateTime = dayjs.utc();
            if (option === "Today") {
                dateTime = now.set("hour", 20).set("minute", 0).set("second", 0);
            } else if (option === "Tomorrow") {
                dateTime = now.add(1, "day").set("hour", 8).set("minute", 0).set("second", 0);
            } else if (option === "Pick Date & Time") {
                setShowDateTimePicker(true);
                return;
            }
            setReminder(dateTime.toISOString());
            setIsRemainderOptionOpen && setIsRemainderOptionOpen(false);
        },
        [setReminder, setIsRemainderOptionOpen]
    );

    const handleSave = useCallback(() => {
        const { date, time } = dateTime;
        if (date && time) {
            const combinedDateTime = date.set("hour", time.hour()).set("minute", time.minute()).set("second", 0);
            setReminder(combinedDateTime.toISOString());
        }
        setShowDateTimePicker(false);
        setIsRemainderOptionOpen && setIsRemainderOptionOpen(false);
    }, [dateTime, setReminder, setIsRemainderOptionOpen]);

    const handleDateChange = useCallback((date: dayjs.Dayjs | null) => {
        setDateTime((prev) => ({ ...prev, date }));
    }, []);

    const handleTimeChange = useCallback((time: dayjs.Dayjs | null) => {
        setDateTime((prev) => ({ ...prev, time }));
    }, []);

    const handleBellClick = useCallback(() => {
        setIsRemainderOptionOpen && setIsRemainderOptionOpen(true);
    }, [setIsRemainderOptionOpen]);

    const handleVisibleChange = useCallback(() => {
        setIsRemainderOptionOpen && setIsRemainderOptionOpen(!isRemainderOptionOpen);
    }, [isRemainderOptionOpen, setIsRemainderOptionOpen]);

    const popoverContent = useMemo(() => {
        const now = dayjs();
        const isBeforeEightAM = now.hour() < 8;
        const todayTime = isBeforeEightAM ? "8:00 AM" : "8:00 PM";

        return showDateTimePicker ? (
            <div className="chooseDate">
                <Flex gap={8} align="center" className="title">
                    <IoArrowBackOutline size={18} onClick={() => setShowDateTimePicker(false)} color="#555555" />
                    <Typography>Pick Date & Time</Typography>
                </Flex>
                <Divider style={{ borderColor: "rgba(100, 100, 100, 0.2)", margin: "0px" }} />
                <div className="dateTimepicker">
                    <DatePicker value={dateTime.date} inputReadOnly onChange={handleDateChange} format={"MMM DD, YYYY"} variant="borderless" />
                    <TimePicker value={dateTime.time} inputReadOnly onChange={handleTimeChange} format={"h:mm A"} variant="borderless" />
                    <Flex justify="end">
                        <Button disabled={dateTime?.date === null || dateTime?.time === null} onClick={handleSave}>
                            Save
                        </Button>
                    </Flex>
                </div>
            </div>
        ) : (
            <div className="selectDate">
                <Typography.Title>Reminder:</Typography.Title>
                <Button onClick={() => handleOptionClick("Today")}>
                    <Typography>Later today</Typography>
                    <Typography>{todayTime}</Typography>
                </Button>
                <Button onClick={() => handleOptionClick("Tomorrow")}>
                    <Typography>Tomorrow</Typography>
                    <Typography> 8:00 AM</Typography>
                </Button>
                <Button onClick={() => handleOptionClick("Pick Date & Time")}>
                    <Flex justify="start" align="center" gap={10}>
                        <GoClock />
                        <Typography>Pick Date & Time</Typography>
                    </Flex>
                </Button>
            </div>
        );
    }, [showDateTimePicker, handleOptionClick, handleDateChange, handleTimeChange, handleSave]);

    return (
        <Tooltip title="Remind me">
            <Popover
                trigger="click"
                placement="bottomLeft"
                visible={isRemainderOptionOpen}
                onVisibleChange={handleVisibleChange}
                content={popoverContent}
                overlayClassName={styleCss.reminderPopover}
            >
                <Button type="link" icon={<LuBellRing size={footerType === NOTES_FOOTER_TYPE.bulkAction ? 21 : 20} color="#444444" />} onClick={handleBellClick} />
            </Popover>
        </Tooltip>
    );
};

// User Plus Icon Component
interface UserPlusIconProps {
    userList: (val: any[]) => void;
    shareList?: any[];
    teamList: any[];
    shareOpen: boolean;
    setSherOpen: Dispatch<SetStateAction<boolean>>;
}
export const UserPlusIcon = ({ userList, teamList, shareList, shareOpen, setSherOpen }: UserPlusIconProps) => {
    return (
        <UserInvite visibility={shareOpen} setVisibility={setSherOpen} onSubmit={userList} assigned={shareList} assignList={teamList}>
            <Tooltip title="Collaborator">
                <Button type="link" icon={<FiUserPlus size={20} color="#444444" />} />
            </Tooltip>
        </UserInvite>
    );
};

// Color Picker Icon Component
export const ColorPickerIcon: React.FC<ColorPickerIconProps> = ({ onColorChange, handleVisibleChange, isColorPopoverOpen, footerType }) => {
    const ColorPopoverContent = (
        <div
            style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                flexDirection: "row",
                width: "100%",
            }}
        >
            {NOTES_BACKGROUND_COLOR?.map((color) => (
                <div
                    key={color}
                    style={{
                        backgroundColor: color,
                        width: "24px",
                        height: "24px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        border: "1px solid #ddd",
                    }}
                    onClick={() => onColorChange(color)}
                />
            ))}
        </div>
    );

    return (
        <Tooltip title="Background option">
            <Popover content={ColorPopoverContent} trigger="click" placement="bottom" visible={isColorPopoverOpen} onVisibleChange={handleVisibleChange}>
                <Button type="link" icon={<MdOutlineColorLens size={footerType === NOTES_FOOTER_TYPE.bulkAction ? 22 : 20} color="#444444" />} style={{ display: "inline-flex" }} />
            </Popover>
        </Tooltip>
    );
};

// Frame Icon Component
export const FrameIcon: React.FC<IUploadDocumentProps> = ({ setUploadFileList, uploadFileList, id, footerType }) => {
    const { UploadDocByNoteId } = useNote();

    const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
        newFileList.forEach((file) => {
            if (!file.url && !file.preview) {
                file.preview = URL.createObjectURL(file.originFileObj as Blob);
            }
        });

        if (footerType === NOTES_FOOTER_TYPE.createNote) {
            setUploadFileList && setUploadFileList(newFileList);
        }
        if (footerType === NOTES_FOOTER_TYPE.editNoteModal || footerType === NOTES_FOOTER_TYPE.cardBottom) {
            setUploadFileList && setUploadFileList(newFileList);
            const formData: any = new FormData();
            if (newFileList?.length > 0) {
                for (let i = 0; i < newFileList.length; i++) {
                    const file: any = newFileList[i];
                    formData.append(`doc_${i + 1}`, file.originFileObj);
                }
            }
            UploadDocByNoteId(id, formData, footerType === NOTES_FOOTER_TYPE.cardBottom ? true : false);
        }
    };

    return (
        <Tooltip title="Add image">
            <Upload onChange={handleChange} showUploadList={false} fileList={uploadFileList || []} multiple={false} beforeUpload={() => false}>
                <Button type="link" icon={<Frame color="#444444" />} />
            </Upload>
        </Tooltip>
    );
};

// More Options Icon Component
export const MoreOptionsIcon: React.FC<MoreOptionsIconProps> = ({
    isMoreOptionOpen,
    setIsMoreOptionOpen,
    onCheckboxToggle,
    showCheckboxes,
    id,
    selectedLabels,
    setSelectedLabels,
    footerType,
    setIsModalOpen,
}) => {
    const {
        labelList,
        createLabel,
        fetchAllLabel,
        addLabelByNoteId,
        getUniqIdsOnly,
        changesNoteStatus,
        activeTab,
        makeCopyById,
        bulkLabelChange,
        selectedNotes,
        getNoteIds,
        bulkMakeCopy,
        bulkStatusChange,
        getArchiveNoteList,
    } = useNote();
    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(isMoreOptionOpen || false);
    const [addLabelVisible, setAddLabelVisible] = useState<boolean>(false); // for add label popover visible or not
    const [searchLabel, setSearchLabel] = useState<string>("");

    useEffect(() => {
        if (!addLabelVisible) {
            setSearchLabel("");
        }
    }, [addLabelVisible]);

    const deleteNotes = () => {
        if (id && footerType !== NOTES_FOOTER_TYPE.bulkAction) {
            changesNoteStatus(id, NOTES_STATUS.deleted);
            if (setIsModalOpen) {
                setIsModalOpen(false);
            }
        }
        if (footerType === NOTES_FOOTER_TYPE.bulkAction) bulkStatusChange(getNoteIds(selectedNotes), NOTES_STATUS.deleted);
        if (activeTab === "archive") {
            setTimeout(() => {
                getArchiveNoteList();
            }, 100);
        }
    };
    const archiveNotes = () => {
        if (id && footerType !== NOTES_FOOTER_TYPE.bulkAction) {
            changesNoteStatus(id, activeTab === "archive" ? NOTES_STATUS.saved : NOTES_STATUS.archive);
            if (setIsModalOpen) {
                setIsModalOpen(false);
            }
        }
        if (activeTab === "archive") {
            setTimeout(() => {
                getArchiveNoteList();
            }, 100);
        }
    };

    // Handle Menu click
    const handleMenuClick = (key: string) => {
        if (key === "addLabel") {
            setAddLabelVisible(true);
        } else if (key === "showCheckbox") {
            onCheckboxToggle && onCheckboxToggle();
            setIsPopoverVisible(false);
            setIsMoreOptionOpen && setIsMoreOptionOpen(false)
        } else if (key === "deleteNotes") {
            deleteNotes();
            setIsPopoverVisible(false);
            setIsMoreOptionOpen && setIsMoreOptionOpen(false)
        } else if (key === "makeCopy") {
            if (id && footerType !== NOTES_FOOTER_TYPE.bulkAction) makeCopyById(id);
            if (footerType === NOTES_FOOTER_TYPE.bulkAction) bulkMakeCopy(getNoteIds(selectedNotes));
            setIsPopoverVisible(false);
            setIsMoreOptionOpen && setIsMoreOptionOpen(false)
        } else if (key === "archive") {
            archiveNotes();
            setIsPopoverVisible(false);
            setIsMoreOptionOpen && setIsMoreOptionOpen(false)
        }
    };

    // on search label
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchLabel(e.target.value);
    };

    // for add new label
    const handleAddLabel = (label: any) => {
        if (label?.trim() && !labelList?.find((item) => item?.title === label)) {
            createLabel(label);
            fetchAllLabel();
            setSearchLabel("");
        }
    };

    const handleLabelChange = (checked: boolean, label: ILabels) => {
        const updatedLabels = checked ? [...selectedLabels, { title: label?.title, _id: label?._id }] : selectedLabels?.filter((l) => l?._id !== label?._id);
        setSelectedLabels(updatedLabels);
        if (id && footerType !== NOTES_FOOTER_TYPE.bulkAction) {
            addLabelByNoteId(id, getUniqIdsOnly(updatedLabels));
        } else if (footerType === NOTES_FOOTER_TYPE.bulkAction) {
            bulkLabelChange(getNoteIds(selectedNotes), getUniqIdsOnly(updatedLabels));
        }
    };

    const handleVisibleChange = (newVisible: boolean) => {
        setIsPopoverVisible(newVisible);
        setIsMoreOptionOpen && setIsMoreOptionOpen(newVisible);
        if (!newVisible) setAddLabelVisible(false);
    };

    const filteredLabels = labelList?.filter((label) => label?.title?.toLowerCase()?.includes(searchLabel?.toLowerCase()));

    const menu = (
        <Menu onClick={(e) => handleMenuClick(e.key)} className={styleCss.FooterMenu}>
            <Menu.Item key="addLabel">Add Label</Menu.Item>
            {footerType !== NOTES_FOOTER_TYPE.bulkAction && <Menu.Item key="showCheckbox">{showCheckboxes ? "Hide Checkbox" : "Show Checkbox"}</Menu.Item>}
            {footerType !== NOTES_FOOTER_TYPE.createNote && <Menu.Item key="makeCopy">Make Copy</Menu.Item>}
            {footerType !== NOTES_FOOTER_TYPE.bulkAction && footerType !== NOTES_FOOTER_TYPE.createNote && (
                <Menu.Item key="archive">{activeTab === "archive" ? "Unachived Notes" : "Archive Notes"}</Menu.Item>
            )}
            {footerType !== NOTES_FOOTER_TYPE.createNote && (
                <Menu.Item key="deleteNotes" danger>
                    <span style={{ color: "#ff4d4f" }}>Delete Notes</span>
                </Menu.Item>
            )}
        </Menu>
    );

    const addLabelPopover = (
        <div className={styleCss.addLabelPopover}>
            <Typography>Label note</Typography>
            <Input.Search placeholder="Search labels" value={searchLabel} onChange={handleSearchChange} onSearch={handleAddLabel} enterButton="Add label" />
            <List>
                {filteredLabels?.map((label: ILabels) => {
                    return (
                        <List.Item key={label?._id}>
                            <Checkbox checked={!!selectedLabels?.find((item) => item?._id === label?._id)} onChange={(e) => handleLabelChange(e.target.checked, label)}>
                                {label?.title}
                            </Checkbox>
                        </List.Item>
                    );
                })}
            </List>
        </div>
    );

    return (
        <Tooltip title="More">
            <Popover
                overlayClassName={styleCss.notesFooterMore}
                content={addLabelVisible ? addLabelPopover : menu}
                trigger="click"
                visible={isPopoverVisible}
                placement="bottom"
                onVisibleChange={handleVisibleChange}
            >
                <Button type="link" icon={<MdMoreVert size={footerType === NOTES_FOOTER_TYPE.bulkAction ? 24 : 20} color="#444444" />} />
            </Popover>
        </Tooltip>
    );
};

// Undo Icon Component
export const UndoIcon: React.FC = () => {
    const handleUndoClick = () => {
        console.log("Undo icon clicked");
    };

    return <Button type="link" icon={<BiUndo size={20} color="#444444" />} onClick={handleUndoClick} />;
};

// Redo Icon Component
export const RedoIcon: React.FC = () => {
    const handleRedoClick = () => {
        console.log("Redo icon clicked");
    };

    return <Button type="link" icon={<BiRedo size={20} color="#444444" />} onClick={handleRedoClick} />;
};

//  checkbox
export const CheckBoxInCrateNote: React.FC<ICheckBoxInCrateNote> = ({ onCheckboxToggle }) => {
    const handleCheckBoxClick = () => {
        onCheckboxToggle();
    };

    return (
        <Tooltip title="New List">
            <Button type="link" icon={<PiCheckSquareBold size={20} color="#444444" />} onClick={handleCheckBoxClick} />
        </Tooltip>
    );
};

// Delete forever
export const DeleteForEver: React.FC<any> = ({ id }) => {
    const { handleDeleteNotes } = useNote();

    const handleCheckBoxClick = (e: any) => {
        e.stopPropagation();
        handleDeleteNotes(id);
    };

    return (
        <Tooltip title="Delete forever">
            <Button type="link" icon={<MdDeleteForever size={20} color="#444444" />} onClick={handleCheckBoxClick} />
        </Tooltip>
    );
};

// restore from bin
export const RestoreFromBin: React.FC<any> = ({ id }) => {
    const { changesNoteStatus } = useNote();

    const handleCheckBoxClick = (e: any) => {
        e.stopPropagation();
        changesNoteStatus(id, NOTES_STATUS.saved);
    };

    return (
        <Tooltip title="Restore notes">
            <Button type="link" icon={<MdRestoreFromTrash size={20} color="#444444" />} onClick={handleCheckBoxClick} />
        </Tooltip>
    );
};

export const ArchiveForBulk: React.FC<any> = () => {
    const { bulkStatusChange, selectedNotes, getNoteIds } = useNote();
    const handleArchiveClick = () => {
        bulkStatusChange(getNoteIds(selectedNotes), NOTES_STATUS.archive);
    };
    return (
        <Tooltip title="Archive Notes">
            <Button type="link" icon={<RiInboxArchiveLine size={24} color="#444444" />} onClick={handleArchiveClick} />
        </Tooltip>
    );
};

export const PinNotes: React.FC<any> = () => {
    const { selectedNotes, bulkPinNotes, getNoteIds } = useNote();
    const isPin = selectedNotes?.some((item: any) => item?.is_pinned === true);

    const handlePinClick = () => {
        bulkPinNotes(getNoteIds(selectedNotes), !isPin);
    };

    return isPin ? (
        <Tooltip title="Unpin note">
            <RiPushpin2Fill size={24} color="#444444" onClick={handlePinClick} />
        </Tooltip>
    ) : (
        <Tooltip title="Pin note">
            <RiPushpin2Line size={24} color="#444444" onClick={handlePinClick} />
        </Tooltip>
    );
};
