import React, { useEffect } from 'react';
import UserLayout from './components/UserLayout';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import ProtectRoute from './security/ProtectRoute';
import Inquiry from './pages/Inquiry';
import Team from './pages/Team';
import Login from './pages/Login';
import { ROLE } from './utils/const';
import { connect } from 'react-redux';
import { UserInterface, UserProfileInterface } from './reducer/loginReducer';
import { LocalStorageService } from './utils/LocalStorage';
import { isLogin, setProfileData, setUserData } from './action';
import AxiosService from './utils/APIService';
import webSocketService from './utils/websocketService';
import AdminLayout from './components/AdminLayout';
import UtilsPage from './pages/UtilsPage';
import AdminTask from './pages/AdminTask';
import ContactList from './pages/ContactList';
import Notes from './pages/Notes';
interface AppPropsInterface{
	isLogin:(val:Boolean) => void; 
	setProfileData: (val:UserProfileInterface | null) => void; 
	setUserData: (val:UserInterface | null) => void;
}
const App = ({isLogin, setProfileData, setUserData }:AppPropsInterface) => {
	const apiService = new AxiosService()
	const navigate = useNavigate()

	const routerPath = () => {
		const userData:UserInterface = LocalStorageService.getItem("user");
		const token = LocalStorageService.getItem("token")
		if(userData && token){
			// if (userData?.role === ROLE.agentAdmin) {
			// 	return '/team'
			// }
			// if (userData?.role === ROLE.agentUser) {
			// }
			return '/inquiry'
		}
		return '/login'
	}

	useEffect(() => {
		const token = LocalStorageService.getItem("token")
		if(!webSocketService.isConnected()){
			webSocketService.connect(process.env.REACT_APP_SOCKET_URL as string)
		}
		if(token){
			getLoggedinUserData()
		}else{
			isLogin(false)
			setProfileData(null)
			setUserData(null)
		}
		return () => webSocketService.disconnect()
	}, [])

	const getLoggedinUserData = () => {
		apiService.get('/auth/me')
		.then((res:any) => {
			isLogin(true)
			setProfileData(res.profile)
			setUserData(res.user)
		})
		.catch(() => {
			LocalStorageService.removeItem('token')
			LocalStorageService.removeItem('user')
			isLogin(false)
			setProfileData(null) 
			setUserData(null)
			navigate('/login')
		})
	}
	
	return (<>
		<Routes>

			<Route path='/login' Component={Login} />

			<Route path='/inquiry' element={<ProtectRoute component={Inquiry} layout={UserLayout} />} />
			<Route path='/notes' element={<ProtectRoute component={Notes} layout={UserLayout}/>} />
			<Route path='/contacts' element={<ProtectRoute component={ContactList} layout={UserLayout}/>} />
			
			{/* <Route path='/team' element={<ProtectRoute component={Team} layout={AdminLayout} role={ROLE.agentAdmin} />} />
			<Route path='/utils' element={<ProtectRoute component={UtilsPage} layout={AdminLayout} role={ROLE.agentAdmin} />} />
			<Route path='/tasks' element={<ProtectRoute component={AdminTask} layout={AdminLayout} role={ROLE.agentAdmin} />} /> */}

			<Route path='/' element={<Navigate to={routerPath()} />} />
		</Routes>
	</>)
}

const mapStateToProps = () => {
	return {}
}
export default connect(mapStateToProps, {
	setUserData,
	setProfileData,
	isLogin
}) (App);
