import { EllipsisOutlined, MoreOutlined } from '@ant-design/icons';
import { Badge, Button, Flex, Space, Tabs, Tag, Tooltip, Typography } from 'antd'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styleCss from './style.module.scss'
import { connect } from 'react-redux';
import { RootState } from '../../../reducer';
import { UserInterface } from '../../../reducer/loginReducer';
import { LocalStorageService } from '../../../utils/LocalStorage';
import NavTab from '../../../components/NavTab';

interface TaskNavPropsInterface {
    user: UserInterface | null | undefined;
    setFilter: Dispatch<SetStateAction<any>>;
    filterData: any;
}

const markAll = (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16">
        <path d="M14.323 3.75a2 2 0 011.686 1.975v5.297a2 2 0 01-2 2H8.644l-2.582 2.581v-2.581H5.33l2.11-2.095h4.882a2 2 0 001.995-1.85l.005-.15.001-5.178zM10.896 0a2 2 0 012 2v5.457a2 2 0 01-2 2l-3.997-.001-4.121 4.122-.001-4.122H2a2 2 0 01-2-2V2a2 2 0 012-2h8.896zM9.112 1.915L5.278 5.667 3.855 4.355l-.988.963 2.41 2.274L10.1 2.877l-.988-.962z" fill="#FFF" fill-rule="evenodd" />
    </svg>
)
function TaskNav({ user, setFilter, filterData }: TaskNavPropsInterface) {
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    useEffect(() => {
        setSelectedTags(LocalStorageService.getItem('task_filter') || [])
    }, []);
    const viewMenu = [
        {
            key: '1',
            label: 'List',
            children: '',
        },
        {
            key: '2',
            label: 'Duedate',
            children: '',
        },
        {
            key: '3',
            label: 'Planner',
            children: '',
        },
        {
            key: '4',
            label: 'Calendar',
            children: '',
        },
        {
            key: '5',
            label: 'Gantt',
            children: '',
        },
    ]
    const handleChange = (tag: string, checked: boolean) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        LocalStorageService.setItem('task_filter', nextSelectedTags)
        setSelectedTags(nextSelectedTags);
    };

    useEffect(() => {
        setFilter({ ...filterData, is_personal_task: selectedTags.includes("personal_task") })
    }, [selectedTags]);

    return (
        <Flex gap={15} align='center' justify='space-between'>
            <div className={styleCss.commonNav}>
                <NavTab items={viewMenu} />
            </div>
            <Flex justify='space-between' align='center' style={{ width: "100%" }}>
                <Space styles={{
                    item: {
                        height: "100%"
                    }
                }} className={styleCss.customFilterTag} align='center'>
                    <Typography.Text className='label'>My items:</Typography.Text>
                    <Tag.CheckableTag
                        checked={selectedTags.includes("overdue")}
                        onChange={(checked) => handleChange("overdue", checked)}
                    >
                        <Badge count={10} offset={[-3, 0]} styles={{
                            indicator: {
                                boxShadow: "none"
                            }
                        }} />
                        Overdue
                    </Tag.CheckableTag>
                    <Tag.CheckableTag
                        checked={selectedTags.includes("comments")}
                        onChange={(checked) => handleChange("comments", checked)}
                    >
                        Comments <Badge count={10} offset={[3, 0]} color='#FFFFFF33' styles={{
                            indicator: {
                                boxShadow: "none"
                            }
                        }} />
                    </Tag.CheckableTag>
                    {user?.role == "AGENT_ADMIN" && <Tag.CheckableTag
                        checked={selectedTags.includes("personal_task")}
                        onChange={(checked) => handleChange("personal_task", checked)}
                    >
                        {selectedTags.includes("personal_task") ? "Show All" : "Personal Task"}
                    </Tag.CheckableTag>}
                    <Typography.Text className='label devider-left hideText'>Mark all as read</Typography.Text>
                    <Tooltip title="Mark all as read">
                        <div className='showIcon'>
                            {markAll}
                        </div>
                    </Tooltip>
                </Space>
                <Button style={{ padding: "3px 9px", borderRadius: "20px", height: "auto", width: "auto" }} type='default' icon={<EllipsisOutlined style={{ color: '#fff' }} />}></Button>
            </Flex>
        </Flex>
    )
}
const mapStateToProps = ({ userData }: RootState) => {
    const { user } = userData
    return {
        user
    }
}
export default connect(mapStateToProps, {})(TaskNav)