import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../reducer";
import { Button, Col, Divider, Flex, Form, List, Row, Space, Typography } from "antd";
import FormCss from "./form.module.scss";
import FileUpload from "../../../components/FileUpload";
import uploadSvg from "../../../assets/upload-svg.svg";
import publishSvg from "../../../assets/publish.svg";
import { InputBox, InputRadio } from "../../../components/FormInput";
import { CloseOutlined, DeleteOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
const { Title } = Typography;
interface PassportDocPropsInterface {
    form: any;
    formId: any;
}
export const PassportDoc = ({ form, formId }: PassportDocPropsInterface) => {
    const [frontFileList, setFrontFileList] = useState<any[]>([]);
    const [fullPassport, setFullPassport] = useState([]);
    const [addPassport, setAddPassport] = useState(false);
    const [historyData, setHistoryData] = useState([
        {
            id: "1",
            name: "Current Passport",
            expDate: "25/09/2024"
        },
        {
            id: "2",
            name: "2nd Last Passport",
            expDate: "08/07/2024"
        },
        {
            id: "3",
            name: "3rd Last Passport",
            expDate: "01/05/2024"
        },
        {
            id: "4",
            name: "4th Last Passport",
            expDate: "22/04/2024"
        }
    ]);
    const filePlaceholder = (
        <div className="flex flex-col items-center">
            <img className="mb-7" src={uploadSvg} style={{ width: "200px" }} />
            <div>
                <p style={{ color: "#1A73E8", fontSize: "14px" }}>Drag an Image here or click to upload</p>
                <span style={{ color: "#99A1BB", fontSize: "14px" }}>Upload an image in either a PNG or JPEG format. Maximum size: 2MB.</span>
            </div>
        </div>
    );
    const filePlaceholderMd = (
        <div className="flex flex-col items-center">
            <img className="mb-7" src={publishSvg} style={{ width: "40px" }} />
            <div>
                <p style={{ color: "#1A73E8", fontSize: "14px" }}>Drag an Image here or click to upload</p>
                <span style={{ color: "#99A1BB", fontSize: "14px" }}>Upload an image in either a PNG or JPEG format. Maximum size: 2MB.</span>
            </div>
        </div>
    );
    const [formFields, setFormFields] = useState<any[]>([]);
    useEffect(() => {
        setFormFields([
            {
                name: [formId, "passport_number"],
                label: "Passport Number",
                type: "text",
                placeholder: "Enter passport number"
            },
            {
                name: [formId, "dob"],
                label: "Date of Birth",
                type: "date"
            },
            {
                name: [formId, "gender"],
                label: "Gender",
                type: "radio",
                options: [{label: "Male", value: "M"}, {label: "Female", value: "F"}]
            },
            {
                name: [formId, "passport_last_name"],
                label: "Last Name",
                type: "text",
                placeholder: "Enter last name"
            },
            {
                name: [formId, "passport_first_name"],
                label: "First Name",
                type: "text",
                placeholder: "Enter first name"
            },
            {
                name: [formId, "passport_middle_name"],
                label: "Middle Name",
                type: "text",
                placeholder: "Enter middle name"
            },
            {
                name: [formId, "passport_issue_date"],
                label: "Passport Issue Date",
                type: "date"
            },
            {
                name: [formId, "passport_expiry_date"],
                label: "Passport Expiry Date",
                type: "date"
            },
        ])
    }, [formId]);

    const toggleDoc = () => {
        setAddPassport(!addPassport)
    }

    
    return (
        <>
            <div className="pr-5 pl-5 pb-5">
                <Title level={4} className={`${FormCss.titleLevel4}`}>
                    Passport Front Details
                </Title>
                <Row gutter={[15, 15]}>
                    <Col span={24}>
                        <Form.Item className="passport-dropbox mb-1" name={[formId, "passportFront"]}>
                            <FileUpload
                                onRemove={() => {
                                    form.resetFields([formId, "passportFront"]);
                                }}
                                accept=".png, .jpeg, .jpg"
                                required
                                placeholder={filePlaceholder}
                                setFile={(val: any) => {
                                    setFrontFileList(val);
                                }}
                                fileData={frontFileList}
                            />
                        </Form.Item>
                    </Col>
                    {formFields.map((fld:any, index:number) => ( <Col key={index} span={8}>{fld.type === "radio" ? <InputRadio className="mb-0" {...fld}  /> : <InputBox className="mb-0" {...fld} />}</Col>))}
                </Row>
            </div>
            <Divider style={{
                borderBlockStart: '1px solid rgba(219, 223, 233, 1)',
                margin: 0
            }} />
            <div className="p-5">
                <Title level={4} className={`${FormCss.titleLevel4}`}>
                    Passport Back Details
                </Title>
                <Row gutter={[15, 15]}>
                    <Col span={24}>
                        <Form.Item className="mb-1 passport-dropbox" name={[formId, "passportBack"]}>
                            <FileUpload
                                onRemove={() => {
                                    form.resetFields([formId, "passportBack"]);
                                }}
                                accept=".png, .jpeg, .jpg"
                                required
                                placeholder={filePlaceholder}
                                setFile={(val: any) => {
                                    setFrontFileList(val);
                                }}
                                fileData={frontFileList}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}><InputBox label="Father Full Name" className="mb-0" placeholder="Enter father full name" name={[formId, "passport_father_name"]} /></Col>
                    <Col span={24}><InputBox label="Mother Full Name" className="mb-0" placeholder="Enter mother full name" name={[formId, "passport_mother_name"]} /></Col>
                    <Col span={24}><InputBox label="Spouse Name" className="mb-0" placeholder="Enter spouse" name={[formId, "passport_spouse_name"]} /></Col>
                </Row>
            </div>
            <Divider style={{
                borderBlockStart: '1px solid rgba(219, 223, 233, 1)',
                margin: 0
            }} />
            <div className="p-5">
                <Title level={4} className={`${FormCss.titleLevel4}`}>
                    Full Passport 
                </Title>
                <Button onClick={toggleDoc} type="primary" className={`${FormCss.addButton} mb-1`} block icon={<PlusOutlined />}>Add Passport</Button>
                {addPassport && <div className={`${FormCss.uploadCard} mb-2`}>
                    <Flex justify="space-between" align="center" className="card-header">
                        <Title className='card-title' level={2}>Upload</Title>
                        <Button onClick={toggleDoc} type="link" style={{color: "inherit"}}><CloseOutlined style={{fontSize: "15px"}} /></Button>
                    </Flex>
                    <div className="card-body">
                        <div className="space-wrapper">
                            <div className="passport-dropbox-md">
                                <Row gutter={[0, 15]}>
                                    <Col span={24}>
                                        <FileUpload
                                                accept=".png, .jpeg, .jpg"
                                                required
                                                placeholder={filePlaceholderMd}
                                                setFile={(val: any) => {
                                                    setFullPassport(val);
                                                }}
                                                fileData={fullPassport}
                                            />
                                    </Col>
                                    <Col span={24}>
                                        <InputBox type="date" label="Date" className="mb-0" />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <Flex justify="flex-end">
                            <Button className={FormCss.formButton} type="primary">Upload</Button>
                        </Flex>   
                    </div>
                </div>}
                <div className={`${FormCss.uploadCard}`}>
                    <Flex justify="space-between" align="center" className="card-header">
                        <Title level={3} style={{marginBottom: 0}} className={FormCss.titleLevel3}>Uploaded Documents </Title>
                    </Flex>
                    <div className="card-body">
                        <List
                            size="small"
                            className={FormCss.docList}
                            header={<div className="doc-list-header">
                                <div>Name</div>
                                <div>Expiry Date</div>
                                <div>Action</div>
                            </div>}
                            dataSource={historyData}
                            renderItem={(item) => (
                                <List.Item className="doc-list-item" key={item.id}>
                                    <div>{item.name}</div>
                                    <div>{item.expDate}</div>
                                    <div>
                                        <Flex gap={16}>
                                            <Button style={{color: "rgba(153, 161, 187, 1)"}} type="link" icon={<EyeOutlined />} />
                                            <Button style={{color: "rgba(153, 161, 187, 1)"}} type="link" icon={<DeleteOutlined />} />
                                        </Flex>
                                    </div>
                                </List.Item>)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PassportDoc);
