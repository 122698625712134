export const UTILS = {
    token: "token",
    user: "user",
};

export const ROLE = {
    admin: "ADMIN",
    agentUser: "AGENT_USER",
    agentAdmin: "AGENT_ADMIN",
};

export const TASK_STATUS = {
    completedbyUser: "COMPLETED_BY_USER",
    inprogress: "INPROGRESS",
    pending: "PENDING",
    complete: "COMPLETED",
    cancel: "CANCEL",
};
export const PAYMENT_METHODE = {
    full: "FULL",
    part: "PART",
    timetocollect: "TIME_TO_COLLECT",
};

export const INQUIRY_TYPE: any = {
    AIR_TICKET_DOM: { title: "Air Ticket (Domestinc)", className: "type-air-ticket-domestinc" },
    AIR_TICKET_INT: { title: "Air Ticket (International)", className: "type-air-ticket-international" },
    TOUR_PACKAGE_DOM: { title: "Tour Package (Domestinc)", className: "type-tour-package-domestinc" },
    TOUR_PACKAGE_INT: { title: "Tour Package (International)", className: "type-tour-package-international" },
    VISA: { title: "Visa Application", className: "type-visa-application" },
    INSURENCE: { title: "Insurance", className: "type-insurance" },
    ACCOUNT: { title: "Account", className: "type-account" },
    CO_ORDINATOR: { title: "Co-ordinator", className: "type-coordinator" },
    ADMIN: { title: "Admin", className: "type-admin" },
    SALES_MARKETING: { title: "Sales & Marketing", className: "type-sales-marketing" },
    SELF: { title: "Self", className: "type-self" },
};

// export const INQUIRY_TYPE: any = {
//     AIR_TICKET_DOM: "Air Ticket (Domestinc)",
//     AIR_TICKET_INT: "Air Ticket (International)",
//     TOUR_PACKAGE_DOM: "Tour Package (Domestinc)",
//     TOUR_PACKAGE_INT: "Tour Package (International)",
//     VISA: "Visa Application",
//     INSURENCE: "Insurance",
//     ACCOUNT: "Account",
//     CO_ORDINATOR: "Co-ordinator",
//     ADMIN: "Admin",
//     SALES_MARKETING: "Sales & Marketing",
//     SELF: "Self"
// }

export const DRAWER_TYPE: any = {
    CREATE_TASK: "CREATE_TASK",
    TASK_DETAIL: "TASK_DETAIL",
    CONTACT_DRAWER: "CONTACT_DRAWER",
};

export const INQUIRY_TYPE_STATUS: any = {
    NEW: { title: "New", className: "status-new" },
    PROPOSAL_SENT: { title: "Proposal Sent", className: "status-proposal-sent" },
    FOLLOW_UP: { title: "Follow up", className: "status-follow-up" },
    WAITING_FOR_RESPONSE: { title: "Waiting For Response", className: "status-waiting-for-response" },
    CONFIRMED: { title: "Confirmed", className: "status-confirmed" },
    BOOKING_IN_PROGRESS: { title: "Booking in-process", className: "status-booking-in-progress" },
    PAYMENT: { title: "Payment", className: "status-payment" },
    DOCUMENT_MISSING: { title: "Document Missing", className: "status-document-missing" },
    IN_PROCESS: { title: "In-process", className: "status-in-process" },
};

export const NOTES_BACKGROUND_COLOR = ["#ffffff", "#faafa8", "#f39f76", "#fff8b8", "#e2f6d3", "#b4ddd3", "#d4e4ed", "#d3bfdb", "#f6e2dd", "#e9e3d4"];

export const NOTES_STATUS = {
    saved: "SAVED",
    archive: "ARCHIVE",
    deleted: "DELETED",
};

export const NOTES_FOOTER_TYPE = {
    createNote: "CREATE",
    cardBottom: "CARD_BOTTOM",
    editNoteModal: "EDIT_NOTE_MODAL",
    bulkAction: "BULK_ACTION",
    binAction: "BIN_ACTION",
};
