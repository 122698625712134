// Bin Note Lists
import { useNote } from ".";
import { Button, Typography } from "antd";
import NoteCard from "./NoteCard";
import { RiDeleteBin6Line } from "react-icons/ri";
import Masonry from "react-masonry-css";
import styleCss from "./style.module.scss";

const BinNoteList = () => {
    const { binNoteList, emptyBin, getNoteIds } = useNote();

    const breakpointColumnsObj = {
        default: 5,
        1400: 4,
        1300: 3,
        1100: 2,
        764: 1,
    };

    const handleEmptyBtnClick = () => {
        emptyBin(getNoteIds(binNoteList));
    };

    return (
        <div className={styleCss.noteGirdViewContainer}>
            {!!binNoteList.length && (
                <>
                    <div className="bin-notification">
                        <Typography>Notes in Trash are deleted after 7 days.</Typography>
                        <Button onClick={handleEmptyBtnClick}>Empty Bin</Button>
                    </div>
                    <div className={styleCss.filterTitle}>
                        <Masonry breakpointCols={breakpointColumnsObj} className={styleCss.myMasonryGrid} columnClassName={styleCss.myMasonryGridColumn}>
                            {binNoteList?.map((note: any) => (
                                <NoteCard {...note} key={note?.id} />
                            ))}
                        </Masonry>
                    </div>
                </>
            )}
            {!binNoteList.length && (
                <div className="no-data-notification">
                    <RiDeleteBin6Line size={90} className="note-no-data-icon" />
                    <Typography className="no-data-text">No notes in Bin</Typography>
                </div>
            )}
        </div>
    );
};

export default BinNoteList;
