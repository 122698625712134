import { Badge, Form, TabsProps } from "antd";
import { createContext, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import webSocketService from "../../utils/websocketService";
import AxiosService from "../../utils/APIService";
import TabLayout from "./TaskHeader/TaskTab";
import TaskNav from "./TaskHeader/TaskNav";
import CreateTask from "./TaskInfo/CreateTask";
import Taskcss from "./style.module.scss";
import Task from "./Task";
import WishList from "./WatchList";
import CompetedList from "./CompetedList";
import DeletedList from "./DeletedList";
import useTeamMember from "../../hooks/useTeamMember";
import DeleteRequest from "./DeleteRequest";
import { RootState } from "../../reducer";
import { UserInterface } from "../../reducer/loginReducer";
import { TASK_STATUS } from "../../utils/const";
import SearchHeader from "../../components/SearchHeader";
const InquryContext: any = createContext({});
const _ = require("lodash");
interface InquiryComponentInterface {
  user: UserInterface | null | undefined;
}

export const useInquiry = () => {
  return useContext(InquryContext);
};
function Inquiry({ user }: InquiryComponentInterface) {
  const apiService = new AxiosService();
  const [taskList, setTaskList] = useState<any>();
  const [taskTab, setTaskTab] = useState(() => localStorage.getItem("taskTab") || "2");
  const [pendingTasks, setPendingTasks] = useState<any[]>([]);
  const [WatchList, setWatchList] = useState<any[]>([]);
  const [CompletedList, setCompletedList] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [filter, setFilter] = useState<any>({ is_personal_task: false });
  const { assignList, fetchAssignList } = useTeamMember();
  const [deleteRequestList, setDeleteRequestList] = useState<any[]>([]);

  useEffect(() => {
    fetchAssignList();
    fetchPendingTask();
    fetchWatchlist();
    fetchTaskList();
    fetchCompletedList();
    fetchDeletelist();
  }, []);

  useEffect(() => {
    webSocketService.onMessage("inquiryPositionUpdate", (val) => {
      if (val.tenantId === user?.tenant_id) {
        setTimeout(() => {
          fetchTaskList(filter);
        }, 200);
        fetchPendingTask();
        fetchWatchlist();
        fetchDeletelist();
        fetchCompletedList()
      }
    });
  }, [user]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchDeletelist();
    }, 100);
    return () => clearTimeout(timeOut);
  }, [filter]);

  useEffect(() => {
    if (filter && user) {
      fetchTaskList();
    }
  }, [filter, user]);

  const parseDate = (dateStr: string) => {
    const date: any = dayjs(dateStr, "DD-MM-YYYY HH:mm");
    return new Date(date);
  };

  const fetchTaskList = (params?: any) => {
    apiService
      .get("/inquiry/list")
      .then((res: any) => {
        const data = res.data;
        const listObj = {
          Today: data
            .filter((dt: any) => dt?.task?.status === TASK_STATUS.inprogress)
            .sort((a: any, b: any) => parseDate(a.task.due_date).getTime() - parseDate(b.task.due_date).getTime()),
          "Up-coming": data.filter((dt: any) => dt?.task?.status === TASK_STATUS.pending).sort((a: any, b: any) => a.task.listOrde - b.task.listOrde),
        };

        if (filter?.is_personal_task && user) {
          listObj.Today = listObj.Today.filter((dt: any) => dt.task.assign_to.some((usr: any) => usr._id === user?._id));
          listObj["Up-coming"] = listObj["Up-coming"].filter((dt: any) => dt.task.assign_to.some((usr: any) => usr._id === user?._id));
        }
        setTaskList(listObj);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchPendingTask = () => {
    apiService
      .get("/inquiry/pending-list")
      .then((res: any) => {
        setPendingTasks(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchWatchlist = () => {
    apiService
      .get("/inquiry/list", {
        status: TASK_STATUS.completedbyUser,
        ...filter,
      })
      .then((res: any) => {
        setWatchList(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchCompletedList = () => {
    apiService
      .get("/inquiry/list", {
        status: TASK_STATUS.complete,
        ...filter,
      })
      .then((res: any) => {
        setCompletedList(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchDeletelist = () => {
    apiService
      .get("/inquiry/list", {
        status: "REQUEST_FOR_DELETE",
        ...filter,
      })
      .then((res: any) => {
        setDeleteRequestList(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const TabsList: TabsProps["items"] = [
    {
      key: "2",
      label: (
        <>
          Task
          {taskList && (taskList.Today?.length || 0) + (taskList["Up-coming"]?.length || 0) > 0 && (
            <Badge count={(taskList.Today?.length || 0) + (taskList["Up-coming"]?.length || 0)} size="small" overflowCount={99} offset={[3, -13]} style={{ fontSize: "10px" }} />
          )}
        </>
      ),
      children: "",
    },
    {
      key: "3",
      label: (
        <>
          Watchlist
          {WatchList?.length > 0 && <Badge count={WatchList?.length} size="small" overflowCount={99} offset={[3, -13]} style={{ fontSize: "10px" }} />}
        </>
      ),
      children: "",
    },
    {
      key: "4",
      label: (
        <div className={Taskcss.badge}>
          Completed
          {CompletedList?.length > 0 && <Badge count={CompletedList?.length} size="small" overflowCount={99} offset={[3, -13]} style={{ fontSize: "10px" }} />}
        </div>
      ),
      children: "",
    },
    ...(deleteRequestList?.length === 0
      ? []
      : [
          {
            key: "5",
            label: (
              <>
                Delete Request
                {deleteRequestList?.length > 0 && <Badge count={deleteRequestList?.length} size="small" overflowCount={99} offset={[3, -13]} style={{ fontSize: "10px" }} />}
              </>
            ),
            children: "",
          },
        ]),
    { key: "6", label: "Deleted", children: "" },
  ];

  const handleTabChange = (key: string) => {
    setTaskTab(key);
    localStorage.setItem("taskTab", key);
  };

  useEffect(() => {
    form.setFieldValue("authority", true);
    form.setFieldValue("dueDate", dayjs());
  });

  return (
    <>
      <InquryContext.Provider
        value={{
          assignList,
        }}
      >
        <TabLayout currentValue={taskTab} items={TabsList} onChange={handleTabChange} />

        <SearchHeader title={"My Tasks"}>
            <CreateTask />
        </SearchHeader>

        <TaskNav setFilter={setFilter} filterData={filter} />
        {taskTab === "2" && <Task pendingTasks={pendingTasks} taskList={taskList} setTaskList={setTaskList} />}
        {taskTab === "3" && <WishList filter={filter} WatchList={WatchList} />}
        {taskTab === "4" && <CompetedList filter={filter} CompletedList={CompletedList}/>}
        {taskTab === "5" && <DeleteRequest deleteRequestList={deleteRequestList} />}
        {taskTab === "6" && <DeletedList filter={filter} />}
      </InquryContext.Provider>
    </>
  );
}
const mapStateToProps = ({ userData }: RootState) => {
  const { user } = userData;

  return { user };
};
export default connect(mapStateToProps, {})(Inquiry);
