import {
    Alert,
    message,
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Drawer,
    Dropdown,
    Flex,
    Form,
    GetProp,
    Input,
    MenuProps,
    Row,
    Select,
    Space,
    Typography,
    Upload,
    UploadFile,
    UploadProps, Image,
    Tag
} from 'antd'
import React, { useEffect, useState } from 'react'
import TaskStyleCss from "./style.module.scss"
import { connect } from 'react-redux'
import { setTaskDrawerOpen } from '../../../action'
import { RootState } from '../../../reducer'
import { CloseOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons'
import fireIcon from "../../../assets/fire.svg"
import fireActiveIcon from "../../../assets/fireactive.svg"
import clipSvg from "../../../assets/clip.svg"
import clipBlack from "../../../assets/clipBlack.svg"
import CheckList from './CheckList'
import { InputBox, InputSelect } from '../../../components/FormInput'
import { UserInterface } from '../../../reducer/loginReducer'
import dayjs from 'dayjs'
import { DRAWER_TYPE, INQUIRY_TYPE } from '../../../utils/const'
import AxiosService from '../../../utils/APIService'
import Icon from "../Icon";
import { useInquiry } from '..'
import { capitalizeFirstLetter, capitalizeFirstLetterOfWord } from '../../../utils/commonFunctions';
import Dragger from 'antd/es/upload/Dragger'
import {UserInvite} from '../../../components/UserInviteModel'
import { TweenOneGroup } from 'rc-tween-one'
import commonCSS from '../../../css/commonStyle.module.scss'
import { FaCaretDown } from "react-icons/fa6";

const { Text } = Typography

interface CreateTaskPropsInterface {
    setTaskDrawerOpen: (val: any) => void;
    taskDrawerOpen: any;
    user: UserInterface | null | undefined;
}
export interface subTaskData {
    id?: string;
    is_completed: boolean;
    title: string;
    due_date?: string;
}
export interface subTaskDataListInterface {
    name: string;
    task: subTaskData[];
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

function CreateTask({ setTaskDrawerOpen, taskDrawerOpen, user }: CreateTaskPropsInterface) {
    const apiService = new AxiosService()
    const { assignList }: any = useInquiry()

    const [loader, setLoader] = useState(false)
    const [value, setValue] = useState('one-way');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [showUpload, setShowUpload] = useState('');
    const [isTitleEmpty, setIsTitleEmpty] = useState(false);
    const [isPrior, setIsPrior] = useState(false)
    const [taskForm] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage();
    const [isTaskTypeOpen, setIsTaskTypeOpen] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [subTaskData, setSubTaskData] = useState<subTaskDataListInterface[]>([
        {
            name: "SubTask List",
            task: []
        }
    ])

    const [selectedAssignee, setSelectedAssignee] = useState([]);

    useEffect(() => {
        taskForm.setFieldValue("dueDate", dayjs());
        if (taskDrawerOpen === DRAWER_TYPE.CREATE_TASK) {
            setIsTaskTypeOpen(true);
        }
    }, [taskForm, user?._id, taskDrawerOpen]);

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: " 2nd menu item"
        },
        {
            key: '2',
            label: " 2nd menu item"
        },
        {
            key: '3',
            label: " 2nd menu item"
        },
    ];

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const uploadButton = (
        <>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                Click or drag file to this area to upload
            </p>
        </>
    );

    const drawerCloseHandle = () => {
        taskForm.resetFields()
        setTaskDrawerOpen(null)
        setIsTitleEmpty(false);
        setSubTaskData([])
        setIsTaskTypeOpen(false);
        setFileList([])
    }

    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    const handleClick = (type: string) => {
        setShowUpload(prevState => (prevState === type ? '' : type));
    };

    const createInquiry = (val: any) => {
        setLoader(true)
        const formdata = new FormData();
        formdata.append("type", JSON.stringify(val.type))
        formdata.append("title", val.title)
        formdata.append("dueDate", val.dueDate.format("DD-MM-YYYY HH:mm"))
        if(val.notes) formdata.append("notes", val.notes)
        formdata.append("is_prior", val.is_prior)
        if(selectedAssignee?.length > 0) formdata.append("assignTo", JSON.stringify(selectedAssignee.map((asn:any) => asn._id)))
        if(subTaskData?.length > 0) formdata.append("subtask", JSON.stringify(subTaskData))
        if (fileList?.length > 0) {
            for (let i = 0; i < fileList.length; i++) {
                const file:any = fileList[i];
                    formdata.append(`doc_${i + 1}`, file.originFileObj)
            }
        }

        let hasError = false;

        if (!val.type) {
            messageApi.error('Task type is required');
            hasError = true;
        }

        if (!val.title) {
            setIsTitleEmpty(true);
            hasError = true;
        }

        if (!hasError) {
            apiService.post("/inquiry/create", formdata)
                .then(() => {
                    drawerCloseHandle();
                    setIsTitleEmpty(false);
                    setIsPrior(false);
                })
                .catch((e: any) => {
                    console.log("error", e);
                });
        }
        setLoader(false)
    }

    const createAnotherOne = () => {
        taskForm.submit();
        taskForm.resetFields();
        setFileList([]);
        setSubTaskData([{ name: "SubTask List", task: [] }]);
        setIsTitleEmpty(false);
        setIsPrior(false);
        setShowUpload('');
        setSelectedAssignee([]);
    };
    
    const formSubmitClick = () => {
        taskForm.submit()
    }

    const setTomorrowDate = () => {
        const tomorrow = dayjs().add(1, 'day');
        taskForm.setFieldValue('dueDate', tomorrow);
    };

    const setTodayDate = () => {
        const today = dayjs();
        taskForm.setFieldValue('dueDate', today);
    };

    const handleSubtaskChange = (val: any, index: number) => {
        const taskList = [...subTaskData]
        taskList[index] = val
        setSubTaskData(taskList)
    }
    const addSubTaskList = () => {
        setSubTaskData([...subTaskData, {
            name: "SubTask List",
            task: []
        }])
    }

    const removeSubTaskList = (index: number) => {
        const updatedSubTaskData = subTaskData.filter((_, index) => index !== index);
        setSubTaskData(updatedSubTaskData);
    }

    const getAssigneeList = (val:any) => {
        const dataList = assignList.filter((asl:any) => val.includes(asl._id)).map((asl:any) => ({name: asl.full_name, _id: asl._id}))
        setSelectedAssignee(dataList)
    }
    
    const handleCloseTag = (id:any) => {
        setSelectedAssignee((prev) => prev.filter((dt:any) => dt._id !== id))
    }
    
    return (
        <>
            <Dropdown.Button
                menu={{ items }}
                placement="bottomLeft"
                arrow={{ pointAtCenter: true }}
                icon={<FaCaretDown/>}
                onClick={() => setTaskDrawerOpen(DRAWER_TYPE.CREATE_TASK)}
                className={commonCSS.inquireyBtn}
            >
                CREATE
            </Dropdown.Button>
            <Drawer
                onClose={drawerCloseHandle}
                open={taskDrawerOpen === DRAWER_TYPE.CREATE_TASK}
                width={'calc(100% - 233px)'}
                className={TaskStyleCss.taskDrawer}
                styles={{
                    header: {
                        display: "none"
                    }
                }}
                footer={[
                    <div className='CreateTaskFooter'>
                        <Flex gap={20}>
                            <Button className='addTaskbtn' onClick={formSubmitClick}>ADD TASK</Button>
                            <Button className='createAnotherTaskbtn' onClick={createAnotherOne}>ADD TASK AND CREATE
                                ANOTHER ONE</Button>
                            <Button className='cancalbtn' onClick={drawerCloseHandle}>Cancel</Button>
                        </Flex>
                    </div>
                ]}
                style={{ boxShadow: '0px 0px 5px white' }}

            >
                <Flex onClick={drawerCloseHandle} className='close-label' align='center'>
                    <CloseOutlined style={{fontSize:'12px'}}/> 
                    <span>TASK</span>
                </Flex>

                <Form
                    name='task'
                    onFinish={createInquiry}
                    layout='vertical'
                    form={taskForm}
                    initialValues={{
                        dueDate: dayjs(),
                    }}
                >
                    <Row className={`drawer-header ${TaskStyleCss.drawerSelect}`} justify={'space-between'}>
                        <Col>
                            <Space>
                                <Text className='drawer-title'>New Task</Text>
                                <Form.Item
                                    name="type"
                                    className='mb-0'
                                    required={true}
                                >
                                    <Select
                                        placeholder="TASK TYPE"
                                        style={{ width: 270 }}
                                        options={Object.keys(INQUIRY_TYPE).map((ky) => ({
                                            label: (
                                                <Flex align='center'>
                                                    <Icon name={ky} />
                                                    <span className="pl-2">{INQUIRY_TYPE[ky].title}</span>
                                                </Flex>
                                            ),
                                            value: ky
                                        }))}
                                        className='template-dropdown'
                                        mode='multiple'
                                        rootClassName='taskType'
                                        open={isTaskTypeOpen}
                                        onDropdownVisibleChange={(open) => setIsTaskTypeOpen(open)}
                                    />
                                </Form.Item>
                            </Space>
                        </Col>
                        <Col>
                            <Select
                                className='template-dropdown'
                                placeholder="TASK TEMPLATES"
                                style={{ width: 153 }}
                                options={[
                                    { value: 'jack', label: 'Jack' },
                                    { value: 'lucy', label: 'Lucy' },
                                    { value: 'Yiminghe', label: 'yiminghe' }
                                ]}
                            />
                        </Col>
                    </Row>
                    {isTitleEmpty && (
                        <Alert type='error' message="The task name is not specified." banner
                            style={{ marginBottom: '5px' }} />
                    )}
                    {contextHolder}
                    <Card className='createTaskWrapper'>
                        <Row className='title-wrapper' justify={'space-between'}>
                            <Col span={18}>
                                <Form.Item
                                    name="title"
                                    className='mb-0'
                                >
                                    <Input placeholder="Enter task name" variant="borderless" tabIndex={1}
                                        onChange={() => setIsTitleEmpty(false)} onBlur={(e) => taskForm.setFieldValue('title', capitalizeFirstLetterOfWord(e.target.value))} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item className='mb-0' name={'is_prior'} valuePropName="checked">
                                    <Checkbox onChange={(e) => setIsPrior(e.target.checked)} tabIndex={3}>
                                        <Flex gap={8} align='center'>High Priority <img
                                            src={isPrior ? fireActiveIcon : fireIcon} alt='fire'/></Flex>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <FlightTicket /> */}
                        <Form.Item className={TaskStyleCss.notes}>
                            <InputBox type='textarea' name={'notes'} placeholder='Add Notes here'
                                style={{ marginBottom: '10px' }} autoSize={{ minRows: '7', maxRows: '22' }} tabIndex={2} onBlur={(e: any) => taskForm.setFieldValue('notes', capitalizeFirstLetter(e.target.value))} />
                        </Form.Item>
                        <Space size={30} align='center' style={{ padding: '11px' }}>
                            <Flex align='center' onClick={() => handleClick("FILE")}><img
                                src={showUpload === "FILE" ? clipBlack : clipSvg} alt='clip'/><Typography.Link
                                    style={{ color: showUpload === "FILE" ? "#515e68" : "#AFB5B9", fontSize: 13 }}
                                    className='ml-1'>File</Typography.Link></Flex>
                            <Flex onClick={() => handleClick("CHECKLIST")}><Typography.Link style={{
                                color: showUpload === "CHECKLIST" ? "#515e68" : "#AFB5B9",
                                fontSize: 13, wordSpacing: '-2px'
                            }}>Sub Tasks</Typography.Link></Flex>
                        </Space>
                        {showUpload === "FILE" && (
                            <div>
                                <div className={`${TaskStyleCss.uploadContainer} ${fileList.length > 0 ? TaskStyleCss.showPreview : ''}`}>
                                    <Dragger
                                        listType="picture-card"
                                        fileList={fileList}
                                        onChange={handleChange}
                                        beforeUpload={() => false}
                                        style={{ width: '100%' }}
                                        onPreview={handlePreview}
                                        multiple
                                    >
                                        {uploadButton}
                                    </Dragger>
                                    {previewImage && (
                                        <Image
                                            wrapperStyle={{ display: 'none' }}
                                            preview={{
                                                visible: previewOpen,
                                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {showUpload === "CHECKLIST" && (
                            <div className={TaskStyleCss.checkList}>
                                {subTaskData.map((st: subTaskDataListInterface, index: number) =>
                                    <CheckList data={st} taskListChange={(val: any) => handleSubtaskChange(val, index)} removeSubTaskList={() => removeSubTaskList(index)} />
                                )}
                                <Button
                                    style={{ color: "#80868e", fontSize: '14px', paddingLeft: 0, marginBottom: '13px' }}
                                    size='large'
                                    type="link"
                                    onClick={addSubTaskList}
                                    icon={<PlusOutlined style={{ fontSize: '11px', lineHeight: '1', fill: '#80868e' }} />}
                                >
                                    Add SubTask List
                                </Button>
                            </div>
                        )}
                        <div className='taskField'>
                            <div className='' style={{ paddingBottom: '10px' }}>
                                <Flex align='center' gap={30}>
                                    <Typography.Text className='label'>Due Date</Typography.Text>
                                    <InputBox name={'dueDate'} showTime={true} type='date' style={{ width: '220px' }} />
                                    <Flex align='center' gap={10}>
                                        <Button onClick={setTodayDate} style={{
                                            borderBottom: '1px dashed #5e6675',
                                            borderRadius: '0px',
                                            padding: '0px',
                                            color: '#5e6675'
                                        }}>Today</Button>
                                        <Button onClick={setTomorrowDate} style={{
                                            borderBottom: '1px dashed #5e6675',
                                            borderRadius: '0px',
                                            padding: '0px',
                                            color: '#5e6675'
                                        }}>Tomorrow</Button>
                                    </Flex>
                                </Flex>
                            </div>
                            {user?.role === "AGENT_ADMIN" && <div className=''>
                                <div className='assigneeField'>
                                    <Flex align='center' gap={30}>
                                        <Typography.Text className='label'>Assignee</Typography.Text>
                                        <div className={TaskStyleCss.customeAssigneeInput}>
                                            <TweenOneGroup
                                                appear={false}
                                                enter={{ scale: 0.8, opacity: 0, type: 'from', duration: 100 }}
                                                leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                                                onEnd={(e:any) => {
                                                    if (e.type === 'appear' || e.type === 'enter') {
                                                    (e.target as any).style = 'display: inline-block';
                                                    }
                                                }}
                                            >
                                                <Flex wrap gap={5} align='center'>
                                                    {selectedAssignee.map((dt:any) => <Tag
                                                        closable
                                                        onClose={(e) => {
                                                            e.preventDefault();
                                                            handleCloseTag(dt._id);
                                                        }}
                                                    >
                                                        {dt.name}
                                                    </Tag>)}
                                                    <UserInvite 
                                                        assignList={assignList} 
                                                        user={user} 
                                                        onSubmit={getAssigneeList}
                                                    >
                                                        <span className='label-tag pl-2'>+ Add member</span>
                                                    </UserInvite>
                                                </Flex>
                                            </TweenOneGroup>
                                        </div>
                                        {/* <InputSelect
                                            mode='multiple'
                                            name={'assignTo'}
                                            options={options}
                                            showSearch
                                            allowClear
                                            style={{ width: '420px' }}
                                            placeholder="Select Assign"
                                            withCheckboxes
                                            defaultValue={[]}
                                        /> */}
                                    </Flex>
                                </div>
                            </div>}
                        </div>
                    </Card>
                </Form>
            </Drawer>
        </>
    );
}

const mapStateToProps = ({ layout, userData }: RootState) => {
    const { taskDrawerOpen } = layout
    const { user } = userData;

    return {
        taskDrawerOpen, user
    }
}
export default connect(mapStateToProps, { setTaskDrawerOpen })(CreateTask)