import { Tabs } from "antd";
import styleCss from "./style.module.scss";

interface TabsComponentProps {
    items: Array<{ key: string; label: string; children: React.ReactNode }>;
    onChange?: (key: string) => void;
    activeKey?: string;
    defaultActiveKey?: string;
}

const NavTab = ({ items, onChange, activeKey, defaultActiveKey }: TabsComponentProps) => {
    return <Tabs className={styleCss.customNavTab} items={items} onChange={onChange} activeKey={activeKey} defaultActiveKey={defaultActiveKey} />;
};

export default NavTab;
