import { Checkbox, Flex, Form, Input, Typography, Image, MenuProps, Avatar, Tooltip, Dropdown, Space, Tag, Upload, UploadProps, UploadFile, GetProp, Divider } from "antd";
import styleCss from "./style.module.scss";
import { Button } from "antd";
import { useEffect, useState } from "react";
import AxiosService from "../../utils/APIService";
import CardFooter from "./CardFooter";
import { useNote } from ".";
import { RiCloseLine, RiPushpin2Fill, RiPushpin2Line } from "react-icons/ri";
import { FiClock, FiPlus } from "react-icons/fi";
import { FaCaretDown } from "react-icons/fa6";
import { getInitials } from "../../utils/commonFunctions";
import commonCSS from "../../css/commonStyle.module.scss";
import { IoClose, IoDocumentTextOutline } from "react-icons/io5";
import { TweenOneGroup } from "rc-tween-one";
import { ITags } from "../../utils/interface";
import dayjs from "dayjs";
import { UserInterface } from "../../reducer/loginReducer";
import { RootState } from "../../reducer";
import { connect } from "react-redux";
import { CheckBoxInCrateNote, FrameIcon } from "./NotesFooterIcons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdDragIndicator } from "react-icons/md";
import { NOTES_FOOTER_TYPE } from "../../utils/const";

interface CreateNotePropsInterface {
    user: UserInterface | undefined | null;
}
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const CreateNote = ({ user }: CreateNotePropsInterface) => {
    const apiService = new AxiosService();
    const [taskForm] = Form.useForm();
    const [backgroundColor, setBackgroundColor] = useState("#ffffff");
    const [isExpanded, setIsExpanded] = useState(true);
    const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [noteContent, setNoteContent] = useState<any>(null);
    const [isNotesPin, setIsNotesPin] = useState<boolean>(false);
    const [shareNote, setShareNote] = useState<any[]>([]);
    // const [isShareAll, setIsShareAll] = useState(false);
    const { assignList, fetchList, getUniqIdsOnly } = useNote();
    const [selectedLabels, setSelectedLabels] = useState<any[]>([]);
    const [reminder, setReminder] = useState<any>(null);
    const [isRemainderOpen, setIsRemainderOpen] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [uncheckedItems, setUncheckedItems] = useState<any[]>([]);
    const [checkedItems, setCheckedItems] = useState<any[]>([]);
    const colors = ["#fa7777", "#30bfee"];
    let isShareAll = false;

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: "Share all",
            onClick: () => {
                isShareAll = true;
                taskForm.submit();
            },
        },
    ];

    useEffect(() => {
        if (showCheckboxes) {
            setUncheckedItems(showCheckboxes ? noteContent?.filter((line: any) => !line?.checked) : []);
            setCheckedItems(showCheckboxes ? noteContent?.filter((line: any) => line.checked) : []);
        }
    }, [showCheckboxes]);

    useEffect(() => {
        if (showCheckboxes) {
            setNoteContent([...uncheckedItems || [], ...checkedItems || []]);
        }
    }, [uncheckedItems, checkedItems, showCheckboxes]);

    useEffect(() => {
        if (uploadFileList?.length) setIsExpanded(true);
    }, [uploadFileList]);

    const createNote = (val: any) => {
        const formData = new FormData();
        let hasContent = false;

        formData.append("descriptionType", showCheckboxes ? "checkbox" : "text");
        formData.append("bg_color", backgroundColor ?? "#ffffff");
        formData.append("is_pinned", `${isNotesPin}`);
        if (!!reminder) {
            formData.append("reminder", reminder ?? null);
            hasContent = true;
        }
        if (val.title) {
            formData.append("title", val.title);
            hasContent = true;
        }

        if (noteContent && noteContent.length > 0 ) {
            formData.append(showCheckboxes ? "checkList" : "description", showCheckboxes ? JSON.stringify(noteContent) : noteContent);
            hasContent = true;
        }
        
        if (uploadFileList?.length > 0) {
            for (let i = 0; i < uploadFileList.length; i++) {
                const file: any = uploadFileList[i];
                formData.append(`doc_${i + 1}`, file.originFileObj);
            }
            hasContent = true;
        }
        if (selectedLabels?.length > 0) {
            formData.append("tags", JSON.stringify(getUniqIdsOnly(selectedLabels)));
        }

        if (isShareAll) {
            const teamList = assignList.filter((dt: any) => dt._id !== user?._id)?.map((list: any) => list?._id);
            formData.append("shareTo", JSON.stringify(teamList));
            hasContent = true;
        } else if (shareNote?.length > 0) {
            formData.append("shareTo", JSON.stringify(shareNote));
            hasContent = true;
        }

        if (hasContent) {
            apiService
                .post("/notes/create", formData, false, false)
                .then(() => {
                    taskForm.resetFields();
                    setNoteContent(null);
                    setUploadFileList([]);
                    setIsNotesPin(false);
                    setShareNote([]);
                    setBackgroundColor("#ffffff");
                    setSelectedLabels([]);
                    setReminder(null);
                    fetchList();
                    setShowCheckboxes(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    const parseContent = (text: any) => text?.split("\n")?.map((line: any) => ({ title: line, checked: false }));

    const handleSubmit = () => {
        isShareAll = false;
        taskForm.submit();
    };

    const handleColorChange = (color: string) => {
        setBackgroundColor(color);
    };

    const handleCheckboxChange = (index: number, isUncheckedList: boolean) => {
        if (isUncheckedList) {
            const itemToMove = uncheckedItems[index];
            setUncheckedItems((prevItems) => prevItems.filter((_, i) => i !== index));
            setCheckedItems((prevItems) => [...prevItems, { ...itemToMove, checked: true }]);
        } else {
            const itemToMove = checkedItems[index];
            setCheckedItems((prevItems) => prevItems.filter((_, i) => i !== index));
            setUncheckedItems((prevItems) => [...prevItems, { ...itemToMove, checked: false }]);
        }
    };

    const handleLineTextChange = (index: number, text: string, isUncheckedList: boolean) => {
        if (isUncheckedList) {
            setUncheckedItems((prevItems) => {
                const newItems = [...prevItems];
                newItems[index].title = text;
                return newItems;
            });
        } else {
            setCheckedItems((prevItems) => {
                const newItems = [...prevItems];
                newItems[index].title = text;
                return newItems;
            });
        }
    };

    // const handleAddNewLine = () => {
    //     if (uncheckedItems?.length && !uncheckedItems[uncheckedItems?.length - 1].title) return;
    //     setUncheckedItems((prevItems) => [...prevItems, { title: "", checked: false }]);
    // };

    const handleAddNewLine = () => {
        if (uncheckedItems?.length && !uncheckedItems[uncheckedItems.length - 1].title) return;
        setUncheckedItems((prevItems) => Array.isArray(prevItems) ? [...prevItems, { title: "", checked: false }] : [{ title: "", checked: false }]);
    };

    const handleAddNewLineAtIndex = (index: number, isChecked: boolean) => {
        const newLine = { title: "", checked: isChecked };

        if (isChecked) {
            setCheckedItems((prevItems: any[]) => {
                const newCheckedItems = [...prevItems];
                newCheckedItems.splice(index + 1, 0, newLine);
                return newCheckedItems;
            });
        } else {
            setUncheckedItems((prevItems: any[]) => {
                const newUncheckedItems = [...prevItems];
                newUncheckedItems.splice(index + 1, 0, newLine);
                return newUncheckedItems;
            });
        }
    };

    const handleContentChange = (e: any) => {
        setNoteContent(e.target.value);
    };

    const handleRemoveLine = (index: number, isUncheckedList: boolean) => {
        if (isUncheckedList) {
            setUncheckedItems((prevItems) => prevItems.filter((_, i) => i !== index));
        } else {
            setCheckedItems((prevItems) => prevItems.filter((_, i) => i !== index));
        }
    };

    const handleCheckboxToggle = () => {
        setIsExpanded(true);
        setShowCheckboxes((prev) => {
            if (!prev) {
                setNoteContent(parseContent(noteContent));
            } else {
                setNoteContent(noteContent?.map((line: any) => line?.title).join("\n"));
            }
            return !prev;
        });
    };

    const handlePinNote = () => {
        setIsNotesPin(!isNotesPin);
    };

    const handleUserListChange = (userList: any[]) => {
        setShareNote([...userList]);
    };

    const getFullNameById = (id: string) => {
        const user = assignList?.find((user: any) => {
            return user?._id === id;
        });
        return user && user.full_name;
    };

    const isImageFile = (fileName: any) => {
        const getFileExtension = (fileName: any) => fileName?.split(".").pop()?.toLowerCase() || "";
        const imageTypes = ["jpeg", "jpg", "png", "webp"];
        const extension = getFileExtension(fileName);
        return imageTypes.includes(extension);
    };

    const handleRemoveNoteLabel = (label: any) => {
        const updatedLabels = selectedLabels?.filter((l) => l?._id !== label?._id);
        setSelectedLabels(updatedLabels);
    };

    const handleEditReminder = () => {
        setIsRemainderOpen(true);
    };

    const removeReminder = () => {
        setReminder(null);
    };

    //  called only on remove
    const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }: any) => {
        setUploadFileList(newFileList);
    };

    const getBase64 = (file: File): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file: UploadFile) => {
        let fileType = ["jpeg", "png", "jpg"];
        if (fileType.includes(file.name.split(".")[1])) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as FileType);
            }

            setPreviewImage(file.url || (file.preview as string));
            setPreviewOpen(true);
        } else {
            window.open(file.url, "_blank")?.focus();
        }
    };

    const handleKeyPress = (index: number, e: any, isUncheckedList: boolean) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (isUncheckedList) {
                handleAddNewLineAtIndex(index, false);
            } else {
                handleAddNewLineAtIndex(index, true);
            }
            setTimeout(() => {
                const nextElement = document.querySelector(`.editable-div-${index + 1}`);
                if (nextElement) {
                    (nextElement as HTMLElement).focus();
                }
            }, 0);
        }
    };

    const handleDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;

        const newUncheckedItems = Array.from(uncheckedItems);
        const [movedItem] = newUncheckedItems.splice(source.index, 1);
        newUncheckedItems.splice(destination.index, 0, movedItem);
        setUncheckedItems(newUncheckedItems);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    return (
        <div className={styleCss.createNoteWrapper} style={{ backgroundColor: backgroundColor }}>
            <Form name="note" onFinish={createNote} form={taskForm}>
                {isExpanded ? (
                    <>
                        <div className="pin" onClick={handlePinNote}>
                            {isNotesPin ? (
                                <Tooltip title="Unpin note">
                                    <RiPushpin2Fill size={24} color="#444444" />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Pin note">
                                    <RiPushpin2Line size={24} color="#444444" />
                                </Tooltip>
                            )}
                        </div>
                        {uploadFileList?.length > 0 && (
                            <>
                                <div style={{ marginBottom: "15px" }} className="createImage">
                                    <Upload
                                        listType="picture-card"
                                        fileList={uploadFileList}
                                        beforeUpload={() => false}
                                        onChange={handleChange}
                                        onPreview={handlePreview}
                                        multiple
                                    />
                                </div>
                                <Image
                                    wrapperStyle={{ display: "none" }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                        afterOpenChange: (visible) => !visible && setPreviewImage(""),
                                    }}
                                    src={previewImage}
                                />
                            </>
                        )}

                        <Form.Item className="title" name="title" style={{ marginBottom: "15px" }}>
                            <Input placeholder="Title" variant="borderless" style={{ backgroundColor: "transparent" }} className="title-input" autoComplete="off" onKeyDown={handleKeyDown} />
                        </Form.Item>

                        <Form.Item className="noteField" name="description">
                            <div style={{ marginBottom: `${isExpanded ? "5px" : ""}` }}>
                                {showCheckboxes ? (
                                    <>
                                        <DragDropContext onDragEnd={handleDragEnd}>
                                            <Droppable droppableId="unchecked-list">
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                        {uncheckedItems?.map((line: any, index: number) => (
                                                            <Draggable key={index} draggableId={`unchecked-${index}`} index={index}>
                                                                {(provided) => (
                                                                    <div ref={provided.innerRef} {...provided.draggableProps} className="note-content">
                                                                        <Flex align="center" className="contain-div">
                                                                            <div className="dragHandle" {...provided.dragHandleProps} style={{ paddingRight: "6px" }}>
                                                                                <MdDragIndicator size={24} color="#979797" style={{ display: "inline-flex" }} />
                                                                            </div>
                                                                            <Checkbox
                                                                                checked={line.checked}
                                                                                onChange={() => handleCheckboxChange(index, true)}
                                                                                style={{ marginRight: "10px" }}
                                                                            />
                                                                            <div
                                                                                contentEditable
                                                                                style={{ flexGrow: 1, outline: "none" }}
                                                                                className={`editable-div editable-div-${index}`}
                                                                                onBlur={(e: any) => handleLineTextChange(index, e.currentTarget.textContent, true)}
                                                                                onKeyDown={(e: any) => handleKeyPress(index, e, true)}
                                                                            >
                                                                                {line?.title}
                                                                            </div>
                                                                            <RiCloseLine
                                                                                size={24}
                                                                                onClick={() => handleRemoveLine(index, true)}
                                                                                className="delete-item"
                                                                                color="#979797"
                                                                            />
                                                                        </Flex>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                        {showCheckboxes && (
                                            <div className="listItem" onClick={() => setIsExpanded(true)} style={{ paddingLeft: "28px" }}>
                                                <Button
                                                    style={{ color: "#717171", fontSize: "13px", padding: 0 }}
                                                    size="large"
                                                    type="link"
                                                    onClick={handleAddNewLine}
                                                    icon={<FiPlus size={18} color="#717171" />}
                                                >
                                                    List item
                                                </Button>
                                            </div>
                                        )}
                                        {Array.isArray(noteContent) && noteContent.some((line) => !line.checked) && noteContent.some((line) => line.checked) && (
                                            <Divider
                                                style={{
                                                    borderColor: "rgba(100, 100, 100, 0.2)",
                                                    margin: "5px 10px 15px 25px",
                                                    minWidth: "auto",
                                                    width: "auto",
                                                }}
                                            />
                                        )}
                                        {/* Checked items - not draggable but editable */}
                                        <div style={{ paddingLeft: "27px" }}>
                                            {checkedItems?.map((line: any, index: number) => (
                                                <div key={`checked-${index}`} className="note-content">
                                                    <div style={{ display: "flex", alignItems: "center" }} className="contain-div">
                                                        <Checkbox checked={line.checked} onChange={() => handleCheckboxChange(index, false)} style={{ marginRight: "10px" }} />
                                                        <div
                                                            contentEditable
                                                            style={{ flexGrow: 1, outline: "none" }}
                                                            className={`editable-div editable-div-${index}`}
                                                            onBlur={(e: any) => handleLineTextChange(index, e.currentTarget.textContent, false)}
                                                            onKeyDown={(e: any) => handleKeyPress(index, e, false)}
                                                        >
                                                            <span style={{ textDecoration: "line-through", color: "#5f6368" }}>{line?.title}</span>
                                                        </div>
                                                        <RiCloseLine size={24} onClick={() => handleRemoveLine(index, false)} className="delete-item" color="#979797" />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <div className="create-note-input">
                                        <Input.TextArea
                                            autoSize={{ minRows: 1, maxRows: 18 }}
                                            value={noteContent}
                                            onChange={handleContentChange}
                                            placeholder="Take a note..."
                                            variant="borderless"
                                            onClick={() => setIsExpanded(true)}
                                        />
                                    </div>
                                )}
                            </div>
                        </Form.Item>

                        {reminder && (
                            <Flex onClick={handleEditReminder} gap={4} align="center" className="CreateNoteReminder">
                                <FiClock size={14} style={{ minWidth: "14px", maxWidth: "14px", width: "14px" }} color="#3c4043" />
                                <Typography.Text ellipsis>{dayjs(reminder).format("MMM D, h:mm A")}</Typography.Text>
                                <IoClose size={16} onClick={removeReminder} className="close" color="#3c4043" />
                            </Flex>
                        )}

                        {!!selectedLabels && selectedLabels.length > 0 && (
                            <div className="CreateNoteTag">
                                <TweenOneGroup
                                    appear={false}
                                    enter={{ scale: 0.8, opacity: 0, type: "from", duration: 100 }}
                                    leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                                    onEnd={(e) => {
                                        if (e.type === "appear" || e.type === "enter") {
                                            (e.target as any).style = "display: inline-block";
                                        }
                                    }}
                                >
                                    {selectedLabels?.map((tag: ITags) => (
                                        <Tag key={tag?._id} color={tag?.color} bordered={false}>
                                            <Flex align="center" gap={3}>
                                                <Typography>{tag?.title}</Typography>
                                                <IoClose
                                                    size={14}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleRemoveNoteLabel(tag);
                                                    }}
                                                    className="close"
                                                    color="#3c4043"
                                                />
                                            </Flex>
                                        </Tag>
                                    ))}
                                </TweenOneGroup>
                            </div>
                        )}

                        {!!shareNote && shareNote.length > 0 && (
                            <div style={{ margin: "15px 0px", paddingLeft: "5px" }}>
                                <Avatar.Group
                                    max={{
                                        count: 3,
                                        style: { color: "#ffffffd6", backgroundColor: "#76a3da", fontSize: "14px" },
                                        popover: { rootClassName: "AsssignnePopover" },
                                    }}
                                    size={30}
                                >
                                    {shareNote.map((asn: any, idx: any) => {
                                        const fullName = getFullNameById(asn);
                                        return (
                                            <Tooltip title={fullName} key={idx}>
                                                <Avatar
                                                    style={{
                                                        fontSize: "14px",
                                                        backgroundColor: colors[idx % colors.length],
                                                        marginLeft: "-5px",
                                                    }}
                                                >
                                                    {getInitials(fullName)}
                                                </Avatar>
                                            </Tooltip>
                                        );
                                    })}
                                </Avatar.Group>
                            </div>
                        )}

                        <Flex align="center" justify="space-between" style={{ marginTop: "15px" }}>
                            <Flex gap={30} className="footerBtn">
                                <CardFooter
                                    isHover={true}
                                    onColorChange={handleColorChange}
                                    onCheckboxToggle={handleCheckboxToggle}
                                    showCheckboxes={showCheckboxes}
                                    onUserListChange={handleUserListChange}
                                    selectedLabels={selectedLabels}
                                    setSelectedLabels={setSelectedLabels}
                                    userShareList={shareNote.map((d) => ({ _id: d }))}
                                    reminder={reminder}
                                    setReminder={setReminder}
                                    isRemainderOptionOpen={isRemainderOpen}
                                    setIsRemainderOptionOpen={setIsRemainderOpen}
                                    uploadFileList={uploadFileList}
                                    setUploadFileList={setUploadFileList}
                                    footerType={NOTES_FOOTER_TYPE.createNote}
                                />
                            </Flex>
                            <Space className="actionBtnWrapper">
                                <Button
                                    onClick={() => {
                                        setIsExpanded(false);
                                        handleSubmit();
                                    }}
                                    className="cancel"
                                >
                                    Cancel
                                </Button>
                                <Dropdown.Button menu={{ items }} icon={<FaCaretDown />} onClick={handleSubmit} className={commonCSS.inquireyBtn} trigger={["click"]}>
                                    CREATE
                                </Dropdown.Button>
                            </Space>
                        </Flex>
                    </>
                ) : (
                    <>
                        <div className="noteField not-expanded-container">
                            <Input.TextArea placeholder="Take a note..." autoSize={{ minRows: 1, maxRows: 18 }} variant="borderless" onClick={() => setIsExpanded(true)} />
                            <div className="Create-suffix-icon">
                                <CheckBoxInCrateNote onCheckboxToggle={handleCheckboxToggle} />
                                <FrameIcon setUploadFileList={setUploadFileList} uploadFileList={uploadFileList} id="" footerType={NOTES_FOOTER_TYPE.createNote} />
                            </div>
                        </div>
                    </>
                )}
            </Form>
        </div>
    );
};

const mapStateToProps = ({ userData }: RootState) => {
    const { user } = userData;
    return { user };
};

export default connect(mapStateToProps, {})(CreateNote);
