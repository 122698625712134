// Archive Note List

import { Typography } from "antd";
import { useNote } from ".";
import { MdOutlineArchive } from "react-icons/md";
import NoteCard from "./NoteCard";
import Masonry from "react-masonry-css";
import styleCss from "./style.module.scss";

const ArchiveNoteList = () => {
    const { archiveNoteList } = useNote();

    const breakpointColumnsObj = {
        default: 5,
        1400: 4,
        1300: 3,
        1100: 2,
        764: 1,
    };

    return (
        <div className={styleCss.noteGirdViewContainer}>
            {!!archiveNoteList.length && (
                <div className={styleCss.filterTitle}>
                    <Masonry breakpointCols={breakpointColumnsObj} className={styleCss.myMasonryGrid} columnClassName={styleCss.myMasonryGridColumn}>
                        {archiveNoteList?.map((note: any) => (
                            <NoteCard {...note} key={note?.id} />
                        ))}
                    </Masonry>
                </div>
            )}
            {!archiveNoteList.length && (
                <div className="no-data-notification">
                    <MdOutlineArchive size={90} className="note-no-data-icon" />
                    <Typography className="no-data-text">Your archived notes appear here</Typography>
                </div>
            )}
        </div>
    );
};

export default ArchiveNoteList;
